import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-lightbox-dialog',
  templateUrl: './lightbox-dialog.component.html',
  styleUrls: ['./lightbox-dialog.component.scss']
})
export class LightboxDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public imgSrc: string
	) { }
}
