import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-sample-status-stepper',
	templateUrl: './sample-status-stepper.component.html',
	styleUrls: ['./sample-status-stepper.component.scss']
})
export class SampleStatusStepperComponent implements OnInit {
	@Input() sampleStatus?: string | undefined;

	statuses: string[] = ['ANALYZED', 'ASSESSED', 'REPORTED'];

	ngOnInit(): void {
		if (!this.sampleStatus) this.sampleStatus = '';
	}

	isStepReached(status: string): boolean {
		const stepOrder: string[] = ['PENDING', 'ANALYZED', 'ASSESSED', 'REPORTED'];
		const currentIndex = stepOrder.indexOf(this.sampleStatus ?? 'PENDING');
		return stepOrder.indexOf(status) <= currentIndex;
	}

	getStepClass(status: string): string {
		return this.isStepReached(status) ? 'bg-dc-blue-600 text-white' : 'bg-gray-200 text-gray-600';
	}
}


