import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { HttpXsrfTokenExtractor } from '@angular/common/http';

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {

	constructor(private tokenExtractor: HttpXsrfTokenExtractor) { }

	intercept(req: HttpRequest<any>, next: HttpHandler) {

		const token = this.tokenExtractor.getToken() as string;

		if (token !== null && !req.headers.has('X-XSRF-TOKEN')) {
			req = req.clone({
				headers: req.headers.set('X-XSRF-TOKEN', token) // add XSRF token to header
			});
		}
		return next.handle(req);
	}
}