<form [formGroup]="sampleForm" class="bg-[#F1F1F1]" (submit)="onSubmit()">

    <h1 mat-dialog-title>{{isAddMode ? 'Neue Probe hinzufügen' : 'Probe bearbeiten'}}
        <span *ngIf="!isAddMode" class="text-sm text-dc-blue-500 uppercase">
            [{{sampleForm.get('sampleStatus')?.value | sampleStatus}}]
        </span>
    </h1>
    <mat-dialog-content class="mat-typography h-[83vh] !max-h-[100vh]">
 
        <mat-card class="!inline-flex !flex-row p-4 w-full items-center mb-4 !rounded-none">

                <mat-form-field class="w-1/3 mr-3" subscriptSizing="dynamic">
                    <mat-label>Klient*in</mat-label>
                    <input matInput type="text" formControlName="clientUid" readonly>
                </mat-form-field>

                <mat-form-field class="w-1/3 mr-3" subscriptSizing="dynamic">
                    <mat-label>Probencode</mat-label>
                    <input matInput type="text" formControlName="sampleNumber" tabindex="1" cdkFocusInitial [maxlength]="9" [readonly]="!isAddMode">
                </mat-form-field>

                <mat-form-field class="w-1/3" subscriptSizing="dynamic">
                    <mat-label>Abgabedatum</mat-label>
                    <input matInput 
                        [matDatepicker]="submissionDatePicker" 
                        (dateChange)="formatOutput('submissionDate', $event.value, 'YYYY-MM-DD')" 
                        formControlName="submissionDate"
                        [max]="today">
                    <mat-datepicker-toggle matIconSuffix [for]="submissionDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #submissionDatePicker></mat-datepicker>
                </mat-form-field>
                
        </mat-card>

        <mat-card class="!inline-flex !flex-row p-4 w-full items-center mb-4 !rounded-none">
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Bemerkungen</mat-label>
                <textarea rows="2" matInput formControlName="notes" [maxlength]="255" #textArea (input)="updateTextCount(textArea.value)"></textarea>
                <mat-hint align="end">{{textCount}}/255</mat-hint>
            </mat-form-field>
        </mat-card>

        <mat-tab-group mat-stretch-tabs="true" 
                       mat-align-tabs="start" 
                       dynamicHeight 
                       animationDuration="0ms"
                       class="sample-tab mat-elevation-z1"
                       [selectedIndex]="0"
                       (selectedIndexChange)="onTabChanged($event)"
                       #tabGroup>

            <mat-tab label="Substanz">

                <div class="p-4 bg-[#FFFFFF]">

                    <div class="flex flex-row gap-4 mb-4">

                        <div class="p-3 w-1/2">
                            <mat-form-field class="w-full" subscriptSizing="dynamic">
                                <mat-label>Erworben als</mat-label>
                                <input type="text"
                                    placeholder="Andere Substanz hier eintragen..."
                                    aria-label="Substanz"
                                    matInput
                                    formControlName="name"
                                    [matAutocomplete]="substance"
                                    [maxlength]="100">
                                <mat-autocomplete autoActiveFirstOption #substance="matAutocomplete">
                                    <mat-option *ngFor="let substance of filteredOptionsSubstance | async" [value]="substance">
                                        {{substance}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        
                        <div class="p-3 w-1/2">   
                            <label>Hauptsubstanz</label><br>
                            <mat-radio-group aria-label="Hauptsubstanz" formControlName="isMainSubstance" color="primary">
                                <mat-radio-button [value]="true" class="mr-5">Ja</mat-radio-button>
                                <mat-radio-button [value]="false">Nein</mat-radio-button>
                            </mat-radio-group>
                        </div>

                    </div>

                    <div class="flex flex-row gap-4">
                        
                        <div class="p-3 w-1/2">

                            <mat-form-field class="w-full mb-5" subscriptSizing="dynamic">
                                <mat-label>Form</mat-label>
                                <input type="text"
                                       placeholder="Andere Form hier eintragen..."
                                       aria-label="Form"
                                       matInput
                                       formControlName="sampleForm"
                                       [matAutocomplete]="auto"
                                       [maxlength]="100">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let form of filteredOptionsSampleForm | async" [value]="form">
                                        {{form}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field class="w-full mb-5" subscriptSizing="dynamic">
                                <mat-label>Farbe</mat-label>
                                <input matInput type="text" [maxlength]="100" formControlName="sampleColor">
                            </mat-form-field>
                            
                            <mat-form-field class="w-full" subscriptSizing="dynamic">
                                <mat-label>Logo</mat-label>
                                <input matInput type="text" [maxlength]="100" formControlName="sampleLogo">
                            </mat-form-field>
                        </div>

                        <div class="p-3 w-1/2">
                            <label>Analyseanlass</label>
                            <section class="flex flex-col">
                                <mat-checkbox color="primary" formControlName="reasonEffects">Unerwartete / unerwünschte Wirkung klären</mat-checkbox>
                                <mat-checkbox color="primary" formControlName="reasonPrevention">Prävention (Überdosierung, unerwünschte Wirkung), Safer Use</mat-checkbox>
                                <mat-checkbox color="primary" 
                                              #reasonOther 
                                              [checked]="sampleForm.get('reasonOther')?.value !== ''">Sonstiges</mat-checkbox>
                            </section>
                            <mat-form-field *ngIf="reasonOther.checked" class="w-full mt-2" subscriptSizing="dynamic">
                                <input matInput type="text" placeholder="Anderen Grund eintragen" formControlName="reasonOther">
                            </mat-form-field>
                        </div>

                    </div>

                </div>

            </mat-tab>

            <mat-tab label="Kauf">
                
                <div class="p-4 bg-[#FFFFFF]">

                    <div class="flex flex-row gap-4">

                        <mat-form-field class="p-3 w-[49%]" appYearMonthFormat>
                            <mat-label>Kaufzeitraum</mat-label>
                            <input matInput 
                                   [matDatepicker]="purchaseDatePicker"
                                   formControlName="purchaseDate"
                                   [max]="today">
                            <mat-datepicker-toggle matIconSuffix [for]="purchaseDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #purchaseDatePicker  
                                            startView="multi-year"
                                            (monthSelected)="setMonthAndYear($event, purchaseDatePicker)"></mat-datepicker>
                        </mat-form-field>
                        
                        <div class="p-3 w-[51%]">   
                            <label>Erwerbsort</label><br>
                            <mat-radio-group aria-label="Erwerbsort" formControlName="purchaseLocation" color="primary">
                                <mat-radio-button [value]="'BERLIN'" class="mr-5">Berlin</mat-radio-button>
                                <mat-radio-button [value]="'OTHER'">Anderswo</mat-radio-button>
                            </mat-radio-group>
                        </div>

                    </div>

                    <div class="flex flex-row gap-4">

                        <div class="p-3 w-[45%]">

                            <div class="w-full !inline-flex gap-4">

                                <mat-form-field subscriptSizing="dynamic">
                                    <mat-label>Preis pro Einheit</mat-label>
                                    <input matInput 
                                        #inputRef
                                        formControlName="price" 
                                        type="text">
                                </mat-form-field>

                                <mat-form-field subscriptSizing="dynamic">
                                    <mat-label>Einheit</mat-label>
                                    <mat-select formControlName="unit">
                                        <mat-option *ngFor="let unit of optionsUnit" [value]="unit.value">{{unit.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
           
                        </div>

                                                
                        <div class="p-3 w-[55%]">

                            <div class="w-full !inline-flex gap-4">

                                <div class="w-full">   
                                    <label>Erwerbsweg</label><br>
                                    <mat-radio-group aria-label="Erwerbsweg" formControlName="purchaseChannel" color="primary" class="flex flex-col items-start">
                                        <mat-radio-button *ngFor="let channel of optionsPurchaseChannel" [value]="channel.value" class="mr-5">
                                            {{channel.name}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="w-full">   
                                    <label>Distribution</label><br>
                                    <mat-radio-group aria-label="Distribution" formControlName="distribution" color="primary" class="flex flex-col items-start">
                                        <mat-radio-button *ngFor="let distribution of optionsDistribution" [value]="distribution.value" class="mr-5">
                                            {{distribution.name}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </mat-tab>

        </mat-tab-group>

    </mat-dialog-content>

    <mat-dialog-actions align="start" class="bg-[#FFFFFF]">
        <button type="button" mat-button mat-dialog-close>Schließen</button>
        <span class="flex-1"></span>
        <button type="button" mat-button [hidden]="hideNextButton" (click)="nextTab()">Weiter</button>
        <button type="button" mat-button [hidden]="hidePrevButton" (click)="prevTab()">Zurück</button>
        <button type="submit" mat-flat-button [hidden]="hidePrevButton" [color]="'primary'" [disabled]="isLoading || sittingStatus === 'CLOSED'">Speichern</button>
    </mat-dialog-actions>

</form>