import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '@core/services/api.service';
import { LabDialogComponent } from './lab-dialog/lab-dialog.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, Subscription } from 'rxjs';

@Component({
    selector: 'app-lab',
    templateUrl: './lab.component.html',
    styleUrls: ['./lab.component.scss']
})
export class LabComponent implements OnInit, OnDestroy {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    /** Array of column names to display in the table */
    displayedColumns: string[] = ['edit', 'sampleNumber', 'timestamp'];

    /** Data source for the table */
    dataSource = new MatTableDataSource();
    
	/** Total number of elements in the data source. */
	totalElements: number = 0;

	/** Number of items to display on a page. */
	pageSize: number = 10;

    /** Boolean flag indicating whether a loading indicator should be displayed. */
	isLoading: boolean = false;

    currentSampleStatus: string = 'ANALYZED';

    subscribtion: Subscription;

    constructor(
        private router: Router,
		private route: ActivatedRoute,
        private apiService: ApiService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {

        this.subscribtion = this.route.queryParamMap.pipe(
            distinctUntilChanged()
        ).subscribe(params => {
            this.currentSampleStatus = params.get('sampleStatus') || 'ANALYZED';
            this.getSamples({ size: this.pageSize, page: 0, sampleStatus: this.currentSampleStatus });
        });
    }

    ngOnDestroy(): void {
        if ( this.subscribtion ) this.subscribtion.unsubscribe();
    }

    /**
     * Fetches the samples from the API and updates the table data source.
     * @since 1.0.0
     * @returns void
     */
    getSamples(params: any = null): void {
        this.isLoading = true;
        this.apiService.getSamples(params).subscribe({
			next: (data: any) => {
                this.totalElements = data.totalElements
                this.dataSource = new MatTableDataSource(data.content);
                this.isLoading = false;
            },
            error: () => {
                this.isLoading = false;
            }
        });
    }

    /**
     * Deletes a result from the table and API
     * @since 1.0.0
     * @param {any} element - The result to be deleted
     * @returns void
     */
    deleteResult(element: any): void {
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '33.33vw',
            autoFocus: true,
			data: {
				title: `Laborergebnis für Probe ${element.sampleNumber} unwiderruflich löschen?`,
				text: `Sind Sie absolut sicher, dass Sie dieses Laborergebnis löschen wollen? Wenn ja, tippen Sie <code>${element.sampleNumber}</code> in das Feld ein und fahren Sie fort. Andernfalls klicken Sie auf ABBRECHEN. 
                <p class="!mt-4 !mb-0 inline-flex justify-center w-full border border-solid border-[#ffc107] bg-[#fff4e3] py-4"><span class="material-symbols-outlined mr-2">tips_and_updates</span> Sie löschen nur das Ergebnis, nicht die Probe.</p>`,
				confirmation: `${element.sampleNumber}`
			}
        });
		dialogRef.afterClosed().subscribe(res => {
			if ( res.data === 'confirmed' ) {
                this.apiService.deleteResult(element.id, element.analysis.id).subscribe(() => {
                    // Update the textblock table data
                    this.getSamples({
                        size: this.pageSize, 
                        page: 0, 
                        sampleStatus: this.currentSampleStatus
                    });
                    this.snackBar.open('Laborergebnis gelöscht.', 'Ok.', { panelClass: 'success-snack' });
                });
			}	
		});
    }
    
    /** 
    * Opens the lab dialog for adding or editing a sample.
    * @since 1.0.0
    * @param sample - The sample to be edited, or null for adding a new sample.
    * @reutns void
    */
    openLabDialog(sample: any | null = null): void {
        let dialogRef = this.dialog.open(LabDialogComponent, {
            data: {
                sample: sample
            },
            autoFocus: false,
            disableClose: true,
            maxWidth: '100vw',
            height: '100vh',
            panelClass: ['m-0', 'p-0']
        });

        dialogRef.afterClosed().subscribe(res => {
            // if (res && res.data === 'success') {
                this.getSamples({
                    size: this.pageSize, 
                    page: 0, 
                    sampleStatus: this.currentSampleStatus
                });
            // }
        });
    }

    /**
	 * Event handler for when the page in the paginator changes.
	 * @since 1.0.0
	 * @param {any} event - The page change event.
	 * @return void
	 */
	pageChanged(event: any): void {
		this.pageSize = event.pageSize;
		this.getSamples({
			size: event.pageSize,
			page: event.pageIndex,
            sampleStatus: this.currentSampleStatus
		});
	}

    onSampleStatusChange(status: any) {
        this.currentSampleStatus = status.value
        // Update URL with the new sampleStatus
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { sampleStatus: this.currentSampleStatus },
            queryParamsHandling: 'merge', // Merge with existing query params
        });
        // Go to page 0 in the paginator
		this.paginator.firstPage();
        this.getSamples({
            size: this.pageSize,
			page: 0,
            sampleStatus: this.currentSampleStatus
		});
    }

}