import { Directive, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: 'mat-select[orderMultiple]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrderMultipleDirective),
      multi: true
    }
  ]
})
export class OrderMultipleDirective implements ControlValueAccessor {
  selectedOrder: any[] = [];
  onChange = (value: any) => { };
  onTouched = () => { };

  writeValue(value: any[]) {
    this.selectedOrder = value || [];
    this.onChange(this.selectedOrder);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    // do nothing
  }

  updateSelectedOrder(value: any) {
    const isChecked = value.selected;
    const val = value.value;

    if (isChecked) {
      this.selectedOrder.push(val);
    } else {
      const index = this.selectedOrder.indexOf(val);
      if (index > -1) {
        this.selectedOrder.splice(index, 1);
      }
    }

    this.onChange(this.selectedOrder);
  }
}