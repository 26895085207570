import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sittingStatus'
})
export class SittingStatusPipe implements PipeTransform {

	private sittingStatusMap = new Map<string, string>([
		['OPEN', 'Offen'],
		['CLOSED', 'Geschlossen'],
	]);

	transform(value: string): string {
		const translation = this.sittingStatusMap.get(value);
		return translation ? translation : value;
	}

}
