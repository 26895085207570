import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Sitting } from '@shared/models/sitting.interface';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { FetchOpenSitting, CloseSitting, OpenSitting, CheckOpenSitting } from './sitting.action';


export interface SittingStateModel {
	id: number | null
	sittingNumber: string | null
	sittingStatus: string | null
	timestamp: string | null
	userId: number | null
}


@State<SittingStateModel>({
	name: 'openSitting',
	defaults: {
		id: null,
		sittingStatus: null,
		sittingNumber: null,
		timestamp: null,
		userId: null
	}
})
@Injectable()
export class SittingState {
	@Selector()
	static openSitting(state: SittingStateModel): boolean {
		return state.sittingStatus === 'OPEN' ? true : false
	}

	constructor(
		private apiService: ApiService
	) { }

	@Action(OpenSitting)
	open(ctx: StateContext<SittingStateModel>) {
		return this.apiService.createSitting().pipe(
			switchMap((data: Sitting) => {
				ctx.setState({
					id: data.id,
					sittingStatus: data.sittingStatus,
					sittingNumber: data.sittingNumber,
					timestamp: data.timestamp,
					userId: data.userId
				});
				return of(ctx.getState());
			}),
			catchError((err: HttpErrorResponse) => {
				const error = err.error.error;
				if (error === 'SITTING_ALREADY_OPEN') {
					return this.apiService.getSittings().pipe(
						tap((data: any) => {
							const currentSitting = data.content.find((item: Sitting) => item.sittingStatus === 'OPEN')
							ctx.setState({
								id: currentSitting.id,
								sittingStatus: currentSitting.sittingStatus,
								sittingNumber: currentSitting.sittingNumber,
								timestamp: currentSitting.timestamp,
								userId: currentSitting.userId
							});
						})
					);
				} else {
					// Handle other errors or rethrow them
					return of(null)
				}
			})
		);
	}

	@Action(CloseSitting)
	close(ctx: StateContext<SittingStateModel>) {
		const state = ctx.getState();
		return this.apiService.updateSitting(state.id, 'CLOSED').pipe(
			tap((data: Sitting) => {
				ctx.patchState({
					id: data.id,
					sittingStatus: data.sittingStatus,
					sittingNumber: data.sittingNumber,
					timestamp: data.timestamp,
					userId: data.userId
				});
			})
		);
	}

	@Action(FetchOpenSitting)
	fetch(ctx: StateContext<SittingStateModel>) {
		return this.apiService.getSittings().pipe(
			tap((data: any) => {
				const openSitting = data.content.find((item: any) => item.sittingStatus === 'OPEN')
				if (openSitting) {
					ctx.patchState({
						id: openSitting.id,
						sittingStatus: openSitting.sittingStatus,
						sittingNumber: openSitting.sittingNumber,
						timestamp: openSitting.timestamp,
						userId: openSitting.userId
					});
				}
			})
		);
	}

	@Action(CheckOpenSitting)
	check(ctx: StateContext<SittingStateModel>, action: CheckOpenSitting) {

		if (action && action.data.length > 0) {
			const openSitting = action.data.find((item: any) => item.sittingStatus === 'OPEN')
			if (openSitting) {
				ctx.patchState({
					id: openSitting.id,
					sittingStatus: openSitting.sittingStatus,
					sittingNumber: openSitting.sittingNumber,
					timestamp: openSitting.timestamp,
					userId: openSitting.userId
				});
			} else {
				ctx.patchState({
					id: null,
					sittingStatus: null,
					sittingNumber: null,
					timestamp: null,
					userId: null
				});
			}
		}

	}


}