import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'consultationStatus'
})
export class ConsultationStatusPipe implements PipeTransform {

	private consultationStatusMap = new Map<string, string>([
		['OPEN', 'Offen'],
		['CLOSED', 'Geschlossen'],
	]);

	transform(value: string): string {
		const translation = this.consultationStatusMap.get(value);
		return translation ? translation : value;
	}

}
