import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HttpXsrfInterceptor } from './interceptors/http-xsrf.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { MaterialModule } from './layout/material/material.module';
import { getGermanPaginatorIntl } from './intl/german-paginator.intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		MaterialModule,
		FormsModule, 
		ReactiveFormsModule
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'de-DE' },
		{ provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
	]
})
export class CoreModule { 
	constructor() { registerLocaleData(localeDe) }
}
