<form [formGroup]="textblockForm" class="bg-[#F1F1F1]" (submit)="onSubmit()">

    <h1 mat-dialog-title>
        {{isAddMode ? 'Neuen Textbaustein hinzufügen' : 'Textbaustein bearbeiten'}}
    </h1>
    
    <mat-dialog-content class="mat-typography h-[68vh] !max-h-[100vh]">
 
        <mat-card class="!flex !flex-col p-4 w-full items-center mb-8 !rounded-none">

                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>Beschreibung</mat-label>
                    <input matInput type="text" formControlName="description">
                </mat-form-field>

                <editor class="w-full mt-8"
                    [style.margin-bottom.em]="1.25"
                    formControlName="text"
                    [inline]="false"
                    [init]="tinymceConfig">
                </editor> 
                
        </mat-card>

    </mat-dialog-content>

    <mat-dialog-actions align="start" class="bg-[#FFFFFF]">
        <button type="button" mat-button mat-dialog-close>Schließen</button>
        <span class="flex-1"></span>
        <button type="submit" mat-flat-button [color]="'primary'">Speichern</button>
    </mat-dialog-actions>

</form>