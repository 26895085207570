<app-toolbar></app-toolbar>

<div class="flex flex-row h-[calc(100%-64px)]">
    
    <div class="w-1/4 p-5 pr-0">

		<mat-card>
			<mat-card-header class="mb-5">
				<mat-card-title class="!inline-flex !items-center">
					<span class="material-symbols-outlined mr-1">clinical_notes</span>
					Beratungen
				</mat-card-title>
				<!--
				<mat-card-subtitle>
					Alle ({{dataSource.data.length}}) | Offen ({{openConsultations.length}}) | Geschlossen ({{dataSource.data.length - openConsultations.length}})
				</mat-card-subtitle>
				-->
			</mat-card-header>
			<mat-card-content class="!inline-flex flex-col">
                <button mat-flat-button color="primary" class="uppercase"
                    (click)="startConsultation()" [disabled]="sittingStatus === 'CLOSED' || ['ADMIN'].includes(currentUser.role)">
                    <mat-icon>play_arrow</mat-icon> Neue Beratung
                </button>
			</mat-card-content>
		</mat-card>

        <button class="mt-4 w-full uppercase" mat-raised-button [routerLink]="'/sitting'"><mat-icon>arrow_left</mat-icon>Zurück</button>

	</div>

    <div class="w-full p-5">

		<mat-card>

			<mat-card-header class="mb-5">
				<mat-card-title class="!inline-flex !items-center">
					<mat-icon  fontSet="material-icons-outlined" class="mr-2">inbox</mat-icon> Sprechstunde {{sittingNumber}} <mat-icon>navigate_next</mat-icon> Alle Beratungen
				</mat-card-title>
			</mat-card-header>

			<mat-card-content class="!flex !flex-col">
				
                <table mat-table  [dataSource]="dataSource" class="mat-elevation-z0">

					<!-- Open Samples Column -->
					<ng-container matColumnDef="samples">
						<th mat-header-cell *matHeaderCellDef> Proben </th>
						<td mat-cell *matCellDef="let element">
							<button mat-icon-button color="primary" [breadcrumb]="['consultation', element]" [routerLink]="'sample'">
								<mat-icon>folder_open</mat-icon>
							</button>
						</td>
					</ng-container>

					<!-- Consultation Status Column -->
					<ng-container matColumnDef="consultationStatus">
						<th mat-header-cell *matHeaderCellDef> Status </th>
						<td mat-cell *matCellDef="let element"> {{element.consultationStatus | consultationStatus}} </td>
					</ng-container>

					<!-- Client UID Column -->
					<ng-container matColumnDef="clientUid">
						<th mat-header-cell *matHeaderCellDef> Klient*in UID </th>
						<td mat-cell *matCellDef="let element"> 
							{{element.client.clientUid}} 
							<app-copy-to-clipboard-button [textToCopy]="element.client.clientUid"></app-copy-to-clipboard-button>
						</td>
					</ng-container>

					<!-- Results Column -->
					<ng-container matColumnDef="hasResultsToReport">
						<th mat-header-cell *matHeaderCellDef> Rückmeldungen offen </th>
						<td mat-cell *matCellDef="let element"> 
							<mat-spinner *ngIf="isLoadingOpenResults[element.id]" [diameter]="25"></mat-spinner>
							<span *ngIf="!isLoadingOpenResults[element.id]" [ngClass]="element.client.analyzedSamples > 0 ? 'text-[#f44336]' : ''">{{element.client.analyzedSamples}}</span>
						</td>
					</ng-container>

					<!-- Last Visit Column -->
					<ng-container matColumnDef="lastVisit">
						<th mat-header-cell *matHeaderCellDef> Datum letzter Besuch</th>
						<td mat-cell *matCellDef="let element">
							<mat-spinner *ngIf="isLoadingOpenResults[element.id]" [diameter]="25"></mat-spinner> 
							<span *ngIf="!isLoadingOpenResults[element.id]" >
								{{element.client.lastVisit | date: 'dd.MM.YYYY'}}
								<mat-icon class="ml-1 align-bottom cursor-pointer" 
										  color="warn"
										  matTooltip="Der letzte Besuch ist über ein Jahr her, prüfen Sie die Stammdaten de*r*s Klient*in."
										  *ngIf="lastVisitOverAYearAgo(element.client.lastVisit)">
										  report
								</mat-icon>
							</span>
						</td>
					</ng-container>

					<!-- Timestamp Column -->
					<ng-container matColumnDef="timestamp">
						<th mat-header-cell *matHeaderCellDef> Beginn der Beratung </th>
						<td mat-cell *matCellDef="let element"> {{element.timestamp | date: 'EEEE, dd.MM.yyyy \'um\'
							HH:mm \'Uhr\''}} </td>
					</ng-container>

					<!-- Close Consultation Column -->
					<ng-container matColumnDef="closeConsultation">
						<th mat-header-cell *matHeaderCellDef> Beratung schließen </th>
						<td mat-cell *matCellDef="let element"> 
							<button class="uppercase" [disabled]="element.consultationStatus !== 'OPEN' || ['ADMIN'].includes(currentUser.role)" mat-flat-button color="warn"
								(click)="stopConsultation(element)">
								<mat-icon>stop</mat-icon> Schließen
							</button>
						</td>
					</ng-container>
					
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>

					<!-- Row shown when there is no matching data. -->
					<ng-container *ngIf="!isLoading">
						<tr class="mat-row" *matNoDataRow>
							<td class="mat-cell text-center p-12" colspan="7">
								<p>Keine Beratung gefunden.</p>
								<button type="button" mat-raised-button class="uppercase" (click)="startConsultation()" [disabled]="sittingStatus === 'CLOSED' || ['ADMIN'].includes(currentUser.role)">
									<mat-icon>play_arrow</mat-icon> Beratung starten
								</button>
							</td>
						</tr>
					</ng-container>

				</table>

				<!--<mat-paginator class="mat-elevation-z1 pb-3" [length]="totalElements" [pageSize]="paginationParams.size" [pageSizeOptions]="[5, 10, 25, 100]"(page)="pageChanged($event)" showFirstLastButtons>
				</mat-paginator> -->

			</mat-card-content>
		</mat-card>

		<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

	</div>


</div>
