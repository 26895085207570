import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ImageService {

	constructor(
		private http: HttpClient
	) { }

	downloadFileAsBase64(url: string, fileName: string): Observable<{ imageUrl: string; file: File; fileSize: number } | null> {
		return this.http.get(url, { responseType: 'blob' }).pipe(
			switchMap(blob => {
				const file = new File([blob], fileName, { type: blob.type });
				return this.convertBlobToBase64(blob).pipe(
					map(base64Image => ({
						imageUrl: base64Image,
						fileName: fileName,
						file: file,
						fileSize: blob.size
					}))
				);
			}),
			catchError(() => of(null)) // Error handling
		);
	}

	// Utility function to convert Blob to Base64
	private convertBlobToBase64(blob: Blob): Observable<string> {
		return new Observable<string>(observer => {
			const reader = new FileReader();
			reader.onload = () => {
				observer.next(reader.result as string);
				observer.complete();
			};
			reader.onerror = () => {
				observer.error('Error converting blob to base64');
			};
			reader.readAsDataURL(blob);
		});
	}
}
