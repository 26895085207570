import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '@core/services/api.service';
import { Store } from '@ngxs/store';
import { Consultation } from '@shared/models/consultation.interface';
import { StartConsultationDialogComponent } from './start-consultation-dialog/start-consultation-dialog.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { Sample } from '@shared/models/sample.interface';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { User } from '@shared/models/user.interface';


@Component({
	selector: 'app-consultation',
	templateUrl: './consultation.component.html',
	styleUrls: ['./consultation.component.scss']
})
export class ConsultationComponent implements OnInit {

	sittingId: number;
	sittingNumber: string = '';
	sittingStatus: string = '';

	// Define table columns
	displayedColumns: string[] = ['samples', 'consultationStatus', 'clientUid', 'timestamp', 'hasResultsToReport', 'lastVisit', 'closeConsultation'];

	// Initialize data source with an empty array
	dataSource = new MatTableDataSource();

	// Define an array of open consultations
	openConsultations: Consultation[] = [];

	/** Boolean flag indicating whether a loading indicator should be displayed. */
	isLoading: boolean = false;

	isLoadingOpenResults: any = {};

	currentUser: User

	constructor(
		private apiService: ApiService,
		private store: Store,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		/** Get user object from the Ngxs store snapshot */
		this.currentUser = this.store.selectSnapshot<User>((state: any) => state.auth.user);

		// Get sitting id from the Ngxs store snapshot
		this.sittingId = this.store.selectSnapshot<number>((state: any) => state.breadcrumb.sitting.id);

		// Get sitting number from the Ngxs store snapshot
		this.sittingNumber = this.store.selectSnapshot<string>((state: any) => state.breadcrumb.sitting.sittingNumber);

		// Get sitting status from the Ngxs store snapshot
		this.sittingStatus = this.store.selectSnapshot<string>((state: any) => state.breadcrumb.sitting.sittingStatus);

		// Fetch consultations for the current sitting
		this.getConsultations();
	}

	/**
	 * Fetches consultations for the current sitting
	 *
	 * @since 1.0.0
	 * @params none
	 * @returns void
	 */
	getConsultations(): void {

		this.isLoading = true;

		this.apiService.getConsultations(this.sittingId).subscribe({
			next: (data: any) => {

				const consultations = data.content;
				this.dataSource = new MatTableDataSource(consultations);

				const sampleRequests = consultations.map((consultation: Consultation) => {
					this.isLoadingOpenResults[consultation.id] = true;
					return this.apiService.getSamplesByClientId(consultation.client.id);
				});

				this.isLoading = false;

				forkJoin(sampleRequests).subscribe({
					next: (samplesResponses: any) => {
						for (let i = 0; i < samplesResponses.length; i++) {
							const consultation: Consultation = consultations[i];
							const samples: Sample[] = samplesResponses[i];
							const unreportedSamples: Sample[] = samples.filter((sample: Sample) => sample.sampleStatus === 'ANALYZED');
							consultation.client.analyzedSamples = unreportedSamples.length;
							consultation.client.lastVisit = samples[0]?.submissionDate || '';
							this.isLoadingOpenResults[consultation.id] = false;
						}
					},
					error: () => {
						consultations.forEach((consultation: Consultation) => {
							consultation.client.analyzedSamples = -1;
							this.isLoadingOpenResults[consultation.id] = false;
						});
					}
				});
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}


	lastVisitOverAYearAgo(date: string): boolean {
		const aYearAgo = moment().subtract(1, 'year');
		const lastVisit = moment(date);
		return lastVisit.isBefore(aYearAgo);
	}

	/**
	 * Starts a new consultation by opening a client dialog
	 *
	 * @since 1.0.0
	 * @params none
	 * @returns void
	 */
	startConsultation(): void {
		// Open client dialog
		let dialogRef = this.dialog.open(StartConsultationDialogComponent, {
			autoFocus: false,
			disableClose: true,
			width: '55vw',
		});

		dialogRef.afterClosed().subscribe(res => {
			if (res && res.data) {
				this.apiService.openConsultation(this.sittingId, res.data.id).subscribe(() => {
					// Update consultation list
					this.getConsultations();
				});
			}
		});
	}

	/**
	 * Stops a consultation by closing it and displaying an alert message
	 *
	 * @since 1.0.0
	 * @params id - consultation ID
	 * @returns void
	 */
	stopConsultation(consultation: Consultation): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '33vw',
			autoFocus: true,
			data: {
				title: `Beratung wirklich beenden?`,
				text: `Sind Sie absolut sicher, dass Sie die offene Beratung beenden wollen?`,
				// confirmation: `${element.sampleNumber}`,
				buttonText: 'BEENDEN'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res.data === 'confirmed') {
				this.apiService.closeConsultation(consultation).subscribe(() => {
					// Update consultation list
					this.getConsultations();
				});
			}
		});

	}
}