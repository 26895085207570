import { Injectable } from '@angular/core';
import { State, Action, StateContext, Select, Selector } from '@ngxs/store';
import { Consultation } from '@shared/models/consultation.interface';
import { Sample } from '@shared/models/sample.interface';
import { Sitting } from '@shared/models/sitting.interface';
import { SetConsultation, SetSample, SetSitting } from './breadcrumb.action';

export interface BreadcrumbStateModel {
	sitting: Sitting | null;
	consultation: Consultation | null;
	sample: Sample | null;
}

@State<BreadcrumbStateModel>({
	name: 'breadcrumb',
	defaults: {
		sitting: null,
		consultation: null,
		sample: null
	}
})
@Injectable()
export class BreadcrumbState {

	@Action(SetSitting)
	setSitting(ctx: StateContext<BreadcrumbStateModel>, { payload }: SetSitting) {
		ctx.patchState({ sitting: payload });
	}

	@Action(SetConsultation)
	setConsultation(ctx: StateContext<BreadcrumbStateModel>, { payload }: SetConsultation) {
		ctx.patchState({ consultation: payload });
	}

	@Action(SetSample)
	setSample(ctx: StateContext<BreadcrumbStateModel>, { payload }: SetSample) {
		ctx.patchState({ sample: payload });
	}

}