<app-toolbar></app-toolbar>

<div class="flex flex-col h-auto p-5">

	<div class="flex flex-row gap-4">

		<div class="w-1/4">

			<mat-card>
				<mat-card-header class="flex flex-col">
					<mat-card-title class="!inline-flex !items-center">
                        <span class="material-symbols-outlined mr-2">person_add</span>Benutzer*in anlegen
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="!inline-flex flex-col mt-4">
                    <button mat-flat-button color="primary" (click)="openUserDialog()"><mat-icon>add</mat-icon> Hinzufügen</button>
				</mat-card-content>
			</mat-card>
		</div>

		<div class="w-full">

			<mat-card>
				<mat-card-header class="mb-5">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">group</span> Alle Benutzer*innen
					</mat-card-title>
				</mat-card-header>

				<mat-card-content class="!flex !flex-col">

					<table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

                        <!-- Edit Column -->
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef> Bearbeiten </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="primary" (click)="openUserDialog(element)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                    
                        <!-- User Status Column -->
                        <ng-container matColumnDef="userStatus">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.userStatus | userStatus}} </td>
                        </ng-container>
                    
                        <!-- User Role Column -->
                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef> Nutzerrolle </th>
                            <td mat-cell *matCellDef="let element"> {{element.role | role}} </td>
                        </ng-container>
                    
                        <!-- Username Column -->
                        <ng-container matColumnDef="username">
                            <th mat-header-cell *matHeaderCellDef> Benutzername </th>
                            <td mat-cell *matCellDef="let element"> {{element.username}} </td>
                        </ng-container>

                        <!-- Organization Column -->
                        <ng-container matColumnDef="organization">
                            <th mat-header-cell *matHeaderCellDef> Organisation </th>
                            <td mat-cell *matCellDef="let element"> {{element.organisation | organization}} </td>
                        </ng-container>

                         <!-- Last Login Column -->
                        <ng-container matColumnDef="lastLogin">
                            <th mat-header-cell *matHeaderCellDef> Letzter Login </th>
                            <td mat-cell *matCellDef="let element"> {{element.lastLogin | date: 'dd. MMMM y \'um\' HH:mm:ss \'Uhr\''}} </td>
                        </ng-container>

                        <!-- Created Column -->
                        <ng-container matColumnDef="created">
                            <th mat-header-cell *matHeaderCellDef> Konto erstellt </th>
                            <td mat-cell *matCellDef="let element">  {{element.created | date: 'dd.MM.YYYY'}} </td>
                        </ng-container>

                        <!-- Delete Column -->
                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef> Löschen </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="warn" (click)="deleteUser(element)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                    
                        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>
                    
                        <!-- Row shown when there is no matching data. 
                        <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                        </tr> -->
                    </table>

				</mat-card-content>
			</mat-card>

			<mat-progress-bar *ngIf="!dataSource.data.length" mode="indeterminate"></mat-progress-bar>

		</div>

	</div>

</div>