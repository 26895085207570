import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SampleStatusPipe } from '@core/pipes/sample-status.pipe';
import { ApiService } from '@core/services/api.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
	selector: 'app-sample-number-search-form',
	templateUrl: './sample-number-search-form.component.html',
	styleUrls: ['./sample-number-search-form.component.scss'],
	providers: [SampleStatusPipe]
})
export class SampleNumberSearchFormComponent {

	@Output() result = new EventEmitter<any>();
	@Output() valid = new EventEmitter<any>();

	@Input() validStatus: string[] = ['PENDING', 'ANALYZED', 'REPORTED', 'ASSESSED'];
	@Input() invalidStatus: string[] = ['INVALID'];

	@Input() sampleNumberCtrl: FormControl = new FormControl();

	/** A boolean indicating whether the sample number lookup is currently loading */
	isLoading: boolean = false;

	/** A boolean indicating whether the sample number entered is valid */
	isValidSampleNumber: boolean = false;

	constructor(
		private apiService: ApiService,
		private snackBar: MatSnackBar,
		private sampleStatusPipe: SampleStatusPipe
	) { }

	/**
	 * Looks up a sample number using the API service and updates the form with the sample ID if the sample is pending.
	 * @since 1.0.0
	 * @param {any} event - The event object.
	 * @returns {void}
	 */
	sampleNumberLookup(event: any): void {
		const filterValue = (event.target as HTMLInputElement).value;
		if (filterValue && filterValue.length >= 9) {
			this.isLoading = true;
			this.apiService.getSamplesBySampleNumber(filterValue).pipe(
				debounceTime(2000),
				distinctUntilChanged(),
			).subscribe({
				next: (data: any) => {
					if ( data && this.validStatus.includes(data.sampleStatus) ) {
						this.valid.emit(true);
						this.result.emit(data)
					}
					else if (data && this.invalidStatus.includes(data.sampleStatus) ) {
						this.result.emit(null)
						this.valid.emit(false);
						this.sampleNumberCtrl.setErrors({'invalidStatus': true});
						this.snackBar.open(
							`Die Probe ${data.sampleNumber} kann nicht verwendet werden, da sie den Status "${this.sampleStatusPipe.transform(data.sampleStatus)}" hat.`,
							'Schließen',
							{ panelClass: 'error-snack', duration: 3000 }
						)
					}
					this.isLoading = false;
				},
				error: () => {
					this.isLoading = false;
					this.valid.emit(false);
					this.sampleNumberCtrl.setErrors({'invalidStatus': true});
				}
			})
		} else {
			this.isLoading = false;
			this.valid.emit(false);
			this.sampleNumberCtrl.setErrors({'invalidStatus': true});
		}
	}
}
