import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

	transform(value: number): string {
		
		if (value == null || isNaN(value)) {
			return '';
		}

		// Replace "." with ","
		const formattedValue = value.toString().replace('.', ',');

		return formattedValue;
	}

}
