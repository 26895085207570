import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-client-search-tab',
  templateUrl: './client-search-tab.component.html',
  styleUrls: ['./client-search-tab.component.scss']
})
export class ClientSearchTabComponent {

	/** The reference to the MatTabGroup component in the parent component. */
	@Input() tabGroup: MatTabGroup;

	/** Event emitter that emits the ID of the newly created client when a new consultation is created. */
	@Output() createConsultation = new EventEmitter<number>();

	/** The displayed columns in the table. */
	displayedColumns: string[] = ['clientUid', 'gender', 'postalCode', 'nationality', 'firstConsultationDate', 'lastConsultationDate', 'edit'];

	/** The data source for the table. */
	dataSource = new MatTableDataSource();

	/** The value of the client UID search control. */
	clientUidCtrlValue: string = ''
	
	clientUid: string = ''
	
	/**
	 * Emits the ID of the newly created client to the parent component.
	 * @since 1.0.0
	 * @param id - The ID of the newly created client.
	 * @returns void
	 */
	onCreateConsultation(client: number): void {
		this.createConsultation.emit(client);
	}

	/**
	 * Sets the data source for the table to the provided search results.
	 * @since 1.0.0
	 * @param data - The list of clients returned from the search.
	 * @returns void
	 */
	onSearchResultsChange(data: any[]): void {
		this.dataSource = new MatTableDataSource(data);
	}

	/**
	 * Sets the value of the client UID control to the provided value.
	 * @since 1.0.0
	 * @param value - The new value of the client UID control.
	 * @returns void
	 */
	onClientCtrlValueChange(value: string): void {
		this.clientUidCtrlValue = value;
	}

	onClientUidReconstruction(value: string): void {
		this.clientUid = value
	}

}
