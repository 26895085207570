import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@core/services/api.service';
import { TextblockTinymceConfig } from './textblock-tinymce.config';

@Component({
	selector: 'app-textblock-dialog',
	templateUrl: './textblock-dialog.component.html',
	styleUrls: ['./textblock-dialog.component.scss']
})
export class TextblockDialogComponent implements OnInit {

	/** Flag indicating whether the component is in add mode. */
	isAddMode: boolean = false;

	/** The form group used to create and update text blocks. */
	textblockForm: FormGroup;

	/** The number of characters in the text field. */
	textCount: number = 0;

	tinymceConfig: any = TextblockTinymceConfig.settings

	constructor(
		private fb: FormBuilder,
		private apiService: ApiService,
		private snackBar: MatSnackBar,
		private dialogRef: MatDialogRef<TextblockDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit(): void {

		// Set isAddMode to true if the dialog was opened without an ID
		this.isAddMode = !this.data.id;

		this.textblockForm = this.fb.group({
			id: new FormControl(null),
			description: new FormControl('', Validators.required),
			text: new FormControl('', [Validators.required, Validators.maxLength(10000)])
		});

		if ( !this.isAddMode && this.data.id ) {
			this.getTextblock(this.data.id);
		}

	}

	/**
	 * Updates the number of characters in the text field.
	 *
	 * @param value The value of the text field.
	 * @since 1.0.0
	 * @return void
	 */
	updateTextCount(value: string): void {
		this.textCount = value.length
	}

	/**
	 * Gets the textblock with the specified ID.
	 *
	 * @param id The ID of the textblock to get.
	 * @since 1.0.0
	 * @return void
	 */
	getTextblock(id: number): void {
		this.apiService.getTextblock(id).subscribe((data: any) => {
			if ( data ) {
				this.textblockForm.patchValue(data)
				this.updateTextCount(data.text)
			}
		})
	}

	/**
	 * Submits the textblock form.
	 *
	 * If the form is valid, either creates or updates the textblock.
	 *
	 * @since 1.0.0
	 * @return void
	 */
	onSubmit(): void {
		if ( this.textblockForm.valid ) {
			if ( !this.isAddMode && this.data.id ) {
				this.updateTextblock(this.data.id);
			} else {
				this.createTextblock();
			}
		}
	}

	/**
	 * Updates the textblock with the specified ID.
	 *
	 * @param id The ID of the textblock to update.
	 * @since 1.0.0
	 * @return void
	 */
	private updateTextblock(id: number): void {
		this.apiService.updateTextblock(id, this.textblockForm.value).subscribe((data: any) => {
			this.snackBar.open('Textbaustein erfolgreich geändert.', 'Schließen', {panelClass: 'success-snack'});
			// Close the dialog and pass the 'success' data back to the caller
			this.dialogRef.close({data: 'success'});
		});
	}

	/**
	 * Creates a new textblock.
	 *
	 * @since 1.0.0
	 * @returns void
	 */
	private createTextblock(): void {
		this.apiService.createTextblock(this.textblockForm.value).subscribe((data: any) => {
			this.snackBar.open('Textbaustein erfolgreich erstellt.', 'Schließen', {panelClass: 'success-snack'})
			// Close the dialog and pass the 'success' data back to the caller
			this.dialogRef.close({data: 'success'});
		});
	}

}
