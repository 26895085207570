import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '@core/services/api.service';
import { ClientDialogComponent } from './client-dialog/client-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { User } from '@shared/models/user.interface';
import { Store } from '@ngxs/store';


@Component({
	selector: 'app-client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

	/** ViewChild decorator that access the MatPaginator instance */
	@ViewChild(MatPaginator) paginator: MatPaginator;

	/** Boolean flags indicating whether a loading indicator should be displayed. */
	isLoading: boolean = false;

	/** Define table column names */
	displayedColumns: string[] = ['edit', 'clientUid', 'gender', 'postalCode', 'nationality', 'firstConsultationDate', 'lastConsultationDate', 'delete'];

	/** MatTableDataSource instance */
	dataSource = new MatTableDataSource();

	/** Total number of elements in the data source. */
	totalElements: number = 0;

	/** Number of items to display on a page. */
	pageSize: number = 10;

	/** Current page index. */
	pageIndex: number = 0;
	
	/** Search value for client. */
	clientSearchValue: string = ''

	currentUser: User

	constructor(
		private apiService: ApiService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private store: Store
	) { }

	ngOnInit(): void {
		// Get user object from the Ngxs store snapshot
		this.currentUser = this.store.selectSnapshot<User>((state: any) => state.auth.user);

		this.getClients();
	}
	
	/**
	 * Gets clients from the API and updates the data source.
	 * @since 1.0.0
	 * @param size - The number of items per page
	 * @param page - The current page index
	 * @returns {void}
	 */
	getClients(size: number = this.pageSize, page: number = 0): void {
		this.apiService.getClients('', size, page).subscribe({
			next: (data: any) => {
				this.totalElements = data.totalElements
				this.dataSource = new MatTableDataSource(data.content)
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		})
	}

	/**
	 * Event handler for when the page in the paginator changes.
	 * @since 1.0.0
	 * @param {any} event - The page change event.
	 * @return void
	 */
	pageChanged(event: any): void {
		this.pageSize = event.pageSize
		this.pageIndex = event.pageIndex
		this.getClients(this.pageSize, this.pageIndex);
	}

	/**
	 * Event handler for search result event.
	 * @since 1.0.0
	 * @param {any} event - The search result event.
	 * @returns void
	 */
	onSearchResult(event: any): void {
		if ( event.length > 0 ) {
			this.totalElements = event.length
			this.dataSource = new MatTableDataSource(event)
		}
	}

	/**
	 * Event handler for client UID control value event.
	 * @since 1.0.0
	 * @param {string} event - The client UID control value.
	 * @returns void
	 */
	onClientUidCtrlValue(event: string): void {
		this.clientSearchValue = event
		if ( !event || event.length === 0 ) {
			this.getClients(this.pageSize, 0); 
			this.paginator.firstPage();
		}
	}

	/**
	 * Deletes a client from the API.
	 *
	 * @since 1.0.0
	 * @param {any} client - The client object to be deleted.
	 * @returns void
	 */
	deleteClient(element: any): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '30vw',
            autoFocus: true,
			data: {
				title: `Klient*in ${element.clientUid} unwiderruflich löschen?`,
				text: `Sind Sie absolut sicher, dass Sie diese*n Klient*in löschen wollen? Wenn ja, tippen Sie <code>${element.clientUid}</code> in das Feld ein und fahren Sie fort. Andernfalls klicken Sie auf ABBRECHEN.`,
				confirmation: `${element.clientUid}`
			}
        });
		dialogRef.afterClosed().subscribe(res => {
			if ( res.data === 'confirmed' ) {
				this.apiService.deleteClient(element.id).subscribe({
					next: () => {
						this.snackBar.open(`Klient*in ${element.clientUid} erfolgreich gelöscht.`, 'Schließen', { panelClass: 'success-snack', duration: 2000 })
						this.getClients(this.pageSize, this.pageIndex);
					}
				})
			}	
		});
	}

	/**
	 * Opens a dialog for editing a client.
	 *
	 * @since 1.0.0
	 * @param {any} client - The client object to be edited.
	 * @returns void
	 */
	editClient(client: any): void {

		let dialogRef = this.dialog.open(ClientDialogComponent, {
			data: {
				client: client,
				isAddMode: false
			},
			autoFocus: false,
			disableClose: true,
			width: '45vw',
		});

		dialogRef.afterClosed().subscribe(res => {
			if ( res && res.data ) {
				this.snackBar.open(`Klient*in ${res.data.clientUid} erfolgreich geändert.`, 'Schließen', { panelClass: 'success-snack', duration: 2000 })
				this.getClients(this.pageSize, this.pageIndex);
			}
		});
	}


	/**
	 * Opens a dialog for adding a new client.
	 *
	 * @since 1.0.0
	 * @returns {void}
	 */
	addClient(): void {

		let dialogRef = this.dialog.open(ClientDialogComponent, {
			data: {
				isAddMode: true
			},
			autoFocus: false,
			disableClose: true,
			width: '45vw',
		});

		dialogRef.afterClosed().subscribe(res => {
			if ( res && res.data ) {
				this.snackBar.open(`Klient*in ${res.data.clientUid} erfolgreich hinzugefügt.`, 'Schließen', { panelClass: 'success-snack', duration: 2000 })
				this.getClients(this.pageSize, 0);
				this.paginator.firstPage();
			}
		});
	}


}
