export class SetSitting {
	static readonly type = '[Breadcrumb] Set current Sitting';
	constructor(public payload: any) { }
}

export class SetConsultation {
	static readonly type = '[Breadcrumb] Set current Consultation';
	constructor(public payload: any) { }
}

export class SetSample {
	static readonly type = '[Breadcrumb] Set current Sample';
	constructor(public payload: any) { }
}