import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FormChangeDetectorService {
	private modifiedForms: Set<string> = new Set();
	private formChangeSubject = new Subject<Set<string>>();

	formChange$ = this.formChangeSubject.asObservable();

	trackFormChanges(formName: string, form: FormGroup): void {
		form.valueChanges.subscribe(() => {
			if (!this.modifiedForms.has(formName)) {
				this.modifiedForms.add(formName);
			}

			// Emit the set of modified forms whenever a change is detected
			this.formChangeSubject.next(this.modifiedForms);
		});
	}

	reset(): void {
		this.modifiedForms.clear();
		// Emit an empty set to indicate the reset action
		this.formChangeSubject.next(this.modifiedForms);
	}
}
