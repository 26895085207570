import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';


@Component({
	selector: 'app-client-add-edit',
	templateUrl: './client-add-edit.component.html',
	styleUrls: ['./client-add-edit.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ClientAddEditComponent implements OnInit {

	/** Event emitter for form submission */
	@Output() formSubmit = new EventEmitter<any>();

	/** Input for client data */
	@Input() clientData: any

	/** Form group for client form */
	clientForm: FormGroup

	/** Form control for selected tab index */
	selected = new FormControl(0);

	/** Array to store drug use tabs */
	tabsDrugUse: string[] = []
	
	/** Today date for max attribute of the datepicker */
	today: Date = new Date();

	/** Options for migration background dropdown */
	optionsMigrationBackground: any = [
		{ name: 'Nein', value: 'MIGRATION_NONE' },
		{ name: 'Selbst migriert', value: 'MIGRATION_1ST' },
		{ name: 'Kind von Migranten', value: 'MIGRATION_2ND' },
		{ name: 'In 3. Generation', value: 'MIGRATION_3RD' },
	];

	/** Options for graduation dropdown */
	optionsGraduation: any = [
		{ name: 'In Schulausbildung', value: 'AT_SCHOOL' },
		{ name: 'Ohne Schulabschluss', value: 'NO_GRADUATION' },
		{ name: 'Hauptschul-/Volksschulabschluss', value: 'BASIC_SCHOOL_GRADUATION' },
		{ name: 'Realschule / Polytechnische OS', value: 'SECONDARY_SCHOOL_GRADUATION' },
		{ name: '(Fach-)hochschulreife/Abitur', value: 'HIGH_SCHOOL_GRADUATION' },
		{ name: 'Andere', value: 'OTHER_GRADUATION' },
	];

	/** Options for employment dropdown */
	optionsEmployment: any = [
		{ name: 'Auszubildende(r)', value: 'TRAINEE' },
		{ name: 'Arbeiter/in / Angestellte(r) / Beamte(r)', value: 'EMPLOYEE' },
		{ name: 'Selbständig', value: 'SELF_EMPLOYED' },
		{ name: 'mithelfender Familienangehörige', value: 'FAMILY_WORKER' },
		{ name: 'in beruflicher Rehabilitation', value: 'REHABILITATION' },
		{ name: 'arbeitslos', value: 'UNEMPLOYED' },
		{ name: 'Schüler/in / Studierende/r', value: 'STUDENT' },
		{ name: 'Hausfrau / Hausmann', value: 'HOMEMAKER' },
		{ name: 'Rentner/in', value: 'PENSIONER' },
		{ name: 'Sonstiges', value: 'OTHER' },
	];

	/** Options for consumptions type dropdown */
	optionsConsumptionType: any = [
		{ name: 'Injektion', value: 'INJECTION' },
		{ name: 'Rauchen/Inhalieren/Schnüffeln', value: 'INHALATION' },
		{ name: 'Essen/Trinken', value: 'ORAL' },
		{ name: 'Schnupfen/Schniefen', value: 'NASAL' },
		{ name: 'Andere Arten', value: 'OTHER' },
	];

	constructor(
		private fb: FormBuilder,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {

		this.clientForm = this.fb.group({
			age: new FormControl('', Validators.required),
			clientUid: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z]{2}\d{4}$/)]), // RegEx: 2 letters, 4 numbers
			drugUses: new FormArray([]),
			employment: new FormControl(null),
			firstConsultationDate: new FormControl(moment(new Date()).format('YYYY-MM-DD')),
			gender: new FormControl('', Validators.required),
			graduation: new FormControl(null),
			hadAddictionCare: new FormControl(null),
			// hadDrugcheckingCare: new FormControl(null),
			hasInfoFromFriends: new FormControl(false),
			hasInfoFromInternet: new FormControl(false),
			hasInfoFromMedia: new FormControl(false),
			hasInfoFromPrevention: new FormControl(false),
			hasInfoFromStand: new FormControl(false),
			hasInfoOther: new FormControl(false),
			hasNoneInfo: new FormControl(false),
			migrationBackground: new FormControl(null),
			nationality: new FormControl(null),
			nationality2: new FormControl(null),
			location: new FormControl(null),
			postalCode: new FormControl(null),
		})

		if ( this.clientData ) { 
			this.clientForm.patchValue(this.clientData) 
			// Patch drug uses array into form
			for (let i = 0; i < this.clientData.drugUses.length; i++) {
				this.addDrugUse();
				this.drugUses.at(i).patchValue(this.clientData.drugUses[i] || {});
				// this.drugUses.at(i).updateValueAndValidity();
			}
		}
	}

	formatOutput(controlName: string, date: string, format: string = 'YYYY-MM-DD') {
		this.clientForm.get(controlName)?.patchValue(moment(date).format(format));
	}

	/**
	 * Getter for the FormArray containing drugUses in the clientForm
	 * @since 1.0.0
	 * @returns The FormArray containing drugUses in the clientForm
	 */
	get drugUses(): FormArray {
		return this.clientForm.get('drugUses') as FormArray;
	}

	/**
	 * Add a new FormGroup for a drug use to the drugUses FormArray
	 * @since 1.0.0
	 * @returns void
	 */
	addDrugUse(): void {

		this.tabsDrugUse.push(`Substanz`);
		this.selected.setValue(this.tabsDrugUse.length - 1);

		const drugUse = new FormGroup({
			id: new FormControl(null),
			consumptionType: new FormControl('', Validators.required),
			name: new FormControl('', Validators.required),
			numberDays: new FormControl(null, [
				Validators.required,
				Validators.pattern(/^(0?[1-9]|[1-2][0-9]|30)$/), // only numbers from 1-30
				// Validators.minLength(1),
				Validators.maxLength(2)
			]),
			startingAge: new FormControl(null, [
				Validators.required,
				Validators.pattern(/^(0?[1-9]|[1-9][0-9])$/), // only numbers from 1-99
				// Validators.minLength(1),
				Validators.maxLength(2)
			]),
		});
		
		this.drugUses.push(drugUse);
	}

	/**
	 * Remove the FormGroup for a drugUse at the given index from the drugUses FormArray
	 * @since 1.0.0
	 * @param i The index of the drugUse FormGroup to remove
	 * @returns void
	 */
	removeDrugUse(i: number): void {
		this.drugUses.removeAt(i);
		this.tabsDrugUse.splice(i, 1);
	}
	
	/**
	 * Event handler for client UID change event.
	 * @since 1.0.0
	 * @param {string} uid - The client UID value
	 * @returns void
	 */
	onClientUidChange(uid: string): void {
		this.clientForm.get('clientUid')?.patchValue(uid)
	}

	/**
	 * Event handler for form submission.
	 * @since 1.0.0
	 * @returns void
	 */
	onSubmit(): void {
		if ( this.clientForm.valid ) {
			// Emit form data to parent component
		  	this.formSubmit.emit(this.clientForm.value);

		} else {
			this.snackBar.open('Pflichtfelder* wurden noch nicht alle ausgefüllt.', 'Schließen', { panelClass: 'error-snack', duration: 2000 })
			// Mark all form controls as touched for validation
			this.clientForm.markAllAsTouched();
		}
	}

}
