import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-copy-to-clipboard-button',
  templateUrl: './copy-to-clipboard-button.component.html',
  styleUrls: ['./copy-to-clipboard-button.component.scss']
})
export class CopyToClipboardButtonComponent {

	/** Input property to specify the text to be copied to clipboard */
	@Input('textToCopy') textToCopy: string;
	
	constructor(
		private snackBar: MatSnackBar,
		private clipboard: Clipboard
	) { }

	/**
	 * Copies the specified text to clipboard and shows a snackbar notification.
	 * @since 1.0.0
	 * @returns void
	 */
	copyToClipboard(): void {
		this.clipboard.copy(this.textToCopy); // Copy the text to clipboard
		this.snackBar.open('In Zwischenablage kopiert', '', { // Show a snackbar notification
			duration: 2000 // Set the duration of the snackbar to 2000 milliseconds (2 seconds)
		});
	}
}
