import { HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@core/services/api.service';
import { saveAs } from 'file-saver-es';
import * as moment from 'moment';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent {

  	/** Form Group for from until query */
	daterangeFormSamples = new FormGroup({
		from: new FormControl<Date | null>(null, Validators.required),
		until: new FormControl<Date | null>(null, Validators.required),
	});

	daterangeFormClients = new FormGroup({
		from: new FormControl<Date | null>(null, Validators.required),
		until: new FormControl<Date | null>(null, Validators.required),
	});

	sampleStatusCtrl: FormControl = new FormControl('')

	optionsSampleStatus: string[] = ['PENDING', 'ANALYZED', 'REPORTED', 'INVALID']

  	today: moment.Moment  = moment()
	oneYearAgo: moment.Moment = this.today.clone().subtract(1, 'year')

	isLoading: boolean = false

	constructor(
		private apiService: ApiService,
		private snackBar: MatSnackBar
	) {}

	downloadSamples(): void {
		this.isLoading = true;

		const from: string = moment(this.daterangeFormSamples.get('from')?.value).format('YYYY-MM-DD');
		const until: string = moment(this.daterangeFormSamples.get('until')?.value).format('YYYY-MM-DD');
		const sampleStatus: string = this.sampleStatusCtrl.value;


		this.apiService.exportSamples(from, until, sampleStatus).subscribe({
			next: (response: HttpResponse<ArrayBuffer>) => {
				const contentType = 'application/octet-stream';
				const contentDisposition = response.headers.get('Content-Disposition');

				if (contentDisposition) {

					const fileName = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');
					const blob = new Blob([response.body!], { type: contentType });

					saveAs(blob, fileName);

					this.snackBar.open(`${fileName} wurde erfolgreich exportiert.`, '', { panelClass: 'success-snack', duration: 5000 });
				}

				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}

	downloadClients(): void {
		this.isLoading = true;

		const from: string = moment(this.daterangeFormClients.get('from')?.value).format('YYYY-MM-DD');
		const until: string = moment(this.daterangeFormClients.get('until')?.value).format('YYYY-MM-DD');

		this.apiService.exportClients(from, until).subscribe({
			next: (response: HttpResponse<ArrayBuffer>) => {
				const contentType = 'application/octet-stream';
				const contentDisposition = response.headers.get('Content-Disposition');

				if (contentDisposition) {

					const fileName = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');
					const blob = new Blob([response.body!], { type: contentType });

					saveAs(blob, fileName);

					this.snackBar.open(`${fileName} wurde erfolgreich exportiert.`, '', { panelClass: 'success-snack', duration: 5000 });
				}

				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}
}
