import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@core/services/api.service';
import { Store } from '@ngxs/store';
import { SampleDetailDialogComponent } from './sample-detail-dialog/sample-detail-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { Sample } from '@shared/models/sample.interface';


@Component({
	selector: 'app-sample',
	templateUrl: './sample.component.html',
	styleUrls: ['./sample.component.scss']
})
export class SampleComponent implements OnInit {

	/** The ID of the current sitting. */
	sittingId: number;

	/** The ID of the current consultation. */
	consultationId: number;

	/** The status of the current consultation. */
	consultationStatus: string = '';

	/** The number of the current sitting. */
	sittingNumber: string = '';

	/** The status of the current sitting. */
	sittingStatus: string= '';

	/** The number of the current client. */
	clientUid: string = '';

	/** The maximum number of samples. */
	maxSamples: number = 3;

	/** The columns to display in the table. */
	displayedColumns: string[] = ['edit', 'sampleNumber', 'submissionDate', 'sampleStatus', 'delete'];

	/** The data source for the table. */
	dataSource = new MatTableDataSource();

	/** Boolean flag indicating whether a loading indicator should be displayed. */
	isLoading: boolean = false;

	constructor(
		private apiService: ApiService,
		private dialog: MatDialog,
		private store: Store,
		private snackBar: MatSnackBar
	) { }


	ngOnInit(): void {
		this.sittingId = this.store.selectSnapshot<number>((state: any) => state.breadcrumb.sitting.id);
		this.consultationId = this.store.selectSnapshot<number>((state: any) => state.breadcrumb.consultation.id);
		this.consultationStatus = this.store.selectSnapshot<string>((state: any) => state.breadcrumb.consultation.consultationStatus);
		this.sittingNumber = this.store.selectSnapshot<string>((state: any) => state.breadcrumb.sitting.sittingNumber);
		this.sittingStatus = this.store.selectSnapshot<string>((state: any) => state.breadcrumb.sitting.sittingStatus);
		this.clientUid = this.store.selectSnapshot<string>((state: any) => state.breadcrumb.consultation.client.clientUid);
		this.getSamplesByConsultationId();
	}

	/**
	 * Gets a list of samples and populates the table with the data.
	 *
	 * @since 1.0.0
	 * @returns void
	 */
	getSamplesByConsultationId(): void {
		this.isLoading = true;
		this.apiService.getSamplesByConsultationId(this.consultationId).subscribe({
			next: (data: any) => {
				this.dataSource = new MatTableDataSource(data)
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}

	deleteSample(element: Sample): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '30vw',
            autoFocus: true,
			data: {
				title: `Probe ${element.sampleNumber} unwiderruflich löschen?`,
				text: `Sind Sie absolut sicher, dass Sie diese Probe löschen wollen? Wenn ja, tippen Sie <code>${element.sampleNumber}</code> in das Feld ein und fahren Sie fort. Andernfalls klicken Sie auf ABBRECHEN.`,
				confirmation: `${element.sampleNumber}`
			}
        });
		dialogRef.afterClosed().subscribe(res => {
			if ( res.data === 'confirmed' ) {
				this.apiService.deleteSample(element.id).subscribe({
					next: () => {
						this.snackBar.open('Probe erfolgreich gelöscht.', 'Schließen', { panelClass: 'success-snack', duration: 2000 })
						this.getSamplesByConsultationId();
					}
				})

			}	
		});
	}

	/**
	 * Opens a dialog to display the details of a sample.
	 *
	 * @since 1.0.0
	 * @param sampleId The ID of the sample to display. If null, the dialog will open for a new sample.
	 * @returns void
	 */
	openSampleDetail(sampleId: number | null = null): void {
		let dialogRef = this.dialog.open(SampleDetailDialogComponent, {
			data: { 
				id: sampleId,
				clientUid: this.clientUid,
				consultationId: this.consultationId 
			},
			autoFocus: false,
			disableClose: true,
			maxWidth: '50vw',
			// minHeight: '95vh'
		});

		dialogRef.afterClosed().subscribe(res => {
			// Update the textblock table data
			if (res && res.data === 'success') this.getSamplesByConsultationId();
		});
	}
}