import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { environment } from '@environments/environment';
import { User } from '@shared/models/user.interface';
import { Sitting } from '@shared/models/sitting.interface';
import { Sample } from '@shared/models/sample.interface';
import { Consultation } from '@shared/models/consultation.interface';
import { ImageService } from './image.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(
		private http: HttpClient,
		private imageService: ImageService
	) { }

	/**
	 *  system-controller
	 */
	getVersion(): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/version`)
	}

	/**
	 * user-controller
	 */
	getUsers(): Observable<User> {
		return this.http.get<User>(`${environment.apiUrl}/v2/users`)
	}

	getUser(id: number): Observable<User> {
		return this.http.get<User>(`${environment.apiUrl}/v2/users/${id}`)
	}

	createUser(formData: User): Observable<User> {
		return this.http.post<User>(`${environment.apiUrl}/v2/users`, formData)
	}

	updateUser(id: number, formData: User): Observable<User> {
		return this.http.put<User>(`${environment.apiUrl}/v2/users/${id}`, formData)
	}

	deleteUser(id: number): Observable<User> {
		return this.http.delete<User>(`${environment.apiUrl}/v2/users/${id}`)
	}

	getMe(): Observable<User> {
		return this.http.get<User>(`${environment.apiUrl}/v2/users/me`)
	}

	changePassword(newPassword: string, oldPassword: string): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/v2/users/me/password`, {
			newPassword: newPassword,
			oldPassword: oldPassword
		})
	}

	/**
	 * sitting-controller
	 */
	getSittings(options: any = null): Observable<Sitting> {

		let params = new HttpParams();

		if (options) {
			if (options.size) params = params.set('size', options.size)
			if (options.page || options.page === 0) params = params.set('page', options.page)
			if (options.from) params = params.set('from', options.from)
			if (options.until) params = params.set('until', options.until)
		}

		return this.http.get<Sitting>(`${environment.apiUrl}/v2/sittings`, { params: params })
	}

	createSitting(): Observable<Sitting> {
		return this.http.post<Sitting>(`${environment.apiUrl}/v2/sittings`, {})
	}

	updateSitting(id: any, sittingStatus: string): Observable<Sitting> {
		return this.http.put<Sitting>(`${environment.apiUrl}/v2/sittings/${id}`, {
			sittingStatus: sittingStatus
		})
	}


	/**
	 * consultation-controller
	 */
	getConsultations(sittingId: number): Observable<Consultation> {
		return this.http.get<Consultation>(`${environment.apiUrl}/v2/consultations`, {
			params: {
				sittingId: sittingId
			}
		})
	}

	getConsultation(consultationId: number): Observable<Consultation> {
		return this.http.get<Consultation>(`${environment.apiUrl}/v2/consultations/${consultationId}`)
	}

	openConsultation(sittingId: number, clientId: number): Observable<Consultation> {
		return this.http.post<Consultation>(`${environment.apiUrl}/v2/consultations`, {
			client: { id: clientId },
			sittingId: sittingId,
			consultationStatus: 'OPEN',
		})
	}

	closeConsultation(consultation: Consultation): Observable<Consultation> {
		return this.http.put<Consultation>(`${environment.apiUrl}/v2/consultations/${consultation.id}`, {
			consultationStatus: 'CLOSED',
			client: { id: consultation.client.id },
			id: consultation.id,
			sittingId: consultation.sittingId
		})
	}

	/**
	 * client-controller
	 */
	getClients(clientUid: string = '', size: number = 10000, page: number = 0): Observable<any> {

		let params = new HttpParams();

		params = params.set('size', size)
		params = params.set('page', page)
		if (clientUid) params = params.set('clientUid', clientUid)

		return this.http.get<any>(`${environment.apiUrl}/v2/clients`, { params: params })
	}

	getClient(clientId: number): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/clients/${clientId}`)
	}

	createClient(formData: any): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/v2/clients`, formData)
	}

	updateClient(clientId: number, formData: any): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/v2/clients/${clientId}`, formData)
	}

	deleteClient(clientId: number): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/v2/clients/${clientId}`)
	}

	exportClients(from: string, until: string): Observable<HttpResponse<ArrayBuffer>> {

		let params = new HttpParams();

		params = params.set('from', from)
		params = params.set('until', until)

		return this.http.get(`${environment.apiUrl}/v2/clients/export/excel`, {
			headers: {
				'Content-Type': 'application/octet-stream'
			},
			responseType: 'arraybuffer',
			observe: 'response',
			params: params
		})
	}


	/**
	 * sample-controller
	 */

	getSamples(params: any): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/samples`, {
			params: params
		})
	}

	getSamplesByConsultationId(consultationId: number): Observable<Sample> {
		return this.http.get<Sample>(`${environment.apiUrl}/v2/samples/consultationId:${consultationId}`)
	}

	getSamplesByClientId(clientId: number): Observable<Sample[]> {
		return this.http.get<Sample[]>(`${environment.apiUrl}/v2/samples/clientId:${clientId}`)
	}

	getSamplesBySampleNumber(sampleNumber: string): Observable<Sample> {
		return this.http.get<Sample>(`${environment.apiUrl}/v2/samples/sampleNumber:${sampleNumber}`)
	}

	getSample(id: number): Observable<Sample> {
		return this.http.get<Sample>(`${environment.apiUrl}/v2/samples/${id}`)
	}

	addSample(formData: any): Observable<Sample> {
		return this.http.post<Sample>(`${environment.apiUrl}/v2/samples`, formData)
	}

	updateSample(id: number, formData: any): Observable<Sample> {
		return this.http.put<Sample>(`${environment.apiUrl}/v2/samples/${id}`, formData)
	}

	deleteSample(id: number): Observable<Sample> {
		return this.http.delete<Sample>(`${environment.apiUrl}/v2/samples/${id}`)
	}

	updateSampleStatus(id: number, status: string): Observable<Sample> {
		return this.http.put<Sample>(`${environment.apiUrl}/v2/samples/${id}/status/${status}`, {})
	}

	downloadSampleNumbersPdf(): Observable<HttpResponse<ArrayBuffer>> {
		return this.http.post(`${environment.apiUrl}/v2/samples/sampleNumbers/pdf`, {}, {
			headers: {
				'Content-Type': 'application/json'
			},
			responseType: 'arraybuffer',
			observe: 'response'
		})
	}

	downloadInfoSheetPdf(sittingId: number): Observable<HttpResponse<ArrayBuffer>> {
		return this.http.get(`${environment.apiUrl}/v2/sittings/${sittingId}/pdf`, {
			headers: {
				'Content-Type': 'application/json'
			},
			responseType: 'arraybuffer',
			observe: 'response'
		})
	}

	exportSamples(from?: string, until?: string, sampleStatus?: string): Observable<HttpResponse<ArrayBuffer>> {

		let params = new HttpParams();

		if (from) params = params.set('from', from)
		if (until) params = params.set('until', until)
		if (sampleStatus) params = params.set('sampleStatus', sampleStatus)

		return this.http.get(`${environment.apiUrl}/v2/samples/export/excel`, {
			headers: {
				'Content-Type': 'application/octet-stream'
			},
			responseType: 'arraybuffer',
			observe: 'response',
			params: params
		})
	}


	/**
	 * sample-image-controller
	 */
	getSampleImages(sampleId: Number): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/samples/${sampleId}/images`)
	}

	downloadSampleImages(sampleId: number): Observable<any[]> {
		return this.getSampleImages(sampleId).pipe(
			switchMap((response: any[]) => {
				if (response.length === 0) {
					return of([]); // No images, return empty array
				}

				const requests = response.map(image => {
					const url = `${environment.apiUrl}/v2/samples/${sampleId}/images/${image.sequential}`;
					return this.imageService.downloadFileAsBase64(url, image.filename);
				});

				return forkJoin(requests).pipe(
					map(results => results.filter(image => image !== null)) // Filter out null images
				);
			})
		);
	}

	deleteSampleImage(sampleId: Number, sequential: number): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/v2/samples/${sampleId}/images/${sequential}`)
	}

	addSampleImages(sampleId: number, imageList: any[]): Observable<any> {
		const uploadRequests: Observable<any>[] = [];

		imageList.forEach((image, index) => {
			const formData = new FormData();
			formData.append('file', image.file);
			const sequential = index + 1;
			uploadRequests.push(
				this.http.put<any>(`${environment.apiUrl}/v2/samples/${sampleId}/images/${sequential}`, formData)
			);
		});

		// Executes all requests and waits for them to complete
		return forkJoin(uploadRequests);
	}

	updateSampleImages(sampleId: number, imageList: any[]): Observable<any> {
		return this.getSampleImages(sampleId).pipe(
			switchMap((existingImages: any[]) => {
				// Determine which images to delete
				const existingSequentialNumbers = existingImages.map(image => image.sequential);
				const imageListSequentialNumbers = imageList.map((_, index) => index + 1);
				const imagesToDelete = existingSequentialNumbers.filter(sequential => !imageListSequentialNumbers.includes(sequential));
	
				// Delete the images that are no longer in the imageList
				const deleteRequests: Observable<any>[] = imagesToDelete.map(sequential => this.deleteSampleImage(sampleId, sequential));
	
				// Upload the new images
				const uploadRequests: Observable<any>[] = imageList.map((image, index) => {
					const formData = new FormData();
					formData.append('file', image.file);
					const sequential = index + 1;
					return this.http.put<any>(`${environment.apiUrl}/v2/samples/${sampleId}/images/${sequential}`, formData);
				});
	
				// If there are no delete or upload requests, return an empty observable
				if (deleteRequests.length === 0 && uploadRequests.length === 0) {
					console.log('No images to delete or upload');
					return of(null);
				}
	
				// Execute all delete and upload requests
				return forkJoin([...deleteRequests, ...uploadRequests]);
			})
		);
	}



	/**
	 * result-controller
	 * TODO: Update this to accommodate the recent API refactor. Results have been splitted in Analyses and Assessments.
	 */

	createResult(sampleId: number, formData: any): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/v2/samples/${sampleId}/results`, formData)
	}

	updateResult(sampleId: number, resultId: number, formData: any): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/v2/samples/${sampleId}/results/${resultId}`, formData)
	}

	deleteResult(sampleId: number, resultId: number): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/v2/samples/${sampleId}/results/${resultId}`)
	}

	getResult(sampleId: number): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/samples/${sampleId}/results`)
	}

	getResults(params: any): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/samples`, {
			params: params
		})
	}


	/**
	 * analysis-controller
	 */

	createAnalysis(sampleId: number, formData: any): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/v2/samples/${sampleId}/analyses`, formData)
	}

	updateAnalysis(sampleId: number, analysisId: number, formData: any): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/v2/samples/${sampleId}/analyses/${analysisId}`, formData)
	}

	deleteAnalysis(sampleId: number, analysisId: number): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/v2/samples/${sampleId}/analyses/${analysisId}`)
	}

	getAnalysis(sampleId: number): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/samples/${sampleId}/analyses`)
	}

	/**
	 * assesament-controller
	 */

	createAssessment(sampleId: number, formData: any): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/v2/samples/${sampleId}/assessments`, formData)
	}

	updateAssessment(sampleId: number, assessmentId: number, formData: any): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/v2/samples/${sampleId}/assessments/${assessmentId}`, formData)
	}

	deleteAssessment(sampleId: number, assessmentId: number): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/v2/samples/${sampleId}/assessments/${assessmentId}`)
	}

	getAssessment(sampleId: number): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/samples/${sampleId}/assessments`)
	}



	/**
	 * textblock-controller
	 */

	getTextblocks(params: any = null): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/textblocks`, {
			params: params
		})
	}

	getTextblock(id: number): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/v2/textblocks/${id}`)
	}

	createTextblock(formData: any): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/v2/textblocks`, {
			description: formData.description,
			text: formData.text
		})
	}

	updateTextblock(id: number, formData: any): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/v2/textblocks/${id}`, {
			description: formData.description,
			text: formData.text,
			id: formData.id
		})
	}

	deleteTextblock(id: number): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/v2/textblocks/${id}`)
	}
}
