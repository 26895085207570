import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '@core/services/api.service';
import { Select, Store } from '@ngxs/store';
import { Sitting } from '@shared/models/sitting.interface';
import { OpenSitting, CloseSitting, CheckOpenSitting } from '@shared/states/sitting/sitting.action';
import { SittingState, SittingStateModel } from '@shared/states/sitting/sitting.state';
import { Observable, Subscription } from 'rxjs';
import { saveAs } from 'file-saver-es';
import { HttpResponse } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@shared/models/user.interface';

@Component({
	selector: 'app-sitting',
	templateUrl: './sitting.component.html',
	styleUrls: ['./sitting.component.scss']
})
export class SittingComponent implements OnInit, OnDestroy {

	@ViewChild(MatPaginator) paginator: MatPaginator;

	/** Select sitting state from Ngxs store */
	@Select(SittingState) sittingState$: Observable<Sitting>;

	/** Subscription instance to unsubscribe later */
	private subscription: Subscription;

	/** Define table column names */
	displayedColumns: string[] = ['edit', 'sittingStatus', 'sittingNumber', 'timestamp', 'pdf'];

	/** MatTableDataSource instance */
	dataSource = new MatTableDataSource();

	/** Total number of elements in the data source. */
	totalElements: number = 0;

	/** Number of items to display on a page. */
	pageSize: number = 10;

	/** Boolean flags indicating whether a loading indicator should be displayed. */
	isLoading: boolean = false;
	isLoadingSampleSheet: boolean = false;
	isLoadingInfoSheet: any = {};

	/** Sitting state */
	sittingState: SittingStateModel | null = null

	/** Form Group for from until query */
	daterangeForm = new FormGroup({
		from: new FormControl<Date | null>(null),
		until: new FormControl<Date | null>(null),
	});

	from: string | null = null
	until: string | null = null
	today: Date | null = new Date()

	currentUser: User

	constructor(
		private apiService: ApiService,
		private store: Store,
		private snackBar: MatSnackBar,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		/** Get user object from the Ngxs store snapshot */
		this.currentUser = this.store.selectSnapshot<User>((state: any) => state.auth.user);

		/** Add consultant and organization row if user is ADMIN */
		if ( this.currentUser.role === 'ADMIN' ) this.displayedColumns = ['edit', 'sittingStatus', 'sittingNumber', 'timestamp', 'consultant', 'organization', 'pdf'];

		/** Subscribe to sittingState$ observable and update sittingState when changes occur */
		this.subscription = this.sittingState$.subscribe((state: any) => this.sittingState = state);

		/** Call getSittings() method to fetch sittings archive */
		this.getSittings({ size: this.pageSize, page: 0 });

		/** Subscribe to daterangeForm and perform API request on change */
		this.subscription = this.daterangeForm.valueChanges.subscribe((data: any) => {
			// Convert selected 'from' and 'until' dates to desired format
			this.from = data.from ? data.from.format('YYYY-MM-DD') : null;
			this.until = data.until ? data.until.format('YYYY-MM-DD') : null;
			// If a date range was selected, go to page 0 and fetch data with selected date range
			if (data.from && data.until) {
				this.getSittings({
					size: this.pageSize,
					page: 0,
					from: this.from,
					until: this.until
				});
			}
			// Else reset sittings list and fetch data with no date range
			else {
				this.getSittings({ size: this.pageSize, page: 0 });
			}
			// Go to page 0 in the paginator
			this.paginator.firstPage();
		});

	}

	ngOnDestroy(): void {
		/** Unsubscribe from sittingState$ observable to avoid memory leaks */
		this.subscription.unsubscribe();
	}

	/**
	 * Start a new sitting
	 * @since 1.0.0
	 * @return void
	 */
	startSitting(): void {
		/** Dispatch OpenSitting action to Ngxs store and call getSittings() method after the action is completed */
		this.store.dispatch(new OpenSitting).subscribe(() => this.getSittings());
	}

	/**
	 * Stop a current sitting
	 * @since 1.0.0
	 * @return void
	 */
	stopSitting(): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '33vw',
            autoFocus: true,
			data: {
				title: `Offene Sprechstunde ${this.sittingState?.sittingNumber} wirklich beenden?`,
				text: `Sind Sie absolut sicher, dass Sie die offene Sprechstunde beenden wollen?`,
				// confirmation: `${element.sampleNumber}`,
				buttonText: 'BEENDEN'
			}
        });
		dialogRef.afterClosed().subscribe(res => {
			if ( res.data === 'confirmed' ) {
				/** Dispatch CloseSitting action to Ngxs store and call getSittings() method after the action is completed */
				this.store.dispatch(new CloseSitting).subscribe(() => this.getSittings());
			}	
		});
	}

	/**
	 * Fetch sittings data
	 * @since 1.0.0
	 * @param size - The number of items per page
	 * @param page - The current page index
	 * @return void
	 */
	getSittings(options: any = null): void {
		this.isLoading = true;
		/** Fetch sittings data and update dataSource with the data content */
		this.apiService.getSittings(options).subscribe({
			next: (data: any) => {
				this.totalElements = data.totalElements
				this.dataSource = new MatTableDataSource(data.content);
				// Check for open sitting
				this.store.dispatch(new CheckOpenSitting(data.content));
				this.isLoading = false;
			},
			error: () => {
				this.isLoading = false;
			}
		})
	}

	/**
	 * Event handler for when the page in the paginator changes.
	 * @since 1.0.0
	 * @param {any} event - The page change event.
	 * @return void
	 */
	pageChanged(event: any): void {
		this.pageSize = event.pageSize;
		this.getSittings({
			size: event.pageSize,
			page: event.pageIndex,
			from: this.from,
			until: this.until
		});
	}

	/**
	 * Clears the selected date range in the daterangeForm and prevents 
	 * the toggle window from opening.
	 * @since 1.0.0
	 * @param event - The MouseEvent object representing the click event.
	 * @returns void
	 */
	clearDateRange(event: MouseEvent): void {
		event.stopPropagation();
		this.daterangeForm.patchValue({ from: null, until: null });
	}

	/**
	 * Downloads a sample numbers PDF file called "Stickerbogen".
	 * @since 1.0.0
	 * @return void
	 */
	downloadSampleNumbersPdf(): void {

		this.isLoadingSampleSheet = true;

		this.apiService.downloadSampleNumbersPdf().subscribe({
			next: (response: HttpResponse<ArrayBuffer>) => {
				const contentType = 'application/pdf';
				const contentDisposition = response.headers.get('Content-Disposition');

				if (contentDisposition) {

					const fileName = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');
					const blob = new Blob([response.body!], { type: contentType });

					saveAs(blob, fileName);

					this.snackBar.open(`${fileName} wurde erfolgreich exportiert.`, '', { panelClass: 'success-snack', duration: 5000 });
				}

				this.isLoadingSampleSheet = false;
			},
			error: () => {
				this.isLoadingSampleSheet = false;
			}
		});
	}

	/**
	 * Downloads an info sheet PDF file called "Probenbegleitschein" for a specific sitting.
	 * @since 1.0.0
	 * @param {number} sittingId - The ID of the sitting for which to download the info sheet PDF.
	 * @return void
	 */
	downloadInfoSheetPdf(sittingId: number): void {

		this.isLoadingInfoSheet[sittingId] = true;

		this.apiService.downloadInfoSheetPdf(sittingId).subscribe({
			next: (response: HttpResponse<ArrayBuffer>) => {
				const contentType = 'application/pdf';
				const contentDisposition = response.headers.get('Content-Disposition');

				if (contentDisposition) {

					const fileName = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');
					const blob = new Blob([response.body!], { type: contentType });

					saveAs(blob, fileName);

					this.snackBar.open(`${fileName} wurde erfolgreich exportiert.`, '', { panelClass: 'success-snack', duration: 5000 });
				}

				this.isLoadingInfoSheet[sittingId] = false;
			},
			error: () => {
				this.isLoadingInfoSheet[sittingId] = false;
			}
		});
	}

}