<mat-tab-group [selectedIndex]="0" #tabGroup animationDuration="0ms" class="bg-[#F1F1F1] client-tab">
    
    <mat-tab label="Bestehende*n Klient*in suchen">
        <ng-template matTabContent>
            <app-client-search-tab [tabGroup]="tabGroup" (createConsultation)="createConsultation($event)"></app-client-search-tab>
        </ng-template>
    </mat-tab>

    <mat-tab label="Neue*n Klient*in anlegen">
        <ng-template matTabContent>
            <app-client-add-edit *ngIf="tabGroup.selectedIndex === 1" (formSubmit)="onFormSubmit($event)"></app-client-add-edit>
        </ng-template>
    </mat-tab>

</mat-tab-group>


<mat-dialog-actions align="start" class="bg-[#FFFFFF]">
    <button type="button" mat-button mat-dialog-close>Abbrechen</button>
    <span class="flex-1"></span>
    <button *ngIf="currentTabIndex === 1" type="submit" mat-flat-button color="primary" [disabled]="isLoading" (click)="clientAddEdit.onSubmit()">Speichern</button>
</mat-dialog-actions>