import { Sitting } from "@shared/models/sitting.interface";

export class OpenSitting {
    static readonly type = '[Sitting] Open';
}

export class CloseSitting {
    static readonly type = '[Sitting] Close';
}

export class FetchOpenSitting {
    static readonly type = '[Sitting] Fetch open sitting';
}

export class CheckOpenSitting {
    static readonly type = '[Sitting] Check open sitting';
    constructor(public data: Sitting[]){}
}