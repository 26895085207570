import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sampleStatus'
})
export class SampleStatusPipe implements PipeTransform {

	private sampleStatusMap = new Map<string, string>([
		['PENDING', 'Wartend'],
		['ANALYZED', 'Analysiert'],
		['REPORTED', 'Zurückgemeldet'],
		['ASSESSED', 'Bewertet'],
		['INVALID', 'Invalid']
	]);

	transform(value: string): string {
		const translation = this.sampleStatusMap.get(value);
    	return translation ? translation : value;
	}

}
