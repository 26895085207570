import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select, Store } from '@ngxs/store';
import { AppInfoDialogComponent } from '@shared/components/app-info-dialog/app-info-dialog.component';
import { ExportDialogComponent } from '@shared/components/export-dialog/export-dialog.component';
import { MeDialogComponent } from '@shared/components/me-dialog/me-dialog.component';
import { Sitting } from '@shared/models/sitting.interface';
import { User } from '@shared/models/user.interface';
import { SittingState } from '@shared/states/sitting/sitting.state';
import { Logout } from 'app/auth/states/auth.action';
import { Observable, Subscription } from 'rxjs';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

	/** Observable for sitting state from NGXS store. */
	@Select(SittingState) sittingState$: Observable<Sitting>;

	/** Subscription for sitting state updates. */
	private subscribtion: Subscription;

	/** The user object of the currently logged-in user. */
	currentUser: User

	/** A boolean flag indicating whether there is an open sitting or not. */
	hasOpenSitting: boolean = false

	/** Redirect user based on his role */
	routeToRedirect: string = ''

	constructor(
		private store: Store,
		private snackBar: MatSnackBar,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		// Subscribe to sitting state for notification
		this.subscribtion = this.sittingState$.subscribe((state: any) => {
			this.hasOpenSitting = state?.sittingStatus === 'OPEN' ? true : false
		})

		// Get user object from the Ngxs store snapshot
		this.currentUser = this.store.selectSnapshot<User>((state: any) => state.auth.user);

		this.routeToRedirect = ( this.currentUser.role === 'PHARMACIST' ) ? '/laboratory' : '/sitting';
	}

	ngOnDestroy(): void {
		this.subscribtion.unsubscribe();
	}
	
	/**
	 * Logs out the user by dispatching a Logout action, showing a snack bar notification,
	 * and navigating to the home page.
	 * @since 1.0.0
	 * @return {void}
	 */
	logout(): void {
		this.store.dispatch(new Logout).subscribe(() => {
			this.snackBar.open('Bis zum nächsten mal!', '👋', { panelClass: 'success-snack', duration: 3000})
		})
	}

	/**
	 * Opens the "Me" user dialog.
	 * @since 1.0.0
	 * @returns void
	 */
	openMeDialog(): void {
		this.dialog.open(MeDialogComponent, { 
			width: '30vw',
            autoFocus: false
        });
	}

	
	/**
	 * Opens the export dialog.
	 * @since 1.0.0
	 * @returns void
	 */
	openExportDialog(): void {
		this.dialog.open(ExportDialogComponent, { 
			width: '30vw',
            autoFocus: false
        });
	}

	/**
	 * Opens the app info dialog.
	 * @since 1.0.0
	 * @returns void
	 */
	openAppInfoDialog(): void {
		this.dialog.open(AppInfoDialogComponent, { 
			width: '30vw',
			autoFocus: false
		});
	}
}