import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientXsrfModule } from '@angular/common/http';

import { environment } from '@environments/environment';

import { AuthModule } from './auth/auth.module';
import { AdminModule } from './admin/admin.module';

import { AppComponent } from './app.component';

import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { AuthState } from './auth/states/auth.state';
import { SittingState } from '@shared/states/sitting/sitting.state';
import { BreadcrumbState } from '@shared/states/breadcrumb/breadcrumb.state';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientXsrfModule.withOptions({
			cookieName: 'XSRF-TOKEN',
			headerName: 'X-XSRF-TOKEN',
		}),
		NgxsModule.forRoot([
			AuthState,
			SittingState,
			BreadcrumbState,
			// SettingsState,
		], { developmentMode: !environment.production }),
		NgxsStoragePluginModule.forRoot({
			storage: StorageOption.SessionStorage
		}),
		NgxsReduxDevtoolsPluginModule.forRoot(),
		NgxsResetPluginModule.forRoot(),
		// CoreModule,
		AuthModule,
		AdminModule
	],
	providers: [
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'} }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
