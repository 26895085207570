import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '@core/services/api.service';

@Component({
	selector: 'app-client-uid-dialog',
	templateUrl: './client-uid-dialog.component.html',
	styleUrls: ['./client-uid-dialog.component.scss']
})
export class ClientUidDialogComponent implements OnInit {

	/** The client UID */
	clientUid: string = ''

	/** The displayed columns in the table. */
	displayedColumns: string[] = ['clientUid', 'gender', 'postalCode', 'nationality', 'firstConsultationDate', 'lastConsultationDate', 'select'];

	/** The data source for the table. */
	dataSource = new MatTableDataSource();

	constructor(
		private apiService: ApiService,
		private dialogRef: MatDialogRef<ClientUidDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
	
	ngOnInit(): void {
		if ( this.data.length > 0 ) this.dataSource = new MatTableDataSource(this.data);
	}

	/**
     * Handles the change event of the client UID input.
     *
     * @since 1.0.0
     * @param {any} event - The change event object.
     * @returns {void}
     */
	onClientUidChange(event: any): void {
		console.log(event)
		this.clientUid = event;
		if ( this.clientUid.length === 6 ) {
			this.apiService.getClients(this.clientUid).subscribe((data: any) => {
				this.dataSource = new MatTableDataSource(data.content)
			})
		}
	}

	/**
     * Handles the selection of a client from the table.
     *
     * @msince 1.0.0
     * @param {any} client - The selected client object.
     * @returns {void}
     */
	selectClient(client: any): void {
		this.dialogRef.close({data: client})
	}

}
