<div appFileDragAndDrop (filesChangeEmiter)="onFileDropped($event)"
  class="flex-col w-auto h-[200px] border border-dashed rounded-lg p-4 bg-[#f5f5f5] flex items-center justify-center text-center cursor-pointer"
  (click)="fileInput.click()">
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-cloud-upload"
    viewBox="0 0 16 16">
    <path fill-rule="evenodd"
      d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
    <path fill-rule="evenodd"
      d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708z" />
  </svg>
  <p>Bild hier ablegen oder klicken.</p>
  <input type="file" hidden (change)="onFilesSelected($event)" #fileInput accept="image/*">
  <!-- Loading Spinner -->
  <mat-spinner *ngIf="isLoading" color="primary" diameter="25"></mat-spinner>
</div>

<!-- Image List with Drag and Drop -->
<ul *ngIf="imageList.length > 0" cdkDropList (cdkDropListDropped)="drop($event)" class="list-none m-0 mt-5 p-0">
  <li *ngFor="let image of imageList; let i = index" cdkDrag class="list-none w-full flex justify-start items-center my-1">
    <mat-icon class="cursor-grab active:cursor-grabbing w-[40px] flex-shrink-0" cdkDragHandle>drag_indicator</mat-icon>
    <img [src]="image.imageUrl" [alt]="image.fileName" class=" hover:cursor-zoom-in w-[70px] h-[70px] object-cover border border-solid border-gray-500 rounded-md mx-2 flex-shrink-0" (click)="openLightboxDialog(image)">
    <span class="flex-grow flex flex-col truncate">
      <span class="truncate" matTooltip="{{image.fileName}}">{{ image.fileName }}</span>
      <code class="text-xs">({{ (image.fileSize / 1024).toFixed(2) }} KB)</code>
    </span>
    <button mat-icon-button color="warn" (click)="removeImage(i)" class="ml-auto">
      <mat-icon>close</mat-icon>
    </button>
  </li>
</ul>
