<app-toolbar></app-toolbar>

<div class="flex flex-col h-auto p-5">

	<div class="flex flex-row gap-4">

		<div class="w-1/4">

			<mat-card>
				<mat-card-header class="flex flex-col">
					<mat-card-title class="!inline-flex !items-center">
                        <span class="material-symbols-outlined mr-2">labs</span>Neues Laborergebnis
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="!inline-flex flex-col mt-4">
                    <button mat-flat-button color="primary" (click)="openLabDialog()"><mat-icon>add</mat-icon> Erfassen</button>
				</mat-card-content>
			</mat-card>

		</div>

		<div class="w-full">

			<mat-card>
				<mat-card-header class="mb-5 flex-col">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">lab_panel</span> Alle Laborergebnisse
					</mat-card-title>
                    <mat-button-toggle-group class="place-self-start mt-2" [(ngModel)]="currentSampleStatus" (change)="onSampleStatusChange($event)">
                        <mat-button-toggle value="ANALYZED"> Analysiert </mat-button-toggle>
                        <mat-button-toggle value="ASSESSED"> Bewertet </mat-button-toggle>
                        <mat-button-toggle value="REPORTED"> Zurückgemeldet </mat-button-toggle>
                    </mat-button-toggle-group>
				</mat-card-header>

				<mat-card-content class="!flex !flex-col">

					<table mat-table  [dataSource]="dataSource" class="mat-elevation-z0">

                        <!-- Edit Column -->
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef> Bearbeiten </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="primary" (click)="openLabDialog(element)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                    
                        <!-- Text Column -->
                        <ng-container matColumnDef="sampleNumber">
                            <th mat-header-cell *matHeaderCellDef> Probencode </th>
                            <td mat-cell *matCellDef="let element"> {{element.sampleNumber}} </td>
                        </ng-container>

                        <!-- Creator Column -->
                        <ng-container matColumnDef="timestamp">
                            <th mat-header-cell *matHeaderCellDef> Datum </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.analysis.timestamp | date: 'dd.MM.yyyy'}}, um {{element.analysis.timestamp | date: 'HH:mm:ss'}} Uhr 
                            </td>
                        </ng-container>

                        <!-- Delete Column 
                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef> Löschen </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="warn" (click)="deleteResult(element)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        -->
                        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>
                    
                        <!-- Row shown when there is no matching data.  -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell p-8 text-center" colspan="4">
                                <p>Keine Laborergebnisse gefunden</p>
                                <button type="button" mat-raised-button class="uppercase" (click)="openLabDialog()">
									<mat-icon>add</mat-icon> Ergebniss erfassen
								</button>
                            </td>
                        </tr>
                    </table>

                    <mat-paginator class="mat-elevation-z0 mt-4" 
                        [length]="totalElements"
                        [pageSize]="pageSize" 
                        [pageSizeOptions]="[5, 10, 25, 100]"
                        (page)="pageChanged($event)" 
                        showFirstLastButtons>
                    </mat-paginator>

				</mat-card-content>
			</mat-card>

			<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

		</div>

	</div>

</div>