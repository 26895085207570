import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@core/services/api.service';
import { catchError, forkJoin, Observable, of } from 'rxjs';
import { Analysis, Assessment, Sample } from '@shared/models/sample.interface';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { NumberFormatPipe } from '@core/pipes/number-format.pipe';
import { LabTinymceConfig } from './lab-tinymce.config';
import { maxLengthValidator } from './max-length.validator';
import { FilterService } from '@shared/services/filter.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FormChangeDetectorService } from '@shared/services/form-change-detector.service';

@Component({
	selector: 'app-lab-dialog',
	templateUrl: './lab-dialog.component.html',
	styleUrls: ['./lab-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LabDialogComponent implements OnInit {

	@ViewChild('textArea') textAreaRef!: ElementRef;

	@ViewChild(MatSelect) select: MatSelect;

	/** The forms used to display and submit laboratory results. */
	sampleForm: FormGroup;
	analysisForm: FormGroup;
	assessmentForm: FormGroup;

	isPharmacist: boolean = true;

	/** An array of text blocks */
	textblocks: any[] = []

	isValidSampleNumber: boolean = false

	/** The number of characters in the text field. */
	textCount: number = 0;

	sampleNumber: FormControl = new FormControl('');

	/** Additional sample informations */
	currentSample: Sample

	imageList: any = [];

	/** Array of options for the unit. */
	optionsUnit: string[] = [
		'mg',
		'µg',
		'%',
		'‰',
		'positiv',
		'negativ'
	];

	/** Observable of filtered options of the unit */
	filteredOptionsUnit: Observable<string[]>[] = [];

	/** Array of options for the substance names. */
	optionsSubstanceName: string[] = [
		'1cP-LSD-Tartrat',
		'1P-LSD-Tartrat',
		'1T-LSD-Tartrat',
		'1V-LSD-Tartrat',
		'2C-B-Fly-Hydrochlorid',
		'2C-B-Hydrochlorid',
		'2-FDCK-Hydrochlorid',
		'2-FMA-Hydrochlorid',
		'3-CMC-Hydrochlorid',
		'3-MMC-Hydrochlorid',
		'4-CMC-Hydrochlorid',
		'4-Methylaminophenazon',
		'6-MAM',
		'9-OH-HHC',
		'Acetylcodein',
		'AL-LAD-Tartrat',
		'Amphetamin-Sulfat',
		'apha-PhiP-Hydrochlorid',
		'Benzoesäure',
		'Benzoylecgonin-Hydrochlorid',
		'delta-4(8)-iso-THC',
		'delta-8-THC',
		'Diamorphin',
		'DMT-Hydrochlorid',
		'GBL',
		'iso-3-CMC-Hydrochlorid',
		'iso-LSD-Tartrat',
		'Ketamin-Hydrochlorid',
		'Koffein',
		'Kokain-Hydrochlorid',
		'Lidocain-Hydrochlorid',
		'LSD-Tartrat',
		'M-alpha-HMCA',
		'MDMA-Hydrochlorid',
		'MDMB-4en-PINACA',
		'Mephedron-Hydrochlorid',
		'Methamphetamin-Hydrochlorid',
		'Methylecgonin-Hydrochlorid',
		'N-Ethylpentedron-Hydrochlorid',
		'N-Methylpentylon-Hydrochlorid',
		'NMT-Hydrochlorid',
		'Norkokain-Hydrochlorid',
		'Noscapin',
		'Paracetamol',
		'Phenethylamin-Hydrochlorid',
		'Procain-Hydrochlorid',
		'synthetische Cannabinoide',
		'Tetramisol-Hydrochlorid',
		'unbekannte Substanz',
		'unbekannte Substanz 1',
		'unbekannte Substanz 2',
		'unlöslicher Bestandteil'
	];

	/** Observable of filtered options of the substance names */
	filteredOptionsSubstanceName: Observable<string[]>[] = [];

	tinymceConfig: any = LabTinymceConfig.settings
	tinymceConfigPublic: any = { ...LabTinymceConfig.settings}

	textBlocksLoaded: boolean = false

	textLength: number = 0;

	hasChanges: boolean = false;

	constructor(
		private fb: FormBuilder,
		private apiService: ApiService,
		private snackBar: MatSnackBar,
		private numberPipe: NumberFormatPipe,
		private dialogRef: MatDialogRef<LabDialogComponent>,
		private dialog: MatDialog,
		private filterService: FilterService,
		private cdr: ChangeDetectorRef,
		private formChangeDetectorService: FormChangeDetectorService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit(): void {

		this.getTextblocks();

		this.tinymceConfig.height = 815;
		this.tinymceConfigPublic.height = 488;

		/*this.tinymceConfig.setup = (editor: any) => {
			const updateTextLength = () => { this.updateTextLength(editor); };
			editor.on('init change input', updateTextLength);
		};*/

		this.sampleForm = this.fb.group({
			id: new FormControl(''),
			sampleId: new FormControl(''),
		});

		this.analysisForm = this.fb.group({
			id: new FormControl(null),
			depth: new FormControl(null, Validators.pattern(/^\d+(,\d{1,2})?$/)),
			height: new FormControl(null, Validators.pattern(/^\d+(,\d{1,2})?$/)),
			weight: new FormControl(null, Validators.pattern(/^\d+(,\d{1,2})?$/)),
			width: new FormControl(null, Validators.pattern(/^\d+(,\d{1,2})?$/)),
			substances: new FormArray([], Validators.required)
			// notes: new FormControl('', [Validators.required, maxLengthValidator(10000, () => this.textLength)]),
		});

		this.assessmentForm = this.fb.group({
			id: new FormControl(null),
			publicEvaluation: new FormControl(''),
			publicHeadline: new FormControl(''),
			published: new FormControl(false),
			contaminated: new FormControl(false),
			misdeclared: new FormControl(false),
			highlyDosed: new FormControl(false),
			description: new FormControl('', [Validators.required, maxLengthValidator(10000, () => this.textLength)]),
			alertType: new FormControl(null),
			isAlert: new FormControl(null),
			isRedAlert: new FormControl(null),
			textblocks: new FormControl('')
		});

		// Track changes for each form
		this.formChangeDetectorService.trackFormChanges('analysisForm', this.analysisForm);
		this.formChangeDetectorService.trackFormChanges('assessmentForm', this.assessmentForm);
		this.formChangeDetectorService.trackFormChanges('sampleForm', this.sampleForm);
	
		// Subscribe to the form change observable
		this.formChangeDetectorService.formChange$.subscribe((modifiedForms) => {
			this.hasChanges = modifiedForms.size !== 0;
		});

		this.currentSample = this.data.sample;

		if ( this.currentSample ) { 
			this.populateAllForms();
		}
		
	}

	private populateAllForms() {
		const subtancesArray = this.analysisForm.get('substances') as FormArray
		subtancesArray.clear();
		this.analysisForm.reset();
		this.assessmentForm.reset();
		this.patchSampleData();
		this.sampleIsValid(true);
		this.transformAnalysisNumbers();
		this.patchAlertType();
		this.patchSubstanceFormArray();
		this.getSampleImages();
		this.formChangeDetectorService.reset();
		this.hasChanges = false;
	}

	private patchSampleData(): void {
		this.sampleForm.patchValue(this.currentSample);
		this.sampleForm.get('sampleId')?.patchValue({value: this.currentSample.id, onlySelf: true});
		this.sampleNumber.patchValue(this.currentSample.sampleNumber);
		this.analysisForm.patchValue(this.currentSample.analysis);
		this.assessmentForm.patchValue(this.currentSample.assessment);
	}
	
	private transformAnalysisNumbers(): void {
		this.patchAnalysisValue('weight');
		this.patchAnalysisValue('width');
		this.patchAnalysisValue('height');
		this.patchAnalysisValue('depth');
	}
	
	private patchAnalysisValue(controlName: string): void {
		const control = this.analysisForm.get(controlName);
		if (control) {
			const transformedValue = this.numberPipe.transform(control.value);
			control.patchValue(transformedValue);
		}
	}
	
	private patchAlertType(): void {
		const isAlert = this.assessmentForm.get('isAlert')?.value;
		const isRedAlert = this.assessmentForm.get('isRedAlert')?.value;
	
		let alertType = null;
		if (isAlert === true && isRedAlert === true) {
			alertType = 'alarm';
		} else if (isAlert === true && isRedAlert === false) {
			alertType = 'warning';
		} else if (isAlert === false && isRedAlert === false) {
			alertType = 'none';
		}
	
		this.assessmentForm.get('alertType')?.patchValue(alertType);
	}
	
	private patchSubstanceFormArray(): void {
		const substances = this.currentSample.analysis.substances;
		for (let i = 0; i < substances.length; i++) {
			this.addSubstance();
			this.substances.at(i).patchValue(substances[i] || {});
			const transformedAmount = this.numberPipe.transform(substances[i].amount);
			this.substances.at(i).get('amount')?.patchValue(transformedAmount);
		}
	}

	/**
	 * Add a new FormGroup for a substance to the substances FormArray
	 */
	addSubstance() {
		const substance: FormGroup = new FormGroup({
			id: new FormControl(null),
			name: new FormControl('', Validators.required),
			amount: new FormControl(null, [Validators.required, Validators.pattern(/^[-+]?(\d+|\d+,\d+|\d+,\d+[eE][-+]?\d+|\d+[eE][-+]?\d+)$/)]),
			unit: new FormControl('', Validators.required),
		});
		this.substances.push(substance);
		
		
		const nameCtrl = substance.get('name') as FormControl;
		const unitCtrl = substance.get('unit') as FormControl;
			
		const index = this.substances.length - 1;
		this.filteredOptionsSubstanceName[index] = this.filterService.filterOptions(this.optionsSubstanceName, nameCtrl.valueChanges);
		this.filteredOptionsUnit[index] = this.filterService.filterOptions(this.optionsUnit, unitCtrl.valueChanges);
	}

	/**
	 * Remove the FormGroup for a substance at the given index from the substances FormArray
	 * @since 1.0.0
	 * @param i The index of the substance FormGroup to remove
	 */
	removeSubstance(i: number): void {
		this.substances.removeAt(i);
	}

	/**
	 * Getter for the FormArray containing substances in the resultForm
	 * @returns The FormArray containing substances in the resultForm
	 */
	get substances() {
		return this.analysisForm.get('substances') as FormArray;
	}

	get sampleNumberCtrl(): FormControl {
		return this.sampleNumber as FormControl
	}

	/**
	 * Searches for a sample and updates the result form with the sample ID.
	 * @since 1.0.0
	 * @param {any|null} event - The event object triggered by the sample search.
	 * @return void
	 */
	sampleSearchResult(event: any | null): void {
		this.currentSample = event
		this.sampleForm.get('sampleId')?.patchValue(event.id)
	}

	/**
	 * Updates the value of the isValidSampleNumber property based on whether the sample is valid.
	 * @since 1.0.0
	 * @param {boolean} event - Whether the sample is valid.
	 * @return void
	*/
	sampleIsValid(event: boolean): void {
		this.isValidSampleNumber = event
	}


	private udpateCurrentSample(id: number): void {
		this.apiService.getSample(id).subscribe({
			next: (data: Sample) => {
				this.currentSample = data;
				this.populateAllForms();
			},
			error: (error) => {
				console.error(error)
			}
		});
	}


	
	updateTextLength(editor: any): void {
		this.textLength = editor.getContent({ format: 'text' }).length;
	}

	getTextblocks(): void {
		const params = { size: 2000 }
		this.apiService.getTextblocks(params).subscribe({
			next: (data) => {
				this.tinymceConfig.templates = data.content.map((textblock: any) => {
					return {
						title: textblock.description,
						description: ``,
						content: textblock.text
					}
				});
				this.tinymceConfigPublic.templates = data.content.map((textblock: any) => {
					return {
						title: textblock.description,
						description: ``,
						content: textblock.text
					}
				});
			},
			error: (err) => {
				this.snackBar.open(
					`Die Textblöcke konnten nicht geladen werden`,
					'Schließen',
					{ panelClass: 'error-snack', duration: 2000 }
				)
				this.textBlocksLoaded = true;
			},
			complete: () => {
				this.textBlocksLoaded = true;
			}
		});
	}

	getSampleImages(): void {
		this.apiService.downloadSampleImages(this.currentSample.id).subscribe(images => {
			if (images.length > 0 ) {
				this.imageList = images;
				this.cdr.detectChanges();
			}
		});
	}

	updateSampleStatus(status: string): void {
		// Open confirmation dialog
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '33vw',
			autoFocus: true,
			data: {
				title: `Probe ${this.currentSample.sampleNumber} auf "invalid" setzen?`,
				text: `Sind Sie absolut sicher, dass Sie den Status dieser Probe auf "invalid" setzen möchten? 
				Dieser Vorgang kann nicht Rückgängig gemacht werden. Eine Probe ist nur dann ungültig, wenn sie verloren 
				gegangen oder nicht analysierbar ist.`,
				confirmation: `${this.currentSample.sampleNumber}`,
				buttonText: 'AUSFÜHREN'
			}
		});

		dialogRef.afterClosed().subscribe(res => {
			if (res && res.data) {
				this.apiService.updateSampleStatus(this.currentSample.id, status).subscribe((data: any) => {
					this.snackBar.open(
						`Die Probe ${data.sampleNumber} wurde erfolgreich auf "Invalid" gesetzt.`,
						'Schließen',
						{ panelClass: 'success-snack', duration: 2000 }
					)
					this.dialogRef.close({ data: 'success' });
				})
			}
		});

	}

	/**
	 * Updates the number of characters in the text field.
	 * @param content The value of the text field.
	 * @since 2.0.0
	 * @return boolean
	 */
	updateCharacterCount(content: string): boolean {
		// Remove HTML tags to count only text characters
		const text = content.replace(/<[^>]+>/g, '');
		this.textCount = text.length
		if (text.length < 10000) return true
		return false
	}

	/**
	 * Submits the form data to create a new sample result if the form is valid and a 
	 * sample ID has been selected.
	 * @since 1.0.0
	 * @returns {void}
	 */
	onAnalysisSubmit(): void {

		if (this.analysisForm.valid && this.sampleForm.get('sampleId')?.value) {

			const formattedData: Analysis = this.formatAnalysisDataBeforeSend(this.analysisForm.value)

			if ( this.currentSample.sampleStatus === 'PENDING' ) { this.createAnalysis(formattedData) }
			else { this.editAnalysis(formattedData) }

		}
	}

	onAssessmentSubmit(): void {

		if (this.assessmentForm.valid && this.sampleForm.get('sampleId')?.value) {

			const formattedData: Assessment = this.formatAssessmentDataBeforeSend(this.assessmentForm.value)

			if (this.currentSample.sampleStatus === 'ANALYZED' ) { this.createAssessment(formattedData) }
			else { this.editAssessment(formattedData) }

		}
	}

	/**
	 * Creates a new result for the given sample.
	 * @since 1.0.0
	 * @return {void}
	 */
	private createAnalysis(data: Analysis): void {

		this.apiService.createAnalysis(this.currentSample.id, data).subscribe(() => {
			this.snackBar.open(
				`Die Analyse für Probe ${this.sampleNumber?.value} wurde erfolgreich erfasst.`,
				'',
				{ panelClass: 'success-snack', duration: 3000 }
			)
			this.udpateCurrentSample(this.currentSample.id);
		})
	}

	private createAssessment(data: Assessment): void {

		const assessmentRequest = this.apiService.createAssessment(this.currentSample.id, data);
		const imageRequest = this.apiService.updateSampleImages(this.currentSample.id, this.imageList);
		
		forkJoin([
			assessmentRequest,
			imageRequest
		]).subscribe({
			next: () => {
				this.snackBar.open(
					`Die Bewertung für Probe ${this.sampleNumber?.value} wurde erfolgreich erfasst.`,
					'',
					{ panelClass: 'success-snack', duration: 3000 }
				)
				this.udpateCurrentSample(this.currentSample.id);
			},
			error: (error) => {
				this.snackBar.open(
					`Die Bewertung konnte nicht gespeichert werden. Bitte probieren Sie es noch einmal.`,
					'',
					{ panelClass: 'error-snack', duration: 3000 }
				)
			}
		});
	}

    /**
     * Deletes a Assessment from API
     * @since 2.0.0
     * @returns void
     */
    deleteAssessment(): void {
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '33.33vw',
            autoFocus: true,
			data: {
				title: `Bewertung für Probe ${this.currentSample.sampleNumber} unwiderruflich löschen?`,
				text: `Sind Sie absolut sicher, dass Sie diese Bewerbung löschen wollen? Wenn ja, tippen Sie <code>${this.currentSample.sampleNumber}</code> in das Feld ein und fahren Sie fort. Andernfalls klicken Sie auf ABBRECHEN. 
                <p class="!mt-4 !mb-0 inline-flex justify-center w-full border border-solid border-[#ffc107] bg-[#fff4e3] py-4"><span class="material-symbols-outlined mr-2">tips_and_updates</span> Sie löschen nur das Ergebnis, nicht die Probe.</p>`,
				confirmation: `${this.currentSample.sampleNumber}`
			}
        });
		dialogRef.afterClosed().subscribe(res => {
			if ( res.data === 'confirmed' ) {
				
				this.imageList = [];

				const imageRequest = this.apiService.updateSampleImages(this.currentSample.id, this.imageList);
                const assessmentRequest = this.apiService.deleteAssessment(this.currentSample.id, this.currentSample.assessment.id)
				
				forkJoin([
					assessmentRequest,
					imageRequest
				]).subscribe({
					next: () => {
                    	this.udpateCurrentSample(this.currentSample.id);
					}
                });
			}	
		});
    }


	/**
     * Deletes a Analysis from API
     * @since 2.0.0
     * @returns void
     */
	deleteAnalysis(): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '33.33vw',
			autoFocus: true,
			data: {
				title: `Laborergebnis für Probe ${this.currentSample.sampleNumber} unwiderruflich löschen?`,
				text: `Sind Sie absolut sicher, dass Sie dieses Laborergebnis löschen wollen? Wenn ja, tippen Sie <code>${this.currentSample.sampleNumber}</code> in das Feld ein und fahren Sie fort. Andernfalls klicken Sie auf ABBRECHEN. 
				<p class="!mt-4 !mb-0 inline-flex justify-center w-full border border-solid border-[#ffc107] bg-[#fff4e3] py-4"><span class="material-symbols-outlined mr-2">tips_and_updates</span> Sie löschen nur das Ergebnis, nicht die Probe.</p>`,
				confirmation: `${this.currentSample.sampleNumber}`
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if ( res.data === 'confirmed' ) {
				this.apiService.deleteAnalysis(this.currentSample.id, this.currentSample.analysis.id).subscribe(() => {
					this.udpateCurrentSample(this.currentSample.id);
				});
			}	
		});
	}

	/**
	 * Edits an existing result for the given sample.
	 * @since 1.0.0
	 * @return {void}
	 */
	private editAnalysis(data: Analysis): void {

		this.apiService.updateAnalysis(this.currentSample.id, data.id, data).subscribe(() => {
			this.snackBar.open(
				`Die Analyse für Probe ${this.currentSample.sampleNumber} wurde erfolgreich aktualisiert.`,
				'',
				{ panelClass: 'success-snack', duration: 3000 }
			)
			this.udpateCurrentSample(this.currentSample.id);
		})
	}

	private editAssessment(data: Assessment): void {

		const assessmentRequest = this.apiService.updateAssessment(this.currentSample.id, data.id, data)
		const imageRequest = this.apiService.updateSampleImages(this.currentSample.id, this.imageList)

		forkJoin([
			assessmentRequest,
			imageRequest
		]).subscribe({
			next: () => {
				this.snackBar.open(
					`Die Bewertung für Probe ${this.currentSample.sampleNumber} wurde erfolgreich aktualisiert.`,
					'',
					{ panelClass: 'success-snack', duration: 3000 }
				)
				this.udpateCurrentSample(this.currentSample.id);
			},
			error: () => {
				this.snackBar.open(
					`Die Bewertung konnte nicht aktualisiert werden. Bitte probieren Sie es noch einmal.`,
					'',
					{ panelClass: 'error-snack', duration: 3000 }
				)
			}
		});
	}

	/**
	 * Format result data before sending to API
	 * @since 1.0.0
	 * @return {void}
	 */
	formatAnalysisDataBeforeSend(data: Analysis): any {

		const substances = []
		for (let i = 0; i < data.substances.length; i++) {
			substances.push({
				id: data.substances[i].id,
				name: data.substances[i].name,
				amount: data.substances[i].amount?.toString().replace(',', '.'),
				unit: data.substances[i].unit
			})
		}

		return {
			id: data.id,
			depth: parseFloat(data.depth?.toString().replace(',', '.')),			
			height: parseFloat(data.height?.toString().replace(',', '.')),
			weight: parseFloat(data.weight?.toString().replace(',', '.')),
			width: parseFloat(data.width?.toString().replace(',', '.')),
			substances: substances.length > 0 ? substances : null
		}
	}

	formatAssessmentDataBeforeSend(data: Assessment): any {
		
		if (data.alertType === 'none') {
			data.isAlert = false;
			data.isRedAlert = false;
		} else if (data.alertType === 'warning') {
			data.isAlert = true;
			data.isRedAlert = false;
		} else if (data.alertType === 'alarm') {
			data.isAlert = true;
			data.isRedAlert = true;
		}

		return {
			id: data.id,
			isAlert: data.isAlert,
			isRedAlert: data.isRedAlert,
			contaminated: data.contaminated,
			description: data.description,
			highlyDosed: data.highlyDosed,
			misdeclared: data.misdeclared,
			published: data.published,
			publicHeadline: data.publicHeadline,
			publicEvaluation: data.publicEvaluation
		}
	}

	onTogglePublished(event: MatSlideToggleChange): void {
		this.assessmentForm.get('published')?.patchValue(event.checked);
	}


	onImageListChange(images: any): void {
		this.imageList = images;
		this.hasChanges = true;
	}

	closeDialog(): void {
		if ( !this.hasChanges ) {
			this.dialogRef.close({ data: 'success'});
		} else {
			// Open confirmation dialog
			let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
				width: '33vw',
				autoFocus: true,
				data: {
					title: `Änderungen wurden noch nicht gespeichert!`,
					text: `Sie haben Ihre Änderungen noch nicht gespeichert. Klicken Sie auf "ABBRECHEN", 
							um Ihre Bewertung oder Analyse zu speichern.`,
					buttonText: 'WEITER OHNE SPEICHERN'
				}
			});

			dialogRef.afterClosed().subscribe(res => {
				if (res && res.data) {
					this.dialogRef.close({ data: 'success'});
				}
			});
		}
	}
}
