<div class="grid place-items-center content-center h-full">

    <img width="1072" height="386" class="-mt-[155px] w-full max-w-xs h-auto" src="assets/drgchckn_logo_big.png" />

    <form [formGroup]="form" class="max-w-xs" (ngSubmit)="onSubmit()">    

        <mat-form-field class="w-full">

            <mat-label>Benutzername</mat-label>

            <input matInput type="text" 
                formControlName="username"
                name="username" 
                tabindex="1" />

            <mat-icon *ngIf="form.get('username')?.value"
                    matSuffix
                    (click)="form.get('username')?.reset()"
                    class="cursor-pointer">
                close
            </mat-icon>

        </mat-form-field>

        <mat-form-field class="w-full">

            <mat-label>Passwort</mat-label>
            
            <input matInput type="password" 
                formControlName="password"
                type="{{hidden ? 'password' : 'text'}}" 
                name="password" 
                tabindex="2" 
            />

            <mat-icon *ngIf="form.get('password')?.value" 
                    matSuffix
                    (click)="form.get('password')?.reset()"
                    class="cursor-pointer !pr-0">
                close
            </mat-icon>

            <mat-icon matSuffix 
                    (click)="hidden = !hidden" 
                    class="cursor-pointer">
                {{hidden ? 'visibility_off' : 'visibility'}}
            </mat-icon>

        </mat-form-field>

        <button type="submit" 
                name="submit"
                mat-flat-button 
                color="primary" 
                class="w-full !h-mat-input uppercase" 
                [disabled]="loading"
                tabindex="3">
            <mat-spinner *ngIf="loading" [diameter]="25" class="btn-spinner"></mat-spinner>
            <ng-container *ngIf="!loading">Anmelden</ng-container>
        </button>

    </form>

</div>

