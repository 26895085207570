import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@core/services/api.service';
import { User } from '@shared/models/user.interface';

@Component({
  selector: 'app-me-dialog',
  templateUrl: './me-dialog.component.html',
  styleUrls: ['./me-dialog.component.scss']
})
export class MeDialogComponent implements OnInit {

	/** Form group for password change form */
	passwordForm: FormGroup

	/** Flag to toggle visibility of old password input */
	old_hidden: boolean = true

	/** Flag to toggle visibility of new password input */
	new_hidden: boolean = true

	/** User object to store logged-in user's data */
	me: User | any = {}

	constructor(
		private fb: FormBuilder,
		private apiService: ApiService,
		private snackBar: MatSnackBar
	) {}

	ngOnInit(): void {

		// Fetch logged-in user's data
		this.getMe();

		this.passwordForm = this.fb.group({
			oldPassword: new FormControl('', Validators.minLength(1)),
			newPassword: new FormControl('', Validators.minLength(12))
		});
	}

	/**
	 * Fetch logged-in user's data from API
	 * @since 1.0.0
	 * @returns void
	 */
	getMe(): void {
		this.apiService.getMe().subscribe((data: User) => {
			this.me = data
		});
	}

	/**
	 * Callback method called when password change form is submitted
	 * Sends a request to change password using ApiService
	 * @since 1.0.0
	 * @returns void
	 */
	onSubmit(): void {
		if ( this.passwordForm.valid ) {
			this.apiService.changePassword(
				this.passwordForm.get('newPassword')?.value,
				this.passwordForm.get('oldPassword')?.value
			).subscribe({
				next: () => {
					this.passwordForm.reset();
					this.snackBar.open(
						'Passwort wurde erfolgreich geändert und ist beim nächsten Login aktiv.', 
						'Verstanden.',
						{panelClass: 'success-snack', duration: 2500}
					)
				}
			})
		}
	}
}
