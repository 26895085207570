import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LightboxDialogComponent } from '../lightbox-dialog/lightbox-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

interface ImageFile {
    imageUrl: string | ArrayBuffer | null;
    fileName: string;
    fileSize: number;
	file: File
}

@Component({
	selector: 'app-image-upload-form',
	templateUrl: './image-upload-form.component.html',
	styleUrls: ['./image-upload-form.component.scss']
})
export class ImageUploadFormComponent implements OnInit, OnChanges {
	
	@Input() existingImages: ImageFile[] = [];

	@Output() images = new EventEmitter<any>();

	imageList: ImageFile[] = [];

	isLoading = false;

	readonly maxFileSize = 1048576; // 1 MB
	readonly maxImages = 2; // Maximum 2 images

	snackOptions: MatSnackBarConfig = { duration: 8000, panelClass: 'error-snack' };

	constructor(
		private dialog: MatDialog,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {
		if ( this.existingImages ) {
			this.imageList = this.existingImages;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
        if (changes['existingImages'] && changes['existingImages'].currentValue) {
            this.imageList = changes['existingImages'].currentValue; // Update imageList when existingImages changes
        }
    }

	onFilesSelected(event: Event) {
		const input = event.target as HTMLInputElement;
		if (input.files && input.files.length > 0) {
			const file = input.files[0];
			this.handleFile(file);
		}
	}

	onFileDropped(files: File[]) {
		if (files.length > 0) {
			const file = files[0];
			this.handleFile(file);
		}
	}

	private handleFile(file: File) {
		if (this.imageList.length >= this.maxImages) {
			this.snackBar.open(
				`Die maximale Anzahl von ${this.maxImages} Bildern wurde erreicht.`,
				'Schließen',
				this.snackOptions
			);
			return;
		}

		if (file.size > this.maxFileSize) {
			this.snackBar.open(
				`Die Dateigröße von ${(file.size / 1024).toFixed(2)}KB überschreitet die maximal zulässige Größe von 1024KB (1MB). Bitte wählen Sie eine kleinere Datei.`,
				'Schließen',
				this.snackOptions
			);
			return;
		}

		this.readFile(file);
	}

	private readFile(file: File) {
		this.isLoading = true;

		if (!['image/jpeg', 'image/jpg'].includes(file.type)) {
			this.snackBar.open(
				'Ungültiges Bildformat. Bitte laden Sie eine gültige Bilddatei (JPG) hoch.',
				'Schließen',
				this.snackOptions
			);
			this.isLoading = false;
			return;
		}

		const reader = new FileReader();

		reader.onload = () => {
			setTimeout(() => {
				this.imageList.push({
					imageUrl: reader.result,
					fileName: file.name,
					fileSize: file.size,
					file: file
				});
				this.sendImageList();
				this.isLoading = false;
			}, 500); // Delay for better UX
		};

		reader.onerror = () => {
			this.snackBar.open(
				'Fehler beim Lesen der Datei. Bitte versuchen Sie es erneut.',
				'Schließen',
				this.snackOptions
			);
			this.isLoading = false;
		};

		reader.readAsDataURL(file);
	}

	/** 
    * Opens the lab dialog for adding or editing a sample.
    * @since 1.0.0
    * @param sample - The sample to be edited, or null for adding a new sample.
    * @reutns void
    */
    openLightboxDialog(image: ImageFile): void {
        this.dialog.open(LightboxDialogComponent, {
            data: image.imageUrl
        });
    }

	removeImage(index: number): void {
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: 'Bild wirklich löschen?'
            },
			width: '300px'
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res && res.data === 'confirmed') {
                this.imageList.splice(index, 1);
				this.sendImageList();
            }
        });
    }

	// Drag and Drop handler
	drop(event: CdkDragDrop<ImageFile[]>) {
		moveItemInArray(this.imageList, event.previousIndex, event.currentIndex);
		this.sendImageList();
	}

	// Emits image data
	// TODO: BehavoirSubject to detect changes would be more elegant
	sendImageList(): void {
		this.images.emit(this.imageList);
	}
}
