import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-client-uid-generator',
	templateUrl: './client-uid-generator.component.html',
	styleUrls: ['./client-uid-generator.component.scss']
})
export class ClientUidGeneratorComponent implements OnInit {
	
	/** Event emitter for the generated client UID. */
	@Output() clientUid = new EventEmitter<any>();

	/** The form group for the client UID generator. */
	clientUidGenerator: FormGroup

	/** The subscription for the client UID generator form group's value changes. */
	subscription: Subscription

	constructor(
		private fb: FormBuilder
	){}

	ngOnInit(): void {

		this.clientUidGenerator = this.fb.group({
			firstNameMother: new FormControl(
				'', 
				[
					// Validators.required, 
					Validators.pattern('^[a-zA-Z]+$'),  // Letters only
					Validators.minLength(1), 
					Validators.maxLength(1)
				]
			),
			lastNameMother: new FormControl(
				'', 
				[
					// Validators.required, 
					Validators.pattern('^[a-zA-Z]+$'), // Letters only
					Validators.minLength(1), 
					Validators.maxLength(1)
				]
			),
			calendarDay: new FormControl(
				null, 
				[
					// Validators.required,
					Validators.pattern(/^(0?[1-9]|[1-2][0-9]|3[0-1])$/), // Only numbers from 01-31
					Validators.minLength(2),
					Validators.maxLength(2)
				]	
			),
			yearOfBirth: new FormControl(
				null,
				[
					// Validators.required, 
					Validators.pattern(/^(0?\d|[1-9]\d|99)$/), // Only numbers from 00-99
					Validators.minLength(2),
					Validators.maxLength(2)
				]	
			)
		});

		this.subscription = this.clientUidGenerator.valueChanges.subscribe((value) => {
			const firstNameMother = value.firstNameMother.toUpperCase() || '';
			const lastNameMother = value.lastNameMother.toUpperCase()  || '';
			const calendarDay = value.calendarDay || '';
			const yearOfBirth = value.yearOfBirth || '';
	  
			const clientUid = `${firstNameMother}${lastNameMother}${calendarDay}${yearOfBirth}`;
			
			// Emit the generated client UID only if the clientUidGenerator form is valid
			if ( this.clientUidGenerator.valid ) { 
				this.clientUid.emit(clientUid)
			}
			else {
				this.clientUid.emit('')
			}
		});
	}


	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}


}
