import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'salutation'
})
export class SalutationPipe implements PipeTransform {

	private salutationMap = new Map<string, string>([
		['MR', 'Herr'],
		['MRS', 'Frau'],
		['DIVERSE', '']
	]);

	transform(value: string): string {
		const translation = this.salutationMap.get(value);
    	return translation ? translation : value;
	}

}
