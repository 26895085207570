export class LabTinymceConfig {

    public static settings = {
        base_url: '/tinymce', 
        language_url : '/assets/tinymce/langs/tinymce-de.js',
        language: 'de',
        skin_url: '/assets/tinymce/skins/ui/vista',
        // content_css: 'document',
        content_style: 'body { font-size: 16px; color: #000000 }',
        deprecation_warnings: false, // force_p_newlines and forced_root_block are deprecated
        force_p_newlines : false,
        forced_root_block : '',
        // icons: 'material', -> premium only
        branding: false,
        // height: 720,
        menubar: false,
        plugins: [
            'advlist', 
            'autolink', 
            'lists', 
            'link', 
            'charmap', 
            'table', 
            'searchreplace', 
            'wordcount', 
            'visualchars', 
            'template',
        ],
        toolbar_mode: 'floating',
        toolbar: `undo redo | template | styles bold italic underline forecolor removeformat | numlist bullist | link charmap | table | searchreplace`,
        color_cols: 6
    }

}