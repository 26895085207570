<app-toolbar></app-toolbar>

<div class="flex flex-col h-auto p-5">

	<div class="flex flex-row gap-4">

		<div class="w-1/4">

			<mat-card id="">
				<mat-card-header class="flex flex-col">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">person_add</span>Neue*n Klient*in
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="!inline-flex flex-col mt-4">
					<button mat-flat-button color="primary" type="button" (click)="addClient()" [disabled]="!['ADMIN', 'CONSULTANT'].includes(currentUser.role)" class="uppercase">
                        <mat-icon>add</mat-icon> Hinzufügen
                    </button>
				</mat-card-content>
			</mat-card>

			<mat-card id="" class="mt-4">
				<mat-card-header class="flex flex-col">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">person_search</span>Klient*in suchen
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="!inline-flex flex-col mt-4">
					<app-client-search-form 
							(searchResults)="onSearchResult($event)" 
							(clientUidCtrlValue)="onClientUidCtrlValue($event)">
					</app-client-search-form>
					<button type="button" class="mt-2 !hidden" mat-flat-button (click)="getClients()"><mat-icon>close</mat-icon>Zurücksetzen</button>
				</mat-card-content>
			</mat-card>

		</div>

		<div class="w-full">

			<mat-card>
				<mat-card-header class="mb-5">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">group</span> Alle Klienten/Klientinnen
					</mat-card-title>
				</mat-card-header>

				<mat-card-content class="!flex !flex-col">
					<table mat-table  [dataSource]="dataSource" class="mat-elevation-z0">

						<!-- Edit Column -->
						<ng-container matColumnDef="edit">
							<th mat-header-cell *matHeaderCellDef> Bearbeiten </th>
							<td mat-cell *matCellDef="let element">
								<button mat-icon-button color="primary" (click)="editClient(element)" [disabled]="!['ADMIN', 'CONSULTANT'].includes(currentUser.role)">
									<mat-icon>edit</mat-icon>
								</button>
							</td>
						</ng-container>

						<!-- Client UID Column -->
						<ng-container matColumnDef="clientUid">
							<th mat-header-cell *matHeaderCellDef> Klient*in UID </th>
							<td mat-cell *matCellDef="let element"> 
								{{element.clientUid}} 
								<app-copy-to-clipboard-button [textToCopy]="element.clientUid"></app-copy-to-clipboard-button>
							</td>
						</ng-container>

						<!-- Postal Code Column -->
						<ng-container matColumnDef="postalCode">
							<th mat-header-cell *matHeaderCellDef> Wohnort (PLZ) </th>
							<td mat-cell *matCellDef="let element"> {{element.postalCode}} </td>
						</ng-container>


						<!-- Gender Column -->
						<ng-container matColumnDef="gender">
							<th mat-header-cell *matHeaderCellDef> Geschlecht </th>
							<td mat-cell *matCellDef="let element"> {{element.gender | gender}} </td>
						</ng-container>

						<!-- Nationalities Column -->
						<ng-container matColumnDef="nationality">
							<th mat-header-cell *matHeaderCellDef> Staatsangehörigkeit(en) </th>
							<td mat-cell *matCellDef="let element">
                                {{element.nationality | nationality}}{{element.nationality2 ? ' / ' + (element.nationality2 | nationality) : ''}}
							</td>
						</ng-container>

						<!-- First Consultation Column -->
						<ng-container matColumnDef="firstConsultationDate">
							<th mat-header-cell *matHeaderCellDef> Erste Beratung </th>
							<td mat-cell *matCellDef="let element"> {{element.firstConsultationDate | date: 'dd.MM.YYYY'}} </td>
						</ng-container>
				
						<!-- Last Consultation Column -->
						<ng-container matColumnDef="lastConsultationDate">
							<th mat-header-cell *matHeaderCellDef> Letzte Beratung </th>
							<td mat-cell *matCellDef="let element"> {{element.lastConsultationDate  | date: 'dd.MM.YYYY'}} </td>
						</ng-container>

                        <!-- Delete Column -->
						<ng-container matColumnDef="delete">
							<th mat-header-cell *matHeaderCellDef> Löschen </th>
							<td mat-cell *matCellDef="let element">
								<button mat-icon-button color="warn" (click)="deleteClient(element)" [disabled]="!['ADMIN'].includes(currentUser.role)">
									<mat-icon>close</mat-icon>
								</button>
							</td>
						</ng-container>
						
						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>

						<!-- Row shown when there is no matching data.  -->
						<tr class="mat-row" *matNoDataRow>
							<td class="mat-cell text-center !p-6" colspan="8">
								<span *ngIf="clientSearchValue">Keine Klienten oder Klientinnen mit der UID {{clientSearchValue}} gefunden.</span>
								<span *ngIf="!clientSearchValue">Keine Klienten oder Klientinnen gefunden.</span>
							</td>
						</tr>

					</table>

					<mat-paginator class="mat-elevation-z0 mt-4" 
									[length]="totalElements"
									[pageSize]="pageSize" 
									[pageSizeOptions]="[5, 10, 25, 100]"
									(page)="pageChanged($event)" 
									showFirstLastButtons>
					</mat-paginator>

				</mat-card-content>
			</mat-card>

			<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

		</div>

	</div>

</div>