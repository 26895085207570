import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

	/** Property to store the value of the confirmation input field */
	confirmationValue: string = ''

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<ConfirmationDialogComponent>
	) { }

	/**
	 * Callback method called when 'Continue' button is clicked
	 * Closes the dialog and passes the confirmation data
	 * @since 1.0.0
	 * @returns void
	 */
	continue(): void {
		this.dialogRef.close({ data: 'confirmed' })
	}

}
