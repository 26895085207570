import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'userStatus'
})
export class UserStatusPipe implements PipeTransform {

	private userStatusMap = new Map<string, string>([
		['ACTIVE', 'Aktiv'],
		['BLOCKED', 'Blockiert'],
        ['LOCKED', 'Gesperrt'],
        ['DELETED', 'Gelöscht'],
	]);

	transform(value: string): string {
		const translation = this.userStatusMap.get(value);
		return translation ? translation : value;
	}

}
