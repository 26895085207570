import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { FetchOpenSitting } from '@shared/states/sitting/sitting.action';
import { Login } from '../states/auth.action';
import { User } from '@shared/models/user.interface';
import { AuthState } from '../states/auth.state';
import { Role } from '@shared/enums/role.enum';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    /** The form used for the login page. */
    form: FormGroup = new FormGroup({});

    /** Indicates whether the password field is hidden or not. */
    hidden: boolean = true;

    /** Indicates whether the page is currently loading. */
    loading: boolean = false;

    /** Configuration options for the snack bar used for displaying success messages. */
    snackOptions: MatSnackBarConfig = { duration: 5000, panelClass: 'success-snack' };

    constructor(
        private fb: FormBuilder,
        private store: Store,
        private snackBar: MatSnackBar,
        private router: Router
    ) { }


    ngOnInit(): void {
        this.form = this.fb.group({
            username: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
        });
    }

    /**
     * Handles the form submission for logging in.
     * If the form is valid, dispatches a Login action with the provided username and password,
     * shows a loading spinner, and handles the success and error cases.
     * @since 1.0.0
     * @return {void}
     */
    onSubmit(): void {
        if (!this.form.invalid) {
            // Extract username and password from the form value
            const { username, password } = this.form.value;

            // Show loading spinner
            this.loading = true;

            // Dispatch Login action and handle success and error cases
            this.store.dispatch(new Login({ username, password })).subscribe({
                next: (store: any) => {
                    // Handle success case
                    this.loading = false;
                    this.snackBar.open('Authentifizierung erfolgreich.', '🛰️', this.snackOptions);
                    if (  [Role.PHARMACIST, Role.LABORATORY].includes(store.auth.user.role) ) {
                        this.router.navigate(['/laboratory']);
                    } else {
                        this.store.dispatch(new FetchOpenSitting());
                        this.router.navigate(['/sitting']);
                    } 
                    
                },
                error: () => {
                    // Handle error case
                    this.loading = false;
                }
            });
        }
    }
}
