import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class FilterService {

	constructor() { }

	filterOptions(options: string[], input$: Observable<string>): Observable<string[]> {
		return input$.pipe(
			startWith(''),
			map(value => this._filter(value, options))
		);
	}

	private _filter(value: string, options: string[]): string[] {
		const filterValue = value.toLowerCase();
		return options.filter(option => option.toLowerCase().includes(filterValue));
	}
}
