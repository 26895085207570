<h1 mat-dialog-title class="!mb-0 !pt-3 !inline-flex items-center">
    <span class="material-symbols-outlined mr-1">verified_user</span>{{me?.username}}
</h1>

<mat-dialog-content class="mat-typography bg-[#F1F1F1] !pt-4">

    <mat-card>

        <mat-card-content>

            <table class="user-table w-full mb-8">
                <tbody>
                    <tr *ngIf="me.role">
                        <td class="min-w-[7rem] font-bold">Berechtigung:</td>
                        <td>{{me.role | role}}</td>
                    </tr>
                    <tr *ngIf="me.emailAddress">
                        <td class="min-w-[7rem] font-bold">E-Mail:</td>
                        <td>{{me.emailAddress}}</td>
                    </tr>
                    <tr *ngIf="me.salutation">
                        <td class="min-w-[7rem] font-bold">Name:</td>
                        <td>{{me.salutation | salutation}} {{me?.firstname}} {{me?.lastname}}</td>
                    </tr>
                    <tr *ngIf="me.organisation">
                        <td class="min-w-[7rem] font-bold">Organisation:</td>
                        <td>{{me.organisation | organization}}</td>
                    </tr>
                    <tr *ngIf="me.lastLogin">
                        <td class="min-w-[7rem] font-bold">Letzter Login:</td>
                        <td>{{me.lastLogin | date: 'dd.MM.YYYY'}}, um {{me?.lastLogin | date: 'HH:mm:ss'}} Uhr</td>
                    </tr>
                </tbody>
            </table>

            <form [formGroup]="passwordForm" (submit)="onSubmit()">

                <h2>Passwort ändern:</h2>

                <mat-form-field class="w-full">

                    <mat-label>Altes Passwort</mat-label>

                    <input matInput type="password" formControlName="oldPassword"
                        type="{{old_hidden ? 'password' : 'text'}}" name="password old" tabindex="2" />

                    <mat-icon *ngIf="passwordForm.get('oldPassword')?.value" matSuffix
                        (click)="passwordForm.get('oldPassword')?.reset()" class="cursor-pointer !pr-0">
                        close
                    </mat-icon>

                    <mat-icon matSuffix (click)="old_hidden = !old_hidden" class="cursor-pointer">
                        {{old_hidden ? 'visibility_off' : 'visibility'}}
                    </mat-icon>

                    <mat-error *ngIf="passwordForm.hasError('minLength')">Das Passwort ist zu kurz.</mat-error>

                </mat-form-field>

                <mat-form-field class="w-full">

                    <mat-label>Neues Passwort</mat-label>

                    <input matInput type="password" formControlName="newPassword"
                        type="{{new_hidden ? 'password' : 'text'}}" name="password" tabindex="2" />

                    <mat-icon *ngIf="passwordForm.get('newPassword')?.value" matSuffix
                        (click)="passwordForm.get('newPassword')?.reset()" class="cursor-pointer !pr-0">
                        close
                    </mat-icon>

                    <mat-icon matSuffix (click)="new_hidden = !new_hidden" class="cursor-pointer">
                        {{new_hidden ? 'visibility_off' : 'visibility'}}
                    </mat-icon>

                    <mat-hint align="end">Mindestlänge 12 Zeichen.</mat-hint>

                    <mat-error *ngIf="passwordForm.get('newPassword')?.hasError('minlength')">Das Passwort ist zu kurz. {{passwordForm.get('newPassword')?.value.length}} von 12 Zeichen erreicht.</mat-error>

                </mat-form-field>

                <button mat-flat-button color="primary" class="mt-1" type="submit">Passwort ändern</button>

            </form>

        </mat-card-content>

    </mat-card>

</mat-dialog-content>

<mat-dialog-actions align="end" class="bg-[#FFFFFF]">
    <button type="button" mat-button mat-dialog-close cdkFocusInitial>Schließen</button>
</mat-dialog-actions>