import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ApiService } from '@core/services/api.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Store } from '@ngxs/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, map, Observable, startWith, Subscription } from 'rxjs';


@Component({
	selector: 'app-sample-detail-dialog',
	templateUrl: './sample-detail-dialog.component.html',
	styleUrls: ['./sample-detail-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SampleDetailDialogComponent implements OnInit, OnDestroy {

	@ViewChild('inputRef') inputRef: any;

	subscription: Subscription

	/** Reference to the MatTabGroup element. */
	@ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

	/** Flag indicating if the "previous" button should be hidden. */
	hidePrevButton = true;

	/** Flag indicating if the "next" button should be hidden. */
	hideNextButton = false;

	/** FormGroup representing the sample form. */
	sampleForm!: FormGroup;

	/** Flag indicating if the component is in "add" mode. */
	isAddMode = false;

	/** Flag indicating if the HTTP request is loading. */
	isLoading = false;

	/** Prefix for the sample number. */
	sampleNumberPrefix = '';

	/** Array of options for the sample forms. */
	optionsSampleForm: string[] = [
		'Pulver',
		'Tablette',
		'Papertrip',
		'Kristalle',
		'Flüssigkeit'
	];

	/** Observable of filtered options of the sample form. */
	filteredOptionsSampleForm: Observable<string[]>;
	
	/** Array of options for the subtance names. */
	optionsSubstanceName: string[] = [
		'Cannabis',
		'1P-LSD',
		'1D-Al–LAD',
		'1D-LSD',
		'1V-LSD',
		'2C-B-Fly',
		'2C-E',
		'2C-P',
		'2-FDCK',
		'2-FMA',
		'2C-B',
		'3-MMC',
		'3-CMC',
		'4-MMC',
		'AL-LAD',
		'Alprazolam',
		'Amphetamin',
		'CBD',
		'Crystal Meth',
		'Diamorphin',
		'Diazepam',
		'd-LSD',
		'DMT',
		'GBL',
		'GHB',
		'Heroin',
		'Ketamin',
		'Kokain',
		'Lorazepam',
		'LSD',
		'MDMA',
		'Mephedron',
		'Methamphetamin',
		'PCP',
		'Psilocybin',
		'Speed',
		'Speed-Paste',
		'Substanz ist unklar',
		'THC',
		'Tucibi',
		'Unbekannt'
	];

	/** Observable of filtered options of the substance name. */
	filteredOptionsSubstance: Observable<string[]>;

	/** Array of options for the sample unit. */
	optionsUnit = [
		{ value: 'PIECE', name: 'Stück' },
		{ value: 'MILLIGRAM', name: 'mg' },
		{ value: 'GRAM', name: 'g' },
		{ value: 'MILLILITER', name: 'ml' },
		{ value: 'CONSUMPTION_UNIT', name: 'Konsumeinheit' }
	];

	/** Array of options for the purchase channel. */
	optionsPurchaseChannel = [
		{ value: 'STREET', name: 'Straße' },
		{ value: 'DEALER', name: 'mit Händler getroffen' },
		{ value: 'INTERNET', name: 'Internet' },
		{ value: 'DARKNET', name: 'Deepweb' },
		{ value: 'PARTY', name: 'Party / Veranstaltung' },
		{ value: 'OTHER', name: 'Anderes' },
		{ value: 'UNKNOWN', name: 'keine Angabe' }
	];

	/** Array of options for the sample distribution. */
	optionsDistribution = [
		{ value: 'REGULAR_DEALER', name: 'Stammhändler' },
		{ value: 'UNKNOWN_DEALER', name: 'Unbekannter Händler' },
		{ value: 'PRIVATE_CONTACT', name: 'privater Kontakt zu Händler'},
		{ value: 'INTERNET', name: 'Internet' },
		{ value: 'OTHER', name: 'Anderes' },
		{ value: 'UNKNOWN', name: 'Keine Angabe' }
	];

	/** Formatted amount. */
	formattedAmount: any;

	/** Sample amount. */
	amount: any;

	/** The status of the current sitting. */
	sittingStatus: string= '';

	/** Today date for max attribute of the datepicker */
	today: Date = new Date();

	/** The number of characters in the text field. */
	textCount: number = 0;

	constructor(
		private currencyPipe: CurrencyPipe,
		private datePipe: DatePipe,
		private store: Store,
		private apiService: ApiService,
		public fb: FormBuilder,
		public dialogRef: MatDialogRef<SampleDetailDialogComponent>,
		public snackBar: MatSnackBar,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit(): void {
		
		// Initialize the sample form.
		this.sampleForm = this.fb.group({
			id: new FormControl(''),
			clientUid: new FormControl(this.data.clientUid),
			sampleNumber: new FormControl(this.getSampleNumberPrefix(), [Validators.required]),
			consultationId: new FormControl(this.data.consultationId),
			submissionDate: new FormControl(new Date()),
			name: new FormControl('', [Validators.required]),
			isMainSubstance: new FormControl(null),
			reasonEffects: new FormControl(false),
			reasonPrevention: new FormControl(false),
			reasonOther: new FormControl(''),
			notes: new FormControl(''),
			sampleForm: new FormControl('', [Validators.required]),
			sampleColor: new FormControl('', [Validators.required]),
			sampleLogo: new FormControl(''),
			purchaseLocation: new FormControl(null),
			purchaseDate: new FormControl(''),
			price: new FormControl(null),
			unit: new FormControl(null),
			purchaseChannel: new FormControl(null),
			distribution: new FormControl(null),
			sampleStatus: new FormControl('PENDING'),
			feedbackTimestamp: new FormControl('')
		});

		this.filteredOptionsSampleForm = this.sampleForm.get('sampleForm')!.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value || '', this.optionsSampleForm)),
		);

		this.filteredOptionsSubstance = this.sampleForm.get('name')!.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value || '', this.optionsSubstanceName))
		);

		// Get the sample if an ID was provided, or set the component to add mode.
		if ( this.data.id ) {
			this.getSample();
		} else {
			this.isAddMode = true;
		}

		// Get the sitting status
		this.sittingStatus = this.store.selectSnapshot<string>((state: any) => state.breadcrumb.sitting.sittingStatus);

		// Subscribe to form control valueChanges
		this.subscription = this.sampleForm.get('price')!.valueChanges.pipe(debounceTime(750)).subscribe((value: number) => {
			if ( value ) {
				const elRef = this.inputRef.nativeElement;
				// Parse the input value to a numeric format
			   const numericValue = parseFloat(value.toString().replace(',', '.')).toFixed(2);
			   const transformedValue = this.currencyPipe.transform(numericValue, 'EUR');
			   // Update the displayed value in the input field
			   elRef.value = transformedValue;
			   // Update the numeric value in the form control
			   this.sampleForm.get('price')!.setValue(numericValue, { emitEvent: false, emitModelToViewChange: false });
			}
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/**
	 * Filters the options of the sample form based on the provided value.
	 * 
	 * @param {string} value - The value to filter by.
	 * @since 1.0.0
	 * @returns {string[]} The filtered options of the sample form.
	 */
	private _filter(value: string, options: string[]): string[] {
		const filterValue = value.toLowerCase();
	
		return options.filter(option => option.toLowerCase().includes(filterValue));
	}

	/**
	 * Updates the number of characters in the text field.
	 *
	 * @param {string} value The value of the text field.
	 * @since 1.0.0
	 * @return void
	 */
	updateTextCount(value: string): void {
		this.textCount = value.length
	}

	/**
	 * Retrieves sample data from the API and sets it in the sample form.
	 * @since 1.0.0
	 * @return void
	 */
	getSample(): void {
		this.apiService.getSample(this.data.id).subscribe((data: any) => {
			this.sampleForm.patchValue(data)
			// this.formatPrice('price')
			// this.formatSampleNumber('sampleNumber')
		});
	}

	/**
	 * Decreases the selected index of the tab group by 1.
	 * @since 1.0.0
	 * @return void
	 */
	prevTab(): void {
		this.tabGroup.selectedIndex! -= 1;
	}

	/**
	 * Increases the selected index of the tab group by 1.
	 * @since 1.0.0
	 * @return void
	 */
	nextTab(): void {
		this.tabGroup.selectedIndex! += 1;
	}

	/**
	 * Handles the event when the selected tab is changed and sets 
	 * the hidePrevButton and hideNextButton properties accordingly.
	 * @since 1.0.0
	 * @param event - The index of the newly selected tab.
	 * @return void
	 */
	onTabChanged(event: number): void {
		this.hidePrevButton = event === 0;
		this.hideNextButton = event === this.tabGroup._tabs.length - 1;
	}

	/**
	 * Formats a date string and sets the resulting value in the specified form control.
	 * @since 1.0.0
	 * @param controlName - The name of the form control to update.
	 * @param date - The date string to format.
	 * @param format - The desired format for the date string. Default value is 'YYYY-MM-DD'.
	 * @return void
	 */
	formatOutput(controlName: string, date: string, format: string = 'YYYY-MM-DD'): void {
		this.sampleForm.get(controlName)?.patchValue(moment(date).format(format));
	}

	/**
	 * Sets the month and year of the specified datepicker control to the values of the specified 
	 * Moment object.
	 * @since 1.0.0
	 * @param normalizedMonthAndYear - The Moment object containing the new month and year values.
	 * @param datepicker - The MatDatepicker control to update.
	 * @return void
	 */
	setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>): void {
		this.sampleForm.get('purchaseDate')!.setValue(normalizedMonthAndYear.format('YYYY-MM-DD'));
		datepicker.close();
	}

	/**
	 * Formats the value of the specified form control as a currency and sets the resulting value back in 
	 * the same control.
	 * @since 1.0.0
	 * @param controlName - The name of the form control to format.
	 * @return void
	 */
	/*formatPrice(controlName: string): void {
		const control = this.sampleForm.get(controlName);
		if ( control?.value ) {
			const numericValue = parseFloat(control.value.toString().replace(',', '.'));
			const formattedValue = this.currencyPipe.transform(numericValue, 'EUR');
			control.patchValue(formattedValue, { emitEvent: false });
		}
	}*/

	/**
	 * Returns the sample number prefix based on the organization and the current year.
	 * @since 1.0.0
	 * @return {string} The sample number prefix.
	*/
	getSampleNumberPrefix(): string {
		const user = this.store.selectSnapshot((state: any) => state.auth.user)
		const organizationPrefix = user?.organisation.charAt(0).toUpperCase()
		const yearPrefix = this.datePipe.transform(new Date(), 'yy')

		return `${organizationPrefix}-${yearPrefix}-`;
	}

	/**
	 * Handles the form submission, converts the sample number and price, and calls the API service to add 
	 * or update the sample.
	 * @since 1.0.0
	 * @return void
	 * 
	 */
	onSubmit(): void {

		// console.log(this.sampleForm)
		if ( this.sampleForm.valid ) {
			
			// Reconvert the price before submitting
			/*const price = this.sampleForm.get('price')?.value;
			if ( price ) {
				const numericValue = Number.parseFloat(price.replace(',', '.'));
				this.sampleForm.get('price')?.patchValue(numericValue)
			}*/
			this.isLoading = true;

			if ( this.isAddMode ) {

				this.apiService.addSample(this.sampleForm.value).subscribe({
					next: () => {
						this.snackBar.open('Probe erfolgreich hinzugefügt.', 'Schließen', { panelClass: 'success-snack', duration: 2000 })
						this.dialogRef.close({data: 'success'});
					},
					error: (error) => {
						// If sample number already in use, set form control to invalid
						if ( error.error.error === 'SAMPLE_NUMBER_IN_USE') {
							this.sampleForm.get('sampleNumber')?.setErrors({sampleNumberInUse: true})
						}
						// If sample number does not exist, set form control to invalid
						else if ( error.error.error === 'SAMPLE_NUMBER_NOT_FOUND' ) {
							this.sampleForm.get('sampleNumber')?.setErrors({sampleNumberNotFound: true})
						}
						// If sample number has bad format, set form control to invalid
						else if ( error.error.error === 'SAMPLE_NUMBER_INVALID_FORMAT') {
							this.sampleForm.get('sampleNumber')?.setErrors({sampleNumberInvalidFormat: true})
						}

						this.isLoading = false;
					},
					complete: () => {
						this.isLoading = false;
					}
				})

			} else {

				this.apiService.updateSample(this.data.id, this.sampleForm.value).subscribe({
					next: () => {
						this.snackBar.open('Änderungen wurden gespeichert.', 'Schließen', { panelClass: 'success-snack', duration: 2000 })
						this.dialogRef.close({data: 'success'});
					},
					error: () => {
						this.snackBar.open('Änderungen konnten nicht gespeichert werden.', 'Schließen', { panelClass: 'error-snack', duration: 2000 })
						// Format number to displayed price back again
						// this.formatPrice('price')
					},
					complete: () => {
						this.isLoading = false;
					}
				})

			}
			
		} else {
			this.snackBar.open('Pflichtfelder* wurden noch nicht alle ausgefüllt.', 'Schließen', { panelClass: 'error-snack', duration: 2000 })
			// Mark all form controls as touched for validation
			this.sampleForm.markAllAsTouched();
		}
	}


}