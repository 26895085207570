<h1 mat-dialog-title class="!mb-4">Neue Beratung starten</h1>

<mat-dialog-content class="mat-typography">

    <mat-accordion>
        <mat-expansion-panel class="mat-elevation-z1">

            <mat-expansion-panel-header>
                <mat-panel-title> Klient*in UID rekonstruieren </mat-panel-title>
            </mat-expansion-panel-header>

            <app-client-uid-generator (clientUid)="onClientUidReconstruction($event)"></app-client-uid-generator>

        </mat-expansion-panel>
    </mat-accordion>

    <mat-card class="p-8 mt-4">

        <app-client-search-form (searchResults)="onSearchResultsChange($event)"
            (clientUidCtrlValue)="onClientCtrlValueChange($event)" [clientUid]="clientUid">
        </app-client-search-form>

        <table mat-table *ngIf="clientUidCtrlValue && clientUidCtrlValue.length >= 6" [dataSource]="dataSource"
            class="mat-elevation-z0 mt-4">

            <!-- Client UID Column -->
            <ng-container matColumnDef="clientUid">
                <th mat-header-cell *matHeaderCellDef> Klient*in UID </th>
                <td mat-cell *matCellDef="let element"> {{element.clientUid}} </td>
            </ng-container>

            <!-- Gender Column -->
            <ng-container matColumnDef="gender">
                <th mat-header-cell *matHeaderCellDef> Geschlecht </th>
                <td mat-cell *matCellDef="let element"> {{element.gender | gender}} </td>
            </ng-container>

            <!-- Postal Code Column -->
            <ng-container matColumnDef="postalCode">
                <th mat-header-cell *matHeaderCellDef> Postleitzahl </th>
                <td mat-cell *matCellDef="let element"> {{element.postalCode}} </td>
            </ng-container>

            <!-- Nationalities Column -->
            <ng-container matColumnDef="nationality">
                <th mat-header-cell *matHeaderCellDef> Staatsangehörigkeit(en) </th>
                <td mat-cell *matCellDef="let element">
                    {{element.nationality | nationality}}{{element.nationality2 ? ' / ' + (element.nationality2 | nationality) : ''}}
                </td>
            </ng-container>

            <!-- First Consultation Column -->
            <ng-container matColumnDef="firstConsultationDate">
                <th mat-header-cell *matHeaderCellDef> Erste Beratung </th>
                <td mat-cell *matCellDef="let element"> {{element.firstConsultationDate | date: 'dd.MM.YYYY'}} </td>
            </ng-container>
   
            <!-- Last Consultation Column -->
            <ng-container matColumnDef="lastConsultationDate">
                <th mat-header-cell *matHeaderCellDef> Letzte Beratung </th>
                <td mat-cell *matCellDef="let element"> {{element.lastConsultationDate  | date: 'dd.MM.YYYY'}} </td>
            </ng-container>

            <!-- Start Consultation Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> Neue Beratung </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-flat-button color="primary" (click)="onCreateConsultation(element)">
                        <mat-icon>play_circle</mat-icon> Starten
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>

            <!-- Row shown when there is no matching data. -->
            <ng-container *ngIf="clientUidCtrlValue && clientUidCtrlValue.length >= 6">
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell p-4" colspan="5">
                        <p>
                            Keine*n Klient*in gefunden mit UID <b>"{{clientUidCtrlValue}}"</b>.
                            <a [href]="" class="text-dc-blue-400 hover:text-dc-blue-900 cursor-pointer"
                                (click)="tabGroup.selectedIndex = 1">
                                Neue*n Klient*in anlegen?
                            </a>
                        </p>
                    </td>
                </tr>
            </ng-container>

        </table>

    </mat-card>

</mat-dialog-content>