import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@core/services/api.service';
import { ClientAddEditComponent } from '@shared/components/client-add-edit/client-add-edit.component';

@Component({
	selector: 'app-client-dialog',
	templateUrl: './client-dialog.component.html',
	styleUrls: ['./client-dialog.component.scss']
})
export class ClientDialogComponent {

	/** Reference to the ClientAddEditTabComponent in the template. */
	@ViewChild(ClientAddEditComponent) clientAddEdit: ClientAddEditComponent;

	isLoading: boolean = false

	constructor(
		private dialogRef: MatDialogRef<ClientDialogComponent>,
		private apiService: ApiService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	/**
	 * Submits the form data to the ApiService to create a new client.
	 * @since 1.0.0
	 * @param formData - The form data containing the client information to create.
	 * @returns void
	 */
	onFormSubmit(formData: any): void {

		this.isLoading = true;

		if (this.data && !this.data.isAddMode) {

			this.apiService.updateClient(this.data.client.id, formData).subscribe({
				next: (data: any) => {
					this.dialogRef.close({ data: data });
					this.isLoading = false;
				},
				error: (err) => {
					console.log(err)
					this.isLoading = false;
				}
			});

		} else {

			this.apiService.createClient(formData).subscribe({
				next: (data: any) => {
					this.dialogRef.close({ data: data });
					this.isLoading = false;
				},
				error: (err) => {
					console.log(err)
					this.isLoading = false;
				}
			});

		}

	}
}
