import { Component } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { environment } from '@environments/environment';

@Component({
	selector: 'app-app-info-dialog',
	templateUrl: './app-info-dialog.component.html',
	styleUrls: ['./app-info-dialog.component.scss']
})
export class AppInfoDialogComponent {

	api: any
	guiVersion: string = ''

	constructor(
		private apiService: ApiService
	) { }

	ngOnInit(): void {
		this.apiService.getVersion().subscribe((data: any) => this.api = data)
		this.guiVersion = environment.appVersion
	}

}
