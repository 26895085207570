import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '@shared/enums/role.enum';

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {

	private roleMap = new Map<string, string>([
		[Role.ADMIN, 'Administrator*in'],
  		[Role.CONSULTANT, 'Berater*in'],
		[Role.PHARMACIST, 'Pharmazeut*in'],
  		// [Role.FEEDBACK, 'Rückmeldung'],
		[Role.LABORATORY, 'Laborant*in']
	]);

	transform(value: string): string {
		const translation = this.roleMap.get(value);
    	return translation ? translation : value;
	}
}
