import { Injectable, NgZone } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { Login, Logout } from './auth.action';
import { User } from "@shared/models/user.interface";
import { StateClear } from 'ngxs-reset-plugin';
import { Router } from '@angular/router';

export interface AuthStateModel {
    user: any,
    isLoggedIn: boolean | null
}


@State<AuthStateModel>({
	name: 'auth',
	defaults: {
		isLoggedIn: false,
		user: null
	}
})
@Injectable()
export class AuthState {
    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return !!state.isLoggedIn;
    }

    /*@Selector()
    static getUsername(state: AuthStateModel): string {
		return state.user?.username || ''
    }*/

	@Selector()
    static getCurrentUserRole(state: AuthStateModel) {
        return state.user?.role;
    }

    constructor(
        private authService: AuthService,
		private store: Store,
		private router: Router,
		private ngZone: NgZone
    ) { }

	@Action(Login)
	login(ctx: StateContext<AuthStateModel>, action: Login) {
		return this.authService.login(
			action.payload.username, 
			action.payload.password
		).pipe(
			tap((data: User) => {
				ctx.patchState({
					isLoggedIn: true,
					user: {
						role: data.role,
						userStatus: data.userStatus,
						organisation: data.organisation
					}
				});
			})
		);
	}

	@Action(Logout)
	logout(ctx: StateContext<AuthStateModel>) {
		return this.authService.logout().pipe(
			tap(() => {
				// Clear all states
				this.store.dispatch(new StateClear());
				// But reset logout state to keep isLoggedIn flag
				ctx.setState({
					isLoggedIn: false,
					user: null
				});
				// The navigate to login page using router service inside ngZone
				this.ngZone.run(() => {
					this.router.navigate(['/']);
				});
			})
		);
	}
}