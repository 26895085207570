<form [formGroup]="userForm" class="bg-[#F1F1F1]" (submit)="onSubmit()">

    <h1 mat-dialog-title>
        {{isAddMode ? 'Neue*n Benutzer*in hinzufügen' : 'Benutzer*in bearbeiten'}}
    </h1>
    
    <mat-dialog-content class="mat-typography h-[68vh] !max-h-[100vh]">
 
        <mat-card class="!grid !grid-cols-1 !gap-4 w-full !p-4 !rounded-none mb-4">

            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Nutzerrolle</mat-label>
                <mat-select formControlName="role">
                    <mat-option *ngFor="let option of userRoleOptions" [value]="option.value">
                        {{option.name | role}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>E-Mail Adresse</mat-label>
                <input matInput type="text" formControlName="emailAddress">
            </mat-form-field>  
                        
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Benutzername</mat-label>
                <input matInput type="text" formControlName="username">
            </mat-form-field>

            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Passwort</mat-label>
                <input matInput type="password" formControlName="password" type="text" />
                <mat-hint align="end">Mindestlänge 12 Zeichen.</mat-hint>
                <mat-error *ngIf="userForm.get('password')?.hasError('minlength')">Das Passwort ist zu kurz. {{userForm.get('password')?.value.length}} von 12 Zeichen erreicht.</mat-error>
            </mat-form-field>
            
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Anrede</mat-label>
                <mat-select formControlName="salutation">
                    <mat-option *ngFor="let option of salutationOptions" [value]="option.value">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Vorname</mat-label>
                <input matInput type="text" formControlName="firstname">
            </mat-form-field>

            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Nachname</mat-label>
                <input matInput type="text" formControlName="lastname">
            </mat-form-field>

            
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Organisation</mat-label>
                <mat-select formControlName="organisation">
                    <mat-option *ngFor="let option of organizationOptions" [value]="option.value">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Deprecated, but maybe customer want to use it later
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <mat-label>Angezeigter Name</mat-label>
                <mat-select formControlName="displayName">
                    <mat-option [value]="userForm.get('firstname')?.value+' '+userForm.get('lastname')?.value">
                        {{userForm.get('firstname')?.value}} {{userForm.get('lastname')?.value}}
                    </mat-option>
                    <mat-option 
                        [value]="(userForm.get('salutation')?.value | salutation)+' '+userForm.get('firstname')?.value+' '+userForm.get('lastname')?.value">
                        {{userForm.get('salutation')?.value | salutation}} {{userForm.get('firstname')?.value}} {{userForm.get('lastname')?.value}}
                    </mat-option>
                    <mat-option [value]="userForm.get('username')?.value">
                        {{userForm.get('username')?.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            -->

        </mat-card>

        <button *ngIf="!isAddMode && (user.userStatus === 'LOCKED' || user.userStatus === 'BLOCKED')" mat-button color="primary"
                (click)="updateUserStatus('ACTIVE')">
            <mat-icon class="mr-2">person</mat-icon>Nutzer entsperren
        </button>

        <button *ngIf="!isAddMode && user.userStatus === 'ACTIVE'" mat-button color="warn"
                (click)="updateUserStatus('LOCKED')">
            <mat-icon class="mr-2">person_off</mat-icon>Nutzer sperren
        </button>

    </mat-dialog-content>

    <mat-dialog-actions align="start" class="bg-[#FFFFFF]">
        <button type="button" mat-button mat-dialog-close>Schließen</button>
        <span class="flex-1"></span>
        <button type="submit" mat-flat-button [color]="'primary'" [disabled]="isLoading">Speichern</button>
    </mat-dialog-actions>

</form>
