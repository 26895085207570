import { Pipe, PipeTransform } from '@angular/core';
import { Nationality } from '@shared/enums/nationality.enum';

@Pipe({
	name: 'nationality'
})
export class NationalityPipe implements PipeTransform {

	transform(value: string): string {
		const translation = Nationality[value as keyof typeof Nationality];
		return translation ? translation : value;
	}

}
