<form [formGroup]="clientUidGenerator" class="grid grid-cols-2 gap-4">

    <mat-form-field subscriptSizing="dynamic">
        <mat-label>Erster Vorname der Mutter</mat-label>
        <input matInput type="text" [maxLength]="1" formControlName="firstNameMother">
        <mat-hint>1. Buchstabe</mat-hint>
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic">
        <mat-label>Aktueller Nachname der Mutter</mat-label>
        <input matInput type="text" [maxLength]="1" formControlName="lastNameMother">
        <mat-hint>1. Buchstabe</mat-hint>
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic">
        <mat-label>Kalendertag des eigenen Geburtstages</mat-label>
        <input matInput type="text" [maxLength]="2" [minLength]="2" formControlName="calendarDay">
        <mat-hint>01-31 (2 Stellen)</mat-hint>
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic">
        <mat-label>Eigenes Geburtsjahr</mat-label>
        <input matInput type="text" [maxLength]="2" [minLength]="2" formControlName="yearOfBirth">
        <mat-hint>Nur die letzten 2 Stellen</mat-hint>
    </mat-form-field>

</form>