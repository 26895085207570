import { Component, OnInit } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { environment } from '@environments/environment';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	api: any
	guiVersion: string = 'unknown'

	constructor(
		private apiService: ApiService
	){}

	ngOnInit(): void {
		this.apiService.getVersion().subscribe((data: any) => this.api = data)
		this.guiVersion = environment.appVersion
	}


}
