<form [formGroup]="clientForm" (submit)="onSubmit()" class="bg-[#F1F1F1]">

    <h1 mat-dialog-title class="!my-4 !inline-flex justify-between w-full !h-full">
        {{clientData ? 'Klient*in bearbeiten' : 'Neue*n Klient*in anlegen'}}
        <span class="flex-1"></span>
        {{clientForm.get('clientUid')?.value}}
    </h1>

    <mat-dialog-content class="mat-typography !max-h-[76vh]">

        <mat-tab-group animationDuration="0ms" class="client-new-tab mat-elevation-z1">

            <mat-tab *ngIf="!clientData" label="Neue UID">

                <mat-card class="p-8">
                    
                    <app-client-uid-generator (clientUid)="onClientUidChange($event)"></app-client-uid-generator>
                    
                    <mat-form-field class="w-full mt-8" subscriptSizing="dynamic">
                        <mat-label>Neue*n Klient*in UID</mat-label>
                        <input matInput type="text" formControlName="clientUid" readonly>
                    </mat-form-field>

                </mat-card>

            </mat-tab>

            <mat-tab label="Konsumbezogene Daten">

                <mat-card class="p-8">

                    <div class="grid grid-cols-2 gap-4">

                        <div class="col-span-2">
                            <label>Jemals konsum- oder suchtbezogene Hilfe in Anspruch genommen?</label><br>
                            <mat-radio-group aria-label="Addiction Care" formControlName="hadAddictionCare" color="primary">
                                <mat-radio-button [value]="true" class="mr-5">Ja</mat-radio-button>
                                <mat-radio-button [value]="false" class="mr-5">Nein</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <!--
                        <div class="col-span-2">
                            <label>Bereits Einrichtungen im Rahmen des Berliner Drugchecking-Projektes genutzt?</label><br>
                            <mat-radio-group aria-label="Addiction Care" formControlName="hadDrugcheckingCare" color="primary">
                                <mat-radio-button [value]="true" class="mr-5">Ja</mat-radio-button>
                                <mat-radio-button [value]="false" class="mr-5">Nein</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        -->

                        <div class="col-span-2">
                            <label>Informationen zu Substanzen bislang wahrgenommen über:</label>
                            <section class="grid grid-cols-3 gap-0">
                                <mat-checkbox color="primary" formControlName="hasInfoFromStand">
                                    Infostände (z.B. in Clubs)
                                </mat-checkbox>
                                <mat-checkbox color="primary" formControlName="hasInfoFromInternet">
                                    Internet
                                </mat-checkbox>
                                <mat-checkbox color="primary" formControlName="hasInfoFromPrevention">
                                    Andere Präventionsmaßnahme
                                </mat-checkbox>
                                <mat-checkbox color="primary" formControlName="hasInfoFromMedia">
                                    Andere Medien
                                </mat-checkbox>
                                <mat-checkbox color="primary" formControlName="hasInfoFromFriends">
                                    Freunde / Bekannte
                                </mat-checkbox>
                                <mat-checkbox color="primary" formControlName="hasNoneInfo">
                                    Bislang nicht
                                </mat-checkbox>
                                <mat-checkbox color="primary" #hasInfoOther>Anderes</mat-checkbox>
                            </section>
                            <mat-form-field *ngIf="hasInfoOther.checked" class="w-full mt-2" subscriptSizing="dynamic">
                                <input matInput type="text" placeholder="Andere Informationsquelle eintragen" formControlName="hasInfoFromOther">
                            </mat-form-field>
                        </div>

                        <label class="-mb-4">Konsumierte Substanzen:</label>

                        <mat-tab-group class="col-span-2 substance-tab"
                                       [selectedIndex]="selected.value"
                                       (selectedIndexChange)="selected.setValue($event)"
                                       formArrayName="drugUses"
                                       animationDuration="0ms">

                            <mat-tab *ngFor="let tab of tabsDrugUse; let index = index" [label]="tab+' '+(index+1)" [formGroupName]="index" class=" !min-h-[60vh]">

                                <div class="grid grid-cols-2 gap-4 py-4 pr-[1px]">

                                    <mat-form-field class="w-full" subscriptSizing="dynamic">
                                        <mat-label>Name</mat-label>
                                        <input matInput type="text" [maxlength]="100" formControlName="name">
                                    </mat-form-field>
            
                                    <mat-form-field class="w-full" subscriptSizing="dynamic">
                                        <mat-label>Überwiegende Konsumart</mat-label>
                                        <mat-select formControlName="consumptionType">
                                            <mat-option *ngFor="let option of optionsConsumptionType" [value]="option.value">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="w-full" subscriptSizing="dynamic">
                                        <mat-label>Anzahl der Konsumtage</mat-label>
                                        <input matInput type="text" formControlName="numberDays">
                                        <mat-hint>In den letzten 30 Tagen</mat-hint>
                                    </mat-form-field>
                        
                                    <mat-form-field class="w-full" subscriptSizing="dynamic">
                                        <mat-label>Alter bei Konsumbeginn</mat-label>
                                        <input matInput type="text" formControlName="startingAge">
                                        <mat-hint></mat-hint>
                                    </mat-form-field>   
                                    
                                </div>
                            
                                <button mat-button
                                        type="button"
                                        color="warn"
                                        class="uppercase"
                                        [disabled]="tabsDrugUse.length === 0"
                                        (click)="removeDrugUse(index)">
                                        <mat-icon>close</mat-icon> Substanz {{index + 1}} löschen
                                </button>

                            </mat-tab>

                        </mat-tab-group>

                        <button [disabled]="tabsDrugUse.length >= 5"
                                mat-button
                                color="primary"
                                type="button"
                                class="uppercase col-span-2 justify-self-end"
                                (click)="addDrugUse()">
                                <mat-icon>add</mat-icon> Neue Substanz hinzufügen
                        </button>

                    </div>   
                    
                </mat-card>
            
            </mat-tab>

            <mat-tab label="Soziodemographische Daten" [ngClass]="{ 'invalid': clientForm.invalid && clientForm.touched }"> 

                <mat-card class="p-8">

                    <div class="grid grid-cols-2 gap-4">
                    
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-label> Beratungsbeginn </mat-label>
                            <input matInput 
                                   [matDatepicker]="firstConsultationDatePicker" 
                                   formControlName="firstConsultationDate"
                                   (dateChange)="formatOutput('firstConsultationDate', $event.value, 'YYYY-MM-DD')" 
                                   [max]="today">
                            <mat-datepicker-toggle matIconSuffix [for]="firstConsultationDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #firstConsultationDatePicker></mat-datepicker>
                        </mat-form-field>
                    
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-label> Alter bei Beratungsbeginn </mat-label>
                            <input matInput type="text" formControlName="age">
                        </mat-form-field>
                    
                        <div class="col-span-2">
                            <label [ngClass]="{ 'invalid': clientForm.get('gender')?.invalid && clientForm.get('gender')?.touched }">
                                Geschlecht*
                            </label><br>
                            <mat-radio-group aria-label="Gender" formControlName="gender" color="primary">
                                <mat-radio-button [value]="'MALE'" class="mr-5">männlich</mat-radio-button>
                                <mat-radio-button [value]="'FEMALE'" class="mr-5">weiblich</mat-radio-button>
                                <mat-radio-button [value]="'DIVERSE'">divers</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    
                        <app-autocomplete-nationalities
                            nationalityFormControlName="nationality" 
                            nationalityLabel="1. Staatsangehörigkeit">
                        </app-autocomplete-nationalities>
                    
                        <app-autocomplete-nationalities 
                            nationalityFormControlName="nationality2" 
                            nationalityLabel="2. Staatsangehörigkeit">
                        </app-autocomplete-nationalities>
                    
                        <mat-form-field class="col-span-2" subscriptSizing="dynamic">
                            <mat-label> Migrationshintergrund </mat-label>
                            <mat-select formControlName="migrationBackground">
                                <mat-option *ngFor="let option of optionsMigrationBackground" [value]="option.value">{{option.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <div>
                            <label> Wohnort </label><br>
                            <mat-radio-group aria-label="City" formControlName="location" color="primary">
                                <mat-radio-button [value]="'BERLIN'" class="mr-5">Berlin</mat-radio-button>
                                <mat-radio-button [value]="'OTHER'" class="mr-5">Anderswo</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <mat-form-field subscriptSizing="dynamic" class="mt-2" *ngIf="clientForm.get('location')?.value === 'BERLIN'">
                            <mat-label> Postleitzahl in Berlin </mat-label>
                            <input matInput type="text" formControlName="postalCode">
                        </mat-form-field>
                    
                        <mat-form-field class="col-span-2" subscriptSizing="dynamic">
                            <mat-label> Schulabschluss </mat-label>
                            <mat-select formControlName="graduation">
                                <mat-option *ngFor="let option of optionsGraduation" [value]="option.value">{{option.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field class="col-span-2" subscriptSizing="dynamic">
                            <mat-label> Erwerbstätigkeit </mat-label>
                            <mat-select formControlName="employment">
                                <mat-option *ngFor="let option of optionsEmployment" [value]="option.value">{{option.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                </mat-card>

            </mat-tab>

        </mat-tab-group>
        
    </mat-dialog-content>

</form>