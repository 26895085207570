export enum Nationality {
    AFG = 'Afghanistan', 
    AGO = 'Angola',
    ALB = 'Albanien',
    ARE = 'Vereinigte Arabische Emirate',
    ARG = 'Argentinien',
    ARM = 'Armenien', 
    AUS = 'Australien', 
    AUT = 'Österreich',
    AZE = 'Aserbeidschan',
    BDI = 'Burundi', 
    BEL = 'Belgien', 
    BEN = 'Benin', 
    BFA = 'Burkina faso', 
    BGD = 'Bangladesch', 
    BGR = 'Bulgarien', 
    BHR = 'Bahrain', 
    BIH = 'Bosnien-Herzegowina', 
    BLR = 'Weißrussland',
    BLZ = 'Belize', 
    BMU = 'Bermudas', 
    BOL = 'Bolivien', 
    BRA = 'Brasilien',
    BTN = 'Bhutan', 
    BWA = 'Botswana', 
    CAF = 'Zentralafrikanische Republik',
    CAN = 'Canada', 
    CHE = 'Schweiz', 
    CHL = 'Chile', 
    CHN = 'China', 
    CIV = 'Elfenbeinküste (Cote d´Ivoir)',
    CMR = 'Kamerun',
    COD = 'Republik Kongo',
    COG = 'Kongo',
    COL = 'Kolumbien',
    CRI = 'Costa Rica',
    CUB = 'Cuba', 
    CYP = 'Zypern',
    CZE = 'Tschechien',
    DEU = 'Deutschland',
    DJI = 'Djibouti', 
    DNK = 'Dänemark', 
    DOM = 'Dominikanische Republik',
    DZA = 'Algerien', 
    ECU = 'Ecuador', 
    EGY = 'Ägypten',
    ERI = 'Eritrea', 
    ESP = 'Spanien', 
    EST = 'Estland',
    ETH = 'Äthiopien',
    FIN = 'Finnland', 
    FRA = 'Frankreich', 
    GAB = 'Gabun', 
    GBR = 'Großbritannien',
    GEO = 'Georgien', 
    GHA = 'Ghana', 
    GIN = 'Guinea', 
    GLP = 'Guadeloupe', 
    GMB = 'Gambia', 
    GNQ = 'Äquatorial Guinea', 
    GRC = 'Griechenland',
    GRL = 'Grönland',
    GTM = 'Guatemala', 
    GUY = 'Guyana', 
    HND = 'Honduras', 
    HRV = 'Kroatien', 
    HTI = 'Haiti', 
    HUN = 'Ungarn',
    IDN = 'Indonesien', 
    IND = 'Indien', 
    IRL = 'Irland', 
    IRN = 'Iran',
    IRQ = 'Irak', 
    ISL = 'Island', 
    ISR = 'Israel', 
    ITA = 'Italien', 
    JAM = 'Jamaica', 
    JOR = 'Jordanien', 
    JPN = 'Japan', 
    KAZ = 'Kasachstan', 
    KEN = 'Kenia', 
    KGZ = 'Kirgisistan', 
    KHM = 'Kambodscha', 
    KOR = 'Südkorea', 
    KOS = 'Kosovo',
    KWT = 'Kuwait', 
    LAO = 'Laos', 
    LBN = 'Libanon', 
    LBR = 'Liberia', 
    LBY = 'Libyen',
    LIE = 'Liechtenstein', 
    LKA = 'Sri Lanka',
    LSO = 'Lesotho', 
    LTU = 'Litauen',
    LUX = 'Luxembourg', 
    LVA = 'Lettland',
    MAR = 'Marokko', 
    MDA = 'Moldawien',
    MDG = 'Madagaskar', 
    MEX = 'Mexico', 
    MKD = 'Mazedonien',
    MLI = 'Mali', 
    MLT = 'Malta', 
    MNE = 'Montenegro',
    MNG = 'Mongolei', 
    MOZ = 'Mozambique', 
    MRT = 'Mauretanien', 
    MWI = 'Malawi', 
    MYS = 'Malaysia', 
    NAM = 'Namibia', 
    NER = 'Niger', 
    NGA = 'Nigeria', 
    NIC = 'Nicaragua', 
    NLD = 'Niederlande', 
    NOR = 'Norwegen', 
    NPL = 'Nepal', 
    NZL = 'Neuseeland',
    OMN = 'Oman', 
    PAK = 'Pakistan', 
    PAN = 'Panama', 
    PER = 'Peru', 
    PHL = 'Philippinen', 
    POL = 'Polen', 
    PRI = 'Puerto rico', 
    PRK = 'Nordkorea',
    PRT = 'Portugal', 
    PRY = 'Paraguay', 
    QAT = 'Qatar', 
    ROM = 'Rumänien', 
    RUS = 'Russische Föderation', 
    RWA = 'Ruanda', 
    SAU = 'Saudi Arabien',
    SDN = 'Sudan', 
    SEN = 'Senegal', 
    SGP = 'Singapur', 
    SLE = 'Sierra Leone',
    SLV = 'El Salvador',
    SOM = 'Somalia', 
    SRB = 'Serbien',
    SUR = 'Surinam', 
    SVK = 'Slowakei',
    SVN = 'Slowenien', 
    SWE = 'Schweden', 
    SYR = 'Syrien',
    TCD = 'Tschad', 
    TGO = 'Togo', 
    THA = 'Thailand', 
    TJK = 'Tadschikistan', 
    TKM = 'Turkmenistan', 
    TMP = 'Osttimor', 
    TUN = 'Tunesien', 
    TUR = 'Türkei', 
    TWN = 'Taiwan', 
    TZA = 'Tansania', 
    UGA = 'Uganda', 
    UKR = 'Ukraine', 
    URY = 'Uruguay', 
    USA = 'Vereinigte Staaten von Amerika',
    UZB = 'Usbekistan', 
    VEN = 'Venezuela', 
    VNM = 'Vietnam', 
    YEM = 'Jemen', 
    ZAF = 'Südafrika',
    ZMB = 'Sambia', 
    ZWE = 'Zimbabwe', 
    XXX = 'Andere Staaten',
    YYY = 'Staatenlos'
}

