import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { User } from '@shared/models/user.interface';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(
        private http: HttpClient
    ) { }

    /*validate(interceptor: boolean = true) {
        const headers = interceptor ? new HttpHeaders() : new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get(`${environment.apiUrl}/v2/users/me`, { headers })
    }*/

    login(username: string, password: string): Observable<User> {
        return this.http.post<User>(`${environment.apiUrl}/v2/auth/login`, {
            'username': username,
            'password': password
        })
    }

	logout() {
        return this.http.post(`${environment.apiUrl}/v2/auth/logout`, {})
    }
}
