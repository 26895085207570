import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
	selector: 'app-textblock-sheet',
	templateUrl: './textblock-sheet.component.html',
	styleUrls: ['./textblock-sheet.component.scss']
})
export class TextblockSheetComponent {

	constructor(
		private bottomSheetRef: MatBottomSheetRef<TextblockSheetComponent>,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: any
	) {}

	selectTextblock(textblock: string): void {
		this.bottomSheetRef.dismiss(textblock);
	}

}
