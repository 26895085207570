<ol *ngIf="sampleStatus" class="flex items-center w-50 text-gray-500 m-0 pl-4">
  <li class="flex md:w-full items-center mr-5" *ngFor="let status of statuses; index as i">
    <div class="flex items-center whitespace-nowrap after:content-['/'] sm:after:hidden after:mx-2">
      <span class="w-6 h-6 border rounded-full flex justify-center items-center mr-3 text-[0.8rem] lg:w-6 lg:h-6" [ngClass]="getStepClass(status)">
        <svg *ngIf="isStepReached(status)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
        </svg>
        <span *ngIf="!isStepReached(status)">{{ i + 1 }}</span>
      </span>
      {{ status | sampleStatus }}
    </div>
  </li>
</ol>