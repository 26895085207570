<form class="flex flex-col h-full bg-[#F1F1F1]">

    <div class="flex items-center justify-between">
        <h1 mat-dialog-title>
            {{data.isAddMode ? 'Laborergebnis erfassen' : 'Laborergebnis bearbeiten'}}
        </h1>
        <button type="button" mat-icon-button color="warn" [disabled]="!currentSample" (click)="updateSampleStatus('INVALID')" class="uppercase mr-[16px] mb-[9px]" matTooltip="Probe als ungültig makieren">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder-x" viewBox="0 0 16 16">
                <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181L15.546 8H14.54l.265-2.91A1 1 0 0 0 13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91H9v1H2.826a2 2 0 0 1-1.991-1.819l-.637-7a2 2 0 0 1 .342-1.31zm6.339-1.577A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139q.323-.119.684-.12h5.396z"/>
                <path d="M11.854 10.146a.5.5 0 0 0-.707.708L12.293 12l-1.146 1.146a.5.5 0 0 0 .707.708L13 12.707l1.146 1.147a.5.5 0 0 0 .708-.708L13.707 12l1.147-1.146a.5.5 0 0 0-.707-.708L13 11.293z"/>
            </svg>
        </button>
    </div>

    <mat-dialog-content class="mat-typography">

        <div class="flex flex-row gap-4 h-full">

            <div class="w-1/3 flex flex-col">
                
                <mat-card [formGroup]="sampleForm">
                    <mat-card-content>

                        <app-sample-number-search-form 
                            (result)="sampleSearchResult($event)"
                            (valid)="sampleIsValid($event)"
                            [validStatus]="['PENDING']"
                            [invalidStatus]="['ANALYZED', 'REPORTED', 'INVALID', 'ASSESSED']"
                            [sampleNumberCtrl]="sampleNumberCtrl">
                            <mat-icon class="!text-dc-blue-500 !mr-4" *ngIf="isValidSampleNumber">verified</mat-icon>
                        </app-sample-number-search-form>

                        <table *ngIf="currentSample" class="w-full mt-4 table-fixed border border-solid border-[#9e9e9e] rounded p-4">
                            <thead class="text-left">
                                <tr>
                                    <th>Abgegeben als</th>
                                    <th>Form</th>
                                    <th>Farbe</th>
                                    <th>Logo</th>
                                </tr>
                            </thead>
                            <tbody class="text-left">
                                <td>{{currentSample.name || 'k.A.'}}</td>
                                <td>{{currentSample.sampleForm || 'k.A.'}}</td>
                                <td>{{currentSample.sampleColor || 'k.A.'}}</td>
                                <td>{{currentSample.sampleLogo || 'k.A.'}}</td>
                            </tbody>
                            <tfoot>
                                <td colspan="4"><b>Bemerkungen:</b> {{currentSample.notes || 'k.A.'}}</td>
                            </tfoot>
                        </table>

                    </mat-card-content>
                </mat-card>

                <mat-card [formGroup]="analysisForm"  class="mt-4 flex-grow overflow-y-auto">

                    <mat-card-header class="mb-2 flex items-center">
                        <mat-card-title>Analyse</mat-card-title>
                        <div class="flex items-center">
                            <button type="button" [disabled]="analysisForm.invalid" class="uppercase" mat-icon-button color="primary" (click)="onAnalysisSubmit()" matTooltip="Analyse speichern">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy2-fill" viewBox="0 0 16 16">
                                    <path d="M12 2h-2v3h2z"/>
                                    <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v13A1.5 1.5 0 0 0 1.5 16h13a1.5 1.5 0 0 0 1.5-1.5V2.914a1.5 1.5 0 0 0-.44-1.06L14.147.439A1.5 1.5 0 0 0 13.086 0zM4 6a1 1 0 0 1-1-1V1h10v4a1 1 0 0 1-1 1zM3 9h10a1 1 0 0 1 1 1v5H2v-5a1 1 0 0 1 1-1"/>
                                </svg>
                            </button>
                            <button type="button" class="uppercase ml-2" mat-icon-button color="warn" (click)="deleteAnalysis()" matTooltip="Analyse löschen">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                </svg>
                            </button>
                        </div>
                    </mat-card-header>

                    <div class="mt-2 p-4">
                        <mat-form-field class="w-[23.5%] mr-[2%]" subscriptSizing="dynamic">
                            <mat-label>Gewicht</mat-label>
                            <input matInput type="text" 
                                formControlName="weight">
                            <span matSuffix class="pr-4">mg</span>
                        </mat-form-field>

                        <mat-form-field class="w-[23.5%] mr-[2%]" subscriptSizing="dynamic">
                            <mat-label>Breite</mat-label>
                            <input matInput type="text" formControlName="width">
                            <span matSuffix class="pr-4">mm</span>
                        </mat-form-field>

                        <mat-form-field class="w-[23.5%] mr-[2%]" subscriptSizing="dynamic">
                            <mat-label>Höhe</mat-label>
                            <input matInput type="text" formControlName="height">
                            <span matSuffix class="pr-4">mm</span>
                        </mat-form-field>

                        <mat-form-field class="w-[23.5%]" subscriptSizing="dynamic">
                            <mat-label>Tiefe</mat-label>
                            <input matInput type="text" formControlName="depth">
                            <span matSuffix class="pr-4">mm</span>
                        </mat-form-field>
                    </div>

                    <mat-card-content class="mt-2 overflow-hidden overflow-y-auto">
                        <p>Quantitatives Ergebnis:</p>
                        <ul formArrayName="substances" class="list-none p-0">

                            <li *ngFor="let substance of substances.controls; let i = index" [formGroupName]="i" class="mb-0">

                                <mat-form-field class="w-[48%] mr-2" subscriptSizing="dynamic">
                                    <mat-label>Name Substanz {{i + 1}}</mat-label>
                                    <input type="text"
                                        placeholder="Substanz"
                                        aria-label="Substanz"
                                        matInput
                                        formControlName="name"
                                        [matAutocomplete]="autoSubstanceName"
                                        [maxlength]="100">
                                    <mat-autocomplete autoActiveFirstOption #autoSubstanceName="matAutocomplete">
                                        <mat-option *ngFor="let name of filteredOptionsSubstanceName[i] | async" [value]="name">
                                            {{name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                                <mat-form-field class="w-[20%] mr-2">
                                    <mat-label>Menge</mat-label>
                                    <input matInput type="text" formControlName="amount">
                                    <mat-error class="w-[12rem]" *ngIf="substances.controls[i].get('amount')?.hasError('pattern')">Ungültiges Format.</mat-error>
                                </mat-form-field>
                                
                                <mat-form-field class="w-[20%]" subscriptSizing="dynamic">
                                    <mat-label>Einheit</mat-label>
                                    <input type="text"
                                        placeholder="Maßeinheit"
                                        aria-label="Maßeinheit"
                                        matInput
                                        formControlName="unit"
                                        [matAutocomplete]="autoUnit"
                                        [maxlength]="100">
                                    <mat-autocomplete autoActiveFirstOption #autoUnit="matAutocomplete">
                                        <mat-option *ngFor="let unit of filteredOptionsUnit[i] | async" [value]="unit">
                                            {{unit}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                                <button class="align-middle" mat-icon-button color="warn" (click)="removeSubstance(i)">
                                    <mat-icon>close</mat-icon>
                                </button>

                            </li>

                        </ul>
                    </mat-card-content>

                    <mat-card-actions>
                        <button type="button" [disabled]="substances.length >= 20" class="uppercase" mat-button (click)="addSubstance()">
                            <mat-icon>add</mat-icon> Substanz hinzufügen ({{substances.length}}/20)
                        </button>
                    </mat-card-actions>

                </mat-card>
            
            </div>

            <div class="w-2/3 flex flex-col">

                <mat-card [formGroup]="assessmentForm" class="flex-grow">
                    
                    <mat-card-header class="mb-4 flex items-center">
                        <mat-card-title>
                            Bewertung
                            <mat-slide-toggle color="primary" formControlName="published" (change)="onTogglePublished($event)" class="ml-3">öffentlich</mat-slide-toggle>
                        </mat-card-title>
                        <div class="flex items-center">
                            <button type="button" [disabled]="assessmentForm.invalid" mat-icon-button color="primary" (click)="onAssessmentSubmit()" matTooltip="Bewertung speichern">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy2-fill" viewBox="0 0 16 16">
                                    <path d="M12 2h-2v3h2z"/>
                                    <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v13A1.5 1.5 0 0 0 1.5 16h13a1.5 1.5 0 0 0 1.5-1.5V2.914a1.5 1.5 0 0 0-.44-1.06L14.147.439A1.5 1.5 0 0 0 13.086 0zM4 6a1 1 0 0 1-1-1V1h10v4a1 1 0 0 1-1 1zM3 9h10a1 1 0 0 1 1 1v5H2v-5a1 1 0 0 1 1-1"/>
                                </svg>
                            </button>
                            <button type="button" class="ml-2" mat-icon-button color="warn" (click)="deleteAssessment()" matTooltip="Bewertung löschen">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                  </svg>
                            </button>
                        </div>
                    </mat-card-header>

                    <mat-card-content class="!flex gap-5 mt-4">

                            <div class="w-1/3">

                                <mat-accordion>
                                    <mat-expansion-panel class="mat-elevation-z0 border border-solid border-[#9e9e9e] rounded">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>Risikobewertung</mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <mat-radio-group aria-label="Wähle eine Option" formControlName="alertType">
                                            <mat-radio-button color="primary" [value]="'none'">Unauffällig</mat-radio-button>
                                            <mat-radio-button color="primary" [value]="'warning'">Warnung</mat-radio-button>
                                            <mat-radio-button color="primary" [value]="'alarm'">ALARM!</mat-radio-button>
                                        </mat-radio-group>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel class="mat-elevation-z0 border border-solid border-[#9e9e9e] rounded">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>Eigenschaften</mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <mat-checkbox color="primary" formControlName="contaminated">verunreinigt</mat-checkbox>
                                        <mat-checkbox color="primary" formControlName="highlyDosed" class="mx-4">hochdosiert</mat-checkbox>
                                        <mat-checkbox color="primary" formControlName="misdeclared">falsch deklariert</mat-checkbox>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel class="mat-elevation-z0 border border-solid border-[#9e9e9e] rounded">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>Fotos</mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <app-image-upload-form (images)="onImageListChange($event)" [existingImages]="imageList"></app-image-upload-form>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel class="mat-elevation-z0 border border-solid border-[#9e9e9e] rounded" *ngIf="assessmentForm.get('published')?.value">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>Öffentliche Bewertung</mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <mat-form-field class="w-full">
                                            <mat-label>Überschrift</mat-label>
                                            <input matInput type="text" formControlName="publicHeadline">
                                        </mat-form-field>
                                        <editor
                                            formControlName="publicEvaluation"
                                            [inline]="false"
                                            [init]="tinymceConfigPublic">
                                        </editor>
                                    </mat-expansion-panel>
                                </mat-accordion>

                            </div>
                        
                            <editor *ngIf="textBlocksLoaded"
                                class="w-2/3"
                                formControlName="description"
                                [inline]="false"
                                [init]="tinymceConfig">
                            </editor>
                            
                    </mat-card-content>                

                </mat-card>

            </div>

        </div>

    </mat-dialog-content>

    <mat-dialog-actions align="start" class="bg-[#FFFFFF]">        
        <app-sample-status-stepper *ngIf="currentSample" [sampleStatus]="currentSample.sampleStatus"></app-sample-status-stepper>
        <div class="flex-1"></div>
        <button type="button" mat-flat-button (click)="closeDialog()">Schließen</button>
    </mat-dialog-actions>


</form>