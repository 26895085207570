import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'alert'
})
export class AlertPipe implements PipeTransform {

	transform(value: string): string {
		switch (value) {
			case 'REDALERT':
				return 'ALARM!';
			case 'ALERT':
				return 'Warnung';
			case 'NONE':
				return 'Unauffällig';
			default:
				return '';
		}
	}

}
