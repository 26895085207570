import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Role } from '@shared/enums/role.enum';
import { AuthState } from 'app/auth/states/auth.state';
import { Logout } from 'app/auth/states/auth.action';


@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {

	constructor(
        private store: Store,
        private router: Router
    ) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		  
		const allowedRoles = route.data['allowedRoles'] as string[] || [
			Role.ADMIN,
			Role.CONSULTANT,
			Role.PHARMACIST,
			Role.LABORATORY
		];
		
		const userRole: string = this.store.selectSnapshot(AuthState.getCurrentUserRole) || '';

		if ( !allowedRoles.includes(userRole) ) {

			this.store.dispatch([new Logout]);
			
			this.router.navigate(['/'], { queryParams: { redirect_to: state.url }});
			return false;
		}
		
		return true;
	}

}
