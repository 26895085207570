<app-toolbar></app-toolbar>

<div class="flex flex-col h-auto p-5">

	<div class="flex flex-row gap-4">

		<div class="w-1/4">

			<mat-card>
				<mat-card-header class="flex flex-col">
					<mat-card-title class="!inline-flex !items-center">
                        <span class="material-symbols-outlined mr-2">edit_note </span>Textbaustein anlegen
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="!inline-flex flex-col mt-4">
                    <button mat-flat-button color="primary" (click)="openTextblockDialog()"><mat-icon>add</mat-icon> Hinzufügen</button>
				</mat-card-content>
			</mat-card>
		</div>

		<div class="w-full">

			<mat-card>
				<mat-card-header class="mb-5">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">clinical_notes</span> Alle Textbausteine
					</mat-card-title>
				</mat-card-header>

				<mat-card-content class="!flex !flex-col">

					<table mat-table  [dataSource]="dataSource" class="mat-elevation-z0">

                        <!-- Edit Column -->
                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef> Bearbeiten </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="primary" (click)="openTextblockDialog(element.id)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                    
                        <!-- Description Column -->
                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef> Beschreibung </th>
                            <td mat-cell *matCellDef="let element">{{element.description}}</td>
                        </ng-container>
                    
                        <!-- Text Column -->
                        <ng-container matColumnDef="text">
                            <th mat-header-cell *matHeaderCellDef> Text </th>
                            <td mat-cell *matCellDef="let element" class="max-w-lg !p-4" [innerHtml]="element.text | truncate: 250 | sanitizeHtml"></td>
                        </ng-container>
                    
                        <!-- Creator Column -->
                        <ng-container matColumnDef="creator">
                            <th mat-header-cell *matHeaderCellDef> Ersteller </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.user.username}}
                            </td>
                        </ng-container>

                        <!-- Delete Column -->
                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef> Löschen </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button color="warn" (click)="deleteTextblock(element)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                    
                        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>
                    
                         <!-- Row shown when there is no matching data.  -->
                         <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell p-8 text-center" colspan="5">
                                <p>Keine Textbausteine gefunden.</p>
                                <button type="button" mat-raised-button class="uppercase" (click)="openTextblockDialog()">
									<mat-icon>add</mat-icon> Textbaustein Hinzufügen
								</button>
                            </td>
                        </tr>

                    </table>

                    <mat-paginator class="mat-elevation-z0 mt-4" 
                        [length]="totalElements"
                        [pageSize]="pageSize" 
                        [pageSizeOptions]="[5, 10, 25, 100]"
                        (page)="pageChanged($event)" 
                        showFirstLastButtons>
                    </mat-paginator>

				</mat-card-content>
			</mat-card>

			<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

		</div>

	</div>

</div>