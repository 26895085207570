<h1 mat-dialog-title class="!mb-0 !pt-3 !inline-flex items-center">
    <span class="material-symbols-outlined mr-1">build</span> Build
</h1>

<mat-dialog-content class="mat-typography bg-[#F1F1F1] !pt-4">

    <mat-card>

        <mat-card-content>
            <code class="text-sm flex flex-col !text-[#000000]">
                <span>🔬 API Version {{api?.version}}</span>
                <span>💻 GUI Version {{guiVersion}}</span>
                <span>🌍 URL: {{api?.basepath}}</span> 
            </code>
        </mat-card-content>

    </mat-card>

    <div class="w-full text-center mt-4 text-[#000000]">Made with ❤️ in Berlin.</div>

</mat-dialog-content>


<mat-dialog-actions align="end" class="bg-[#FFFFFF]">
    <button type="button" mat-button mat-dialog-close cdkFocusInitial>Schließen</button>
</mat-dialog-actions>