import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@core/guards/auth.guard'
import { RoleGuard } from '@core/guards/role.guard';

import { LoginComponent } from './auth/login/login.component';
import { SittingComponent } from './admin/sitting/sitting.component';
import { ConsultationComponent } from './admin/sitting/consultation/consultation.component';
import { SampleComponent } from './admin/sitting/consultation/sample/sample.component';
import { FeedbackClientComponent } from './admin/feedback/feedback-client/feedback-client.component';
import { FeedbackListComponent } from './admin/feedback/feedback-list/feedback-list.component';
import { LabComponent } from './admin/lab/lab.component';
import { TextblockComponent } from './admin/textblock/textblock.component';
import { ClientComponent } from './admin/client/client.component';
import { UserComponent } from './admin/user/user.component';
import { Role } from '@shared/enums/role.enum';


const routes: Routes = [
	{
		path: '',
		component: LoginComponent
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'sitting',
		component: SittingComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	},
	{
		path: 'sitting/consultation',
		component: ConsultationComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	},
	{
		path: 'sitting/consultation/sample',
		component: SampleComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	},
	{
		path: 'feedback/client',
		component: FeedbackClientComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	},
	{
		path: 'feedback/list',
		component: FeedbackListComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	},
	{
		path: 'laboratory',
		component: LabComponent,
		canActivate: [AuthGuard],
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	},
	{
		path: 'clients',
		component: ClientComponent,
		canActivate: [AuthGuard, RoleGuard],
		data: { allowedRoles: [Role.ADMIN, Role.CONSULTANT]},
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	},
	{
		path: 'textblock',
		component: TextblockComponent,
		canActivate: [AuthGuard, RoleGuard],
		data: { allowedRoles: [Role.ADMIN, Role.PHARMACIST]},
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	},
	{
		path: 'users',
		component: UserComponent,
		canActivate: [AuthGuard, RoleGuard],
		data: { allowedRoles: [Role.ADMIN] },
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
	},
	{
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
