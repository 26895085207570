import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '@core/services/api.service';
import { TextblockDialogComponent } from './textblock-dialog/textblock-dialog.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';


@Component({
	selector: 'app-textblock',
	templateUrl: './textblock.component.html',
	styleUrls: ['./textblock.component.scss']
})
export class TextblockComponent implements OnInit {

	/** Array of column names to display in the table */
	displayedColumns: string[] = ['edit', 'description', 'text', 'creator', 'delete'];

	/** Data source for the table */
	dataSource = new MatTableDataSource();

	/** Total number of elements in the data source. */
	totalElements: number = 0;

	/** Number of items to display on a page. */
	pageSize: number = 10;

	/** Boolean flag indicating whether a loading indicator should be displayed. */
	isLoading: boolean = false;

	constructor(
		private apiService: ApiService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {
		this.getTextblocks({ size: this.pageSize, page: 0 });
	}

	/**
	 * Gets all text blocks from the API and sets them as the data source for the table
	 * @returns void
	 * @since 1.0.0
	 */
	getTextblocks(params: any = null): void {
		this.apiService.getTextblocks(params).subscribe({
			next: (data: any) => {
				this.totalElements = data.totalElements
                this.dataSource = new MatTableDataSource(data.content);
                this.isLoading = false;
            },
            error: () => {
                this.isLoading = false;
            }
		});
	}

	/**
	 * Deletes a text block with the given ID from the API and updates the table
	 * @param number id - The ID of the text block to delete
	 * @returns void
	 * @since 1.0.0
	 */
	deleteTextblock(element: any): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '30vw',
            autoFocus: true,
			data: {
				title: `Textbaustein "${element.description}" unwiderruflich löschen?`,
				text: `Sind Sie absolut sicher, dass Sie diesen Textbaustein löschen wollen?`,
				confirmation: null
			}
        });
		dialogRef.afterClosed().subscribe(res => {
			if ( res.data === 'confirmed' ) {
				this.apiService.deleteTextblock(element.id).subscribe(() => {
					// Update the textblock table data
					this.getTextblocks();
					this.snackBar.open(`Textblock "${element.description}" gelöscht.`, 'Ok.', { panelClass: 'success-snack' });
				});
			}	
		});
	}

	/**
	 * Opens a dialog box for adding or editing a text block
	 * @param {number|null} [id=null] - The ID of the text block to edit, or null for adding a new text block
	 * @returns void
	 * @since 1.0.0
	 */
	openTextblockDialog(id: number | null = null): void {
		let dialogRef = this.dialog.open(TextblockDialogComponent, {
			data: { id: id },
			autoFocus: false,
			minWidth: '50vw',
			minHeight: '80vh'
		});

		dialogRef.afterClosed().subscribe(res => {
			// Update the textblock table data
			if (res && res.data === 'success') this.getTextblocks();
		});
	}

	/**
	 * Event handler for when the page in the paginator changes.
	 * @since 1.0.0
	 * @param {any} event - The page change event.
	 * @return void
	 */
	pageChanged(event: any): void {
		this.pageSize = event.pageSize;
		this.getTextblocks({
			size: event.pageSize,
			page: event.pageIndex
		});
	}
	

}
