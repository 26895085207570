import { AfterViewInit, Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ApiService } from '@core/services/api.service';
import { ClientAddEditComponent } from '@shared/components/client-add-edit/client-add-edit.component';


@Component({
	selector: 'start-consultation-dialog',
	templateUrl: './start-consultation-dialog.component.html',
	styleUrls: ['./start-consultation-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class StartConsultationDialogComponent implements AfterViewInit {

	/** Reference to the MatTabGroup component in the template. */
	@ViewChild('tabGroup') tabGroup: MatTabGroup;
	
	/** Reference to the ClientAddEditTabComponent in the template. */
	@ViewChild(ClientAddEditComponent) clientAddEdit: ClientAddEditComponent;

	currentTabIndex : number | null = 0

	isLoading: boolean = false

	constructor(
		private dialogRef: MatDialogRef<StartConsultationDialogComponent>,
		private apiService: ApiService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	/** 
	 * Subscribe to the "selectedIndexChange" event of the "MatTabGroup" component 
	 * to get the current tab index. This is wrapped in the lifecycle hook 
	 * "ngAfterViewInit" to avoid the "ExpressionChangedAfterItHasBeenCheckedError".
	 * @since 1.0.0
	 * @returns void
	 */
	ngAfterViewInit(): void {
		this.tabGroup.selectedIndexChange.subscribe((index) => {
			this.currentTabIndex = index;
		});
	}

	/**
	 * Closes the dialog and returns the ID of the newly created client to the 
	 * calling component.
	 * @since 1.0.0
	 * @param clientId - The ID of the newly created client.
	 * @returns void
	 */
	createConsultation(data: number): void {
		this.dialogRef.close({ data: data })
	}

	/**
	 * Submits the form data to the ApiService to create a new client.
	 * @since 1.0.0
	 * @param formData - The form data containing the client information to create.
	 * @returns void
	 */
	onFormSubmit(formData: any): void {

		this.isLoading = true

		if ( this.data && !this.data.isAddMode ) {

			this.apiService.updateClient(this.data.client.id, formData).subscribe({
				next: (data: any) => {
					this.dialogRef.close({data: data});
					this.isLoading = false
				},
				error: (err) => {
					console.log(err)
					this.isLoading = false
				}
			});

		} else {

			this.apiService.createClient(formData).subscribe({
				next: (data: any) => {
					this.dialogRef.close({data: data});
					this.isLoading = false
				},
				error: (err) => {
					console.log(err)
					this.isLoading = false
				}
			});

		}

	}

}
