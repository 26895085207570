import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApiService } from '@core/services/api.service';
import { environment } from '@environments/environment';
import { LightboxDialogComponent } from '@shared/components/lightbox-dialog/lightbox-dialog.component';
import { Result, Substance } from '@shared/models/result.interface';
import { Analysis, Assessment } from '@shared/models/sample.interface';

@Component({
	selector: 'app-feedback-dialog',
	templateUrl: './feedback-dialog.component.html',
	styleUrls: ['./feedback-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FeedbackDialogComponent implements OnInit {

	// Holds the sanitized evaluation text
	sanitizedDescription: SafeHtml;

	//
	displayedColumnsSubstances: string[] = ['number', 'name', 'amount'];
	dataSourceSubstances: MatTableDataSource<Substance[]>

	// 
	displayedColumnsAnalysis: string[] = ['weight', 'height', 'width', 'depth'];
	dataSourceAnalysis: MatTableDataSource<Analysis>

	apiUrl: string = '';

	constructor(
		private apiService: ApiService,
		private snackBar: MatSnackBar,
		private dialogRef: MatDialogRef<FeedbackDialogComponent>,
		private sanitizer: DomSanitizer,
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		// Sanitize the evaluation text and replace line breaks with <br> tags
		this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.data.assessment.description);

		this.dataSourceAnalysis = new MatTableDataSource([this.data.analysis]);
		this.dataSourceSubstances = new MatTableDataSource(this.data.analysis.substances);
	}

	getImageUrl(sequential: number): string {
		return `${environment.apiUrl}/v2/samples/${this.data.id}/images/${sequential}`;
	}
	
	handleImageError(event: Event): void {
		const target = event.target as HTMLImageElement;
		target.style.display = 'none';
	}

	openLightbox(sequential: number): void {
		this.dialog.open(LightboxDialogComponent, {
			data: `${environment.apiUrl}/v2/samples/${this.data.id}/images/${sequential}`
		})
	}

	/**
	 * Returns the alarm level based on "isAlert" and "isRedAlert".
	 * @since 1.0.0
	 * @param {Object} result - The result object containing the isAlert and isRedAlert properties.
	 * @returns {string|null} - The alarm level as a string or null if the result is invalid.
	 */
	/**
	 * Returns the alarm level for the given result.
	 * @since 1.0.0
	 * @param result The result object.
	 * @returns The alarm level as a string.
	 */
	getAlarmLevel(result: { isAlert: boolean | null, isRedAlert: boolean | null } | null): string {
		if (!result || result.isAlert === null || result.isRedAlert === null) { return ''; }
		if (result.isAlert && result.isRedAlert) { return 'REDALERT'; }
		if (result.isAlert) { return 'ALERT'; }
		return 'NONE';
	}

	compareDates(feedbackTimestamp: string | null, timestamp: string | null): boolean {

		if (!feedbackTimestamp || !timestamp) {
			return false;
		}

		const feedback = new Date(feedbackTimestamp);
		const assessment = new Date(timestamp);

		return feedback < assessment;
	}

	/**
	 * Handles feedback given by updating sample status and showing a snackbar.
	 * @since 1.0.0
	 * @returns void
	 */
	feedbackGiven(): void {
		this.apiService.updateSampleStatus(this.data.id, 'REPORTED').subscribe((data: any) => {
			this.snackBar.open(
				`Die Probe ${data.sampleNumber} wurde als "zurückgemeldet" gekennzeichnet.`, 
				'Schließen', 
				{panelClass: 'success-snack', duration: 2000} 
			)
			this.dialogRef.close({data: data})
		});
	}
}
