import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '@core/services/api.service';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { User } from '@shared/models/user.interface';



@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent {

	/** Array of column names to display in the table */
	displayedColumns: string[] = ['edit', 'userStatus', 'role', 'username', 'organization', 'lastLogin', 'created', 'delete'];

	/** Data source for the table */
	dataSource = new MatTableDataSource();

	constructor(
		private apiService: ApiService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar
	) { }

	ngOnInit(): void {
		this.getUsers();
	}

	/**
	 * Gets all users from the API and sets them as the data source for the table
	 * @returns void
	 * @since 1.0.0
	 */
	getUsers(): void {
		// const params = { size: 2000, page: 0 };
		this.apiService.getUsers().subscribe((data: any) => {
			this.dataSource = new MatTableDataSource(data.content);
		});
	}

	/**
	 * Deletes a user with the given ID from the API and updates the table
	 * @param {User} element - The user element to delete
	 * @returns void
	 * @since 1.0.0
	 */
	deleteUser(element: User): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
			width: '30vw',
            autoFocus: true,
			data: {
				title: `Benutzer*in ${element.username} unwiderruflich löschen?`,
				text: `Sind Sie absolut sicher, dass Sie diese*n Benutzer*in löschen wollen? Wenn ja, tippen Sie <code>LÖSCHEN</code> in das Feld ein und fahren Sie fort. Andernfalls klicken Sie auf ABBRECHEN.`,
				confirmation: `LÖSCHEN`
			}
        });
		dialogRef.afterClosed().subscribe(res => {
			if (res.data === 'confirmed') {
				this.apiService.deleteUser(element.id).subscribe(() => {
					// Update the user table data
					this.getUsers();
					this.snackBar.open(`Benutzer*in"${element.username}" gelöscht.`, 'Ok.', { panelClass: 'success-snack' });
				});
			}
		});
	}


	openUserDialog(element: User | null = null): void {
		let dialogRef = this.dialog.open(UserDialogComponent, {
			data: element,
			autoFocus: false,
			minWidth: '33.33vw'
		});

		dialogRef.afterClosed().subscribe(res => {
			// Update the user table data
			if (res && res.data === 'success') this.getUsers();
		});
	}


}
