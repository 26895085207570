import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

	private genderMap = new Map<string, string>([
		['MALE', 'männlich'],
		['FEMALE', 'weiblich'],
		['DIVERSE', 'divers']
	]);

	transform(value: string): string {
		const translation = this.genderMap.get(value);
    	return translation ? translation : value;
	}
}
