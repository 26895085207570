<!-- <h2 mat-dialog-title></h2> -->

<mat-dialog-content class="mat-typography !py-8">

    <app-client-uid-generator *ngIf="!data.length" (clientUid)="onClientUidChange($event)"></app-client-uid-generator>

    <table mat-table *ngIf="dataSource.data.length || clientUid.length === 6" [dataSource]="dataSource" class="mat-elevation-z0 mt-4">

        <!-- Client UID Column -->
        <ng-container matColumnDef="clientUid">
            <th mat-header-cell *matHeaderCellDef> Klient*in UID </th>
            <td mat-cell *matCellDef="let element"> {{element.clientUid}} </td>
        </ng-container>

        <!-- Gender Column -->
        <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef> Geschlecht </th>
            <td mat-cell *matCellDef="let element"> {{element.gender | gender}} </td>
        </ng-container>

        <!-- Postal Code Column -->
        <ng-container matColumnDef="postalCode">
            <th mat-header-cell *matHeaderCellDef> Postleitzahl </th>
            <td mat-cell *matCellDef="let element"> {{element.postalCode}} </td>
        </ng-container>

        <!-- Nationalities Column -->
        <ng-container matColumnDef="nationality">
            <th mat-header-cell *matHeaderCellDef> Staatsangehörigkeit(en) </th>
            <td mat-cell *matCellDef="let element">
                {{element.nationality | nationality}}{{element.nationality2 ? ' / ' + (element.nationality2 | nationality) : ''}}
            </td>
        </ng-container>

        <!-- First Consultation Column -->
        <ng-container matColumnDef="firstConsultationDate">
            <th mat-header-cell *matHeaderCellDef> Erste Beratung </th>
            <td mat-cell *matCellDef="let element"> {{element.firstConsultationDate | date: 'dd.MM.YYYY'}} </td>
        </ng-container>

        <!-- Last Consultation Column -->
        <ng-container matColumnDef="lastConsultationDate">
            <th mat-header-cell *matHeaderCellDef> Letzte Beratung </th>
            <td mat-cell *matCellDef="let element"> {{element.lastConsultationDate  | date: 'dd.MM.YYYY'}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="!text-right">Klient*in verwenden</th>
            <td mat-cell *matCellDef="let element" class="!text-right"> 
                <button mat-icon-button color="primary" (click)="selectClient(element)" mat-dialog-close>
                    <mat-icon>how_to_reg</mat-icon>
                </button>
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>

        <!-- Row shown when there is no matching data. -->
        <ng-container>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell p-4" colspan="5">
                    <p>Keinen Klienten gefunden mit UID <b>"{{clientUid}}"</b>.</p>
                </td>
            </tr>
        </ng-container>
        
    </table>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Schließen</button>
</mat-dialog-actions>