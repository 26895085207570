<app-toolbar></app-toolbar>

<div class="flex flex-col h-auto p-5">

	<div class="flex flex-row gap-4">

		<div class="w-1/4">

			<mat-card id="openSitting" class="mb-4" *ngIf="!['ADMIN'].includes(currentUser.role)">
				<mat-card-header class="flex flex-col">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">
							{{sittingState?.sittingStatus === 'OPEN' ? 'folder_open' : 'create_new_folder'}}
						</span>
						{{sittingState?.sittingStatus === 'OPEN' ? 'Offene Sprechstunde' : 'Neue Sprechstunde'}}
					</mat-card-title>
					<div *ngIf="sittingState?.sittingStatus === 'OPEN'" class="flex flex-col my-4 text-[#575757]">
						<span class="inline-flex items-center mb-1">
							<mat-icon class="mr-2">calendar_month</mat-icon> {{sittingState?.timestamp | date: 'EEEE, dd.MM.YYYY'}}
						</span>
						<span class="inline-flex items-center">
							<mat-icon class="mr-2">schedule</mat-icon> {{sittingState?.timestamp | date: 'HH:mm'}} Uhr
						</span>
					</div>
				</mat-card-header>
				<mat-card-content class="!inline-flex flex-col mt-4">
					<button *ngIf="sittingState?.sittingStatus !== 'OPEN'" mat-flat-button color="primary" class="uppercase"
						(click)="startSitting()">
						<mat-icon>play_arrow</mat-icon> Starten
					</button>
					<button *ngIf="sittingState?.sittingStatus === 'OPEN'" mat-flat-button color="primary" class="uppercase" [breadcrumb]="['sitting', sittingState]"
						[routerLink]="'consultation'">
						<mat-icon>play_arrow</mat-icon> Fortsetzen
					</button>
					<button *ngIf="sittingState?.sittingStatus === 'OPEN'" mat-flat-button color="warn" class="mt-4 uppercase"
						(click)="stopSitting()">
						<mat-icon>stop</mat-icon> Beenden
					</button>
					<p *ngIf="sittingState?.sittingStatus !== 'OPEN'" class="text-xs text-[#333] !mt-2">Beginnen Sie eine neue Sprechstunde.</p>
				</mat-card-content>
			</mat-card>

			<mat-card id="sampleNumbersPDF">
				<mat-card-header class="flex flex-col">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">
							picture_as_pdf
						</span>
						Stickerbogen
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="!inline-flex flex-col mt-4">
					<button mat-flat-button color="primary" class="uppercase" [disabled]="['ADMIN'].includes(currentUser.role)"
						(click)="downloadSampleNumbersPdf()">
						<mat-spinner *ngIf="isLoadingSampleSheet" [diameter]="25" class="btn-spinner"></mat-spinner>
						<span class="!inline-flex !items-center !align-middle" *ngIf="!isLoadingSampleSheet">
							<mat-icon class="mr-2">download</mat-icon> Herunterladen</span>
					</button>
					<p class="text-xs text-[#333] !mt-2">Laden Sie einen neuen Satz Probennummern herunter.</p>
				</mat-card-content>
			</mat-card>

		</div>

		<div class="w-full">

			<mat-card>
				<mat-card-header class="mb-5 !inline-flex justify-between items-top">

					<mat-card-title class="!inline-flex !items-center">
						<mat-icon  fontSet="material-icons-outlined" class="mr-2">inbox</mat-icon> Alle Sprechstunden
					</mat-card-title>

					<mat-form-field class="!inline-flex !items-center w-[18.5rem]">
						<mat-label>Zeitraum</mat-label>
						<mat-date-range-input [formGroup]="daterangeForm" [rangePicker]="picker" [max]="today">
							<input matStartDate formControlName="from" placeholder="Von" (focus)="picker.open()" readonly>
							<input matEndDate formControlName="until" placeholder="Bis" (focus)="picker.open()" readonly>
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker>
							<mat-date-range-picker-actions class="inline-flex">
								<button mat-flat-button color="warn" class="uppercase" matDateRangePickerCancel (click)="clearDateRange($event)">Löschen</button>
								<span class="flex-1"></span>
								<button mat-flat-button color="primary" class="uppercase" matDateRangePickerApply>Anwenden</button>
							</mat-date-range-picker-actions>
						</mat-date-range-picker>
					</mat-form-field>

				</mat-card-header>

				<mat-card-content class="!flex !flex-col">
					<table mat-table  [dataSource]="dataSource" class="mat-elevation-z0">

						<!-- Open Consultation Column -->
						<ng-container matColumnDef="edit">
							<th mat-header-cell *matHeaderCellDef> Beratungen </th>
							<td mat-cell *matCellDef="let element">
								<button mat-icon-button color="primary" [breadcrumb]="['sitting', element]" [routerLink]="'consultation'">
									<mat-icon>folder_open</mat-icon>
								</button>
							</td>
						</ng-container>

						<!-- Sitting Status Column -->
						<ng-container matColumnDef="sittingStatus">
							<th mat-header-cell *matHeaderCellDef> Status </th>
							<td mat-cell *matCellDef="let element"> {{element.sittingStatus | sittingStatus}} </td>
						</ng-container>

						<!-- Sitting Number Column -->
						<ng-container matColumnDef="sittingNumber">
							<th mat-header-cell *matHeaderCellDef> Sprechstunde </th>
							<td mat-cell *matCellDef="let element"> {{element.sittingNumber}} </td>
						</ng-container>

						<!-- Timestamp Column -->
						<ng-container matColumnDef="timestamp">
							<th mat-header-cell *matHeaderCellDef> Beginn der Sprechstunde </th>
							<td mat-cell *matCellDef="let element"> {{element.timestamp | date: 'EEEE, dd.MM.yyyy \'um\'
								HH:mm \'Uhr\''}} </td>
						</ng-container>

						<!-- Consultant Column -->
						<ng-container matColumnDef="consultant">
							<th mat-header-cell *matHeaderCellDef> Berater </th>
							<td mat-cell *matCellDef="let element"> {{element.user.username}} </td>
						</ng-container>
						
						<!-- Organization Column -->
						<ng-container matColumnDef="organization">
							<th mat-header-cell *matHeaderCellDef> Organisation </th>
							<td mat-cell *matCellDef="let element"> {{element.user.organisation | organization}} </td>
						</ng-container>

						<!-- PDF Column -->
						<ng-container matColumnDef="pdf">
							<th mat-header-cell *matHeaderCellDef class="max-w-[10rem] !text-right"> Probenbegleitschein </th>
							<td mat-cell *matCellDef="let element" class="max-w-[10rem] !text-right">
								<button [disabled]="element.sittingStatus === 'OPEN'" 
										mat-icon-button color="primary" 
										(click)="downloadInfoSheetPdf(element.id)">
										<span *ngIf="!isLoadingInfoSheet[element.id]" class="material-symbols-outlined">
											download
										</span>
										<mat-spinner *ngIf="isLoadingInfoSheet[element.id]" [diameter]="25" color="primary"></mat-spinner>
								</button>
							</td>
						</ng-container>
						
						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>

						<!-- Row shown when there is no matching data.  -->
						<tr class="mat-row" *matNoDataRow>
							<td class="mat-cell text-center p-8" colspan="5">Keine Sitzung gefunden.</td>
						</tr>
						
					</table>

					<mat-paginator class="mat-elevation-z0 mt-4" 
									[length]="totalElements"
									[pageSize]="pageSize" 
									[pageSizeOptions]="[5, 10, 25, 100]"
									(page)="pageChanged($event)" 
									showFirstLastButtons>
					</mat-paginator>


				</mat-card-content>
			</mat-card>

			<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

		</div>

	</div>

</div>