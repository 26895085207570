import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@core/services/api.service';
import { ClientUidDialogComponent } from '../client-uid-dialog/client-uid-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { Consultation } from '@shared/models/consultation.interface';
import { switchMap } from 'rxjs';
import { Sample } from '@shared/models/sample.interface';
import { Client } from '@shared/models/client.interface';
import { MatSort } from '@angular/material/sort';


@Component({
	selector: 'app-feedback-client',
	templateUrl: './feedback-client.component.html',
	styleUrls: ['./feedback-client.component.scss']
})
export class FeedbackClientComponent {

	/** Indicates whether the entered sample number is valid or not. */
	isValidSampleNumber: boolean = false

	/** Indicates whether the entered client UID is valid or not. */
	isValidClientUid: boolean = false

	/** Total number of elements in the data source. */
	totalElements: number = 0;

	/** Number of items to display on a page. */
	pageSize: number = 10;
		
	/** Object containing information about the current client. */
	currentClient: any = {
		clientUid: '',
		gender: '',
		age: null,
		postalCode: null,
		nationality: '',
		nationality2: '',
	}

	/** Defines the columns to be displayed in the table.  */
	displayedColumns: string[] = [
		'result',
		'sampleStatus',
		'sampleNumber',
		'name',
		'submissionDate',
		'riskAssessment2',
		'riskAssessment',
		// 'setSampleToInvalid'
	];


	/** Initialize data source with an empty array. */
	dataSource = new MatTableDataSource();

	isLoading: boolean = false;
	isLoadingClientUid: any = {};

	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private dialog: MatDialog,
		private apiService: ApiService
	) { }

	/**
	 * Searches for the sample with the given ID and updates the current client and 
	 * data source.
	 * @since 1.0.0
	 * @param event The sample search result event.
	 * @returns void
	 */
	sampleSearchResult(event: any): void {
		if (event && event.id && this.isValidSampleNumber) {
			this.apiService.getConsultation(event.consultationId).pipe(
				switchMap((data: Consultation) => {
					return this.apiService.getClient(data.client.id)
				}),
				switchMap((data: Client) => {
					this.currentClient = data
					return this.apiService.getSamplesByClientId(data.id)
				})
			).subscribe((data: any) => {
				const sortedSamples = this.sortSamples(data);
				this.dataSource = new MatTableDataSource(sortedSamples);
				this.dataSource.sort = this.sort;
			})
		}
	}

	sortSamples(samples: Sample[]): any {
		if ( samples.length <= 1 ) return samples 
		return samples.sort((a, b) => {
			const dateA = Date.parse(a.submissionDate);
			const dateB = Date.parse(b.submissionDate);
			
			if (dateA < dateB) return -1;
			if (dateA > dateB) return 1;
			
			const statusOrder = ['ANALYZED', 'PENDING', 'REPORTED'];
			
			const statusA = statusOrder.indexOf(a.sampleStatus);
			const statusB = statusOrder.indexOf(b.sampleStatus);
			
			return statusA - statusB;
		});
	}

	updateDataSource(samples: Sample[]): void {
        this.dataSource.data = this.sortSamples(samples);
        this.dataSource.sort = this.sort;
    }

	/**
	 * Updates the value of `isValidSampleNumber` based on the result of the 
	 * sample validation.
	 * @since 1.0.0
	 * @param event The sample validation event.
	 * @retuns void
	 */
	isSampleValid(event: boolean): void {
		this.isValidSampleNumber = event
	}

	/**
	 * Fetches the data for the selected client UID and updates the data source.
	 * @since 1.0.0
	 * @param event The search results change event.
	 * @returns void
	 */
	onSearchResultsChange(event: any): void {
		if (event && event.length > 1) {
			this.openClientDialog(event);
		} else if (event && event.length === 1) {
			this.apiService.getSamplesByClientId(event[0].id).subscribe((data: any) => {
				this.updateDataSource(data);
				this.currentClient = event[0]
				this.isValidClientUid = true
			})
		}
	}

	/**
	 * Returns the alarm level for the given result.
	 * @since 1.0.0
	 * @param result The result object.
	 * @returns The alarm level as a string.
	 */
	getAlarmLevel(result: { isAlert: boolean | null, isRedAlert: boolean | null } | null): string {
		if (!result || result.isAlert === null || result.isRedAlert === null) { return ''; }
		if (result.isAlert && result.isRedAlert) { return 'REDALERT'; }
		if (result.isAlert) { return 'ALERT'; }
		return 'NONE';
	}


	compareDates(feedbackTimestamp: string | null, timestamp: string | null): boolean {

		if (!feedbackTimestamp || !timestamp) {
			return false;
		}

		const feedback = new Date(feedbackTimestamp);
		const assessment = new Date(timestamp);

		return feedback < assessment;
	}

	/**
	 * Opens the result dialog and updates the data source with the modified data.
	 * @since 1.0.0
	 * @param {Sample} sample - The sample object to be displayed in the result dialog.
	 * @returns void
	 */
	openResultDialog(sample: Sample): void {
		// Open result dialog
		let dialogRef = this.dialog.open(FeedbackDialogComponent, {
			data: sample,
			width: '45vw',
			maxHeight: '98vh',
			autoFocus: false
		});

		dialogRef.afterClosed().subscribe(res => {
          	if (res?.data) {
                const updatedData = this.dataSource.data.map((d: any) => d.id === res.data.id ? res.data : d);
                this.dataSource.data = updatedData;
            }
		});
	}

	/**
	 * Opens the client dialog and updates the data source with the client's samples.
	 * @since 1.0.0
	 * @param {Array} clients - An array of client objects to be displayed in the client dialog.
	 * @returns void
	 */
	openClientDialog(clients: Client[] = []): void {
		// Open client dialog
		let dialogRef = this.dialog.open(ClientUidDialogComponent, {
			data: clients,
			width: '60vw',
			autoFocus: false,
			disableClose: true
		});

		dialogRef.afterClosed().subscribe(res => {
			if (res && res.data) {
				this.apiService.getSamplesByClientId(res.data.id).subscribe((samples: Sample[]) => {
					// The setTimeout ensures that the MatSort is properly assigned to the data source 
					// after Angular has had a chance to update the view.
					setTimeout(() => this.updateDataSource(samples), 0);
					this.isValidClientUid = true
					this.currentClient = res.data
				})
			}
		});
	}
	
}
