<app-toolbar></app-toolbar>

<div class="flex flex-row h-[calc(100%-64px)]">
    
    <div class="w-1/4 p-5 pr-0">

		<mat-card>
			<mat-card-header class="mb-5">
				<mat-card-title class="!inline-flex !items-center">
					<span class="material-symbols-outlined mr-1">labs</span> Proben {{dataSource.data.length}}/{{maxSamples}}
				</mat-card-title>
			</mat-card-header>
			<mat-card-content class="!inline-flex flex-col">
                <button mat-flat-button color="primary" class="uppercase" (click)="openSampleDetail()" [disabled]="consultationStatus === 'CLOSED' || dataSource.data.length >= maxSamples">
                    <mat-icon>add</mat-icon> Hinzufügen
                </button>
			</mat-card-content>
		</mat-card>

        <button class="mt-4 w-full uppercase" mat-raised-button [routerLink]="'/sitting/consultation'"><mat-icon>arrow_left</mat-icon>Zurück</button>

	</div>

    <div class="w-full p-5">

		<mat-card>
			<mat-card-header class="mb-5">
				<mat-card-title class="!inline-flex !items-center">
					<mat-icon  fontSet="material-icons-outlined" class="mr-2">inbox</mat-icon> Sprechstunde {{sittingNumber}}  <mat-icon>navigate_next</mat-icon> Beratung Klient*in {{clientUid}} <mat-icon>navigate_next</mat-icon> Alle Proben
				</mat-card-title>
			</mat-card-header>
			<mat-card-content class="!flex !flex-col">
				
                <table mat-table  [dataSource]="dataSource" class="mat-elevation-z0">

					<!-- Edit Column -->
					<ng-container matColumnDef="edit">
						<th mat-header-cell *matHeaderCellDef> Details </th>
						<td mat-cell *matCellDef="let element">
							<button mat-icon-button color="primary" [breadcrumb]="['sample', element]" (click)="openSampleDetail(element.id)">
								<mat-icon>folder_open</mat-icon>
							</button>
						</td>
					</ng-container>

					<!-- Sample Number Column -->
					<ng-container matColumnDef="sampleNumber">
						<th mat-header-cell *matHeaderCellDef> Probennummer </th>
						<td mat-cell *matCellDef="let element"> 
							{{element.sampleNumber}} 
							<app-copy-to-clipboard-button [textToCopy]="element.sampleNumber"></app-copy-to-clipboard-button>
						</td>
					</ng-container>

					<!-- Sample Status Column -->
					<ng-container matColumnDef="sampleStatus">
						<th mat-header-cell *matHeaderCellDef> Status</th>
						<td mat-cell *matCellDef="let element"> {{element.sampleStatus | sampleStatus}} </td>
					</ng-container>

					<!-- Submission Date Column -->
					<ng-container matColumnDef="submissionDate">
						<th mat-header-cell *matHeaderCellDef> Abgabedatum </th>
						<td mat-cell *matCellDef="let element"> {{element.submissionDate | date: 'EEEE, dd.MM.yyyy'}} </td>
					</ng-container>

					<!-- Submission Date Column -->
					<ng-container matColumnDef="delete">
						<th mat-header-cell *matHeaderCellDef> Löschen </th>
						<td mat-cell *matCellDef="let element">
							<button mat-icon-button color="warn" (click)="deleteSample(element)" [disabled]="sittingStatus === 'CLOSED'">
								<mat-icon>close</mat-icon>
							</button>
						</td>
					</ng-container>
					
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>

					<!-- Row shown when there is no matching data. -->
					<ng-container *ngIf="!isLoading">
						<tr class="mat-row" *matNoDataRow>
							<td class="mat-cell text-center p-12" colspan="5">
								<p>Keine Proben gefunden.</p>
								<button type="button" mat-raised-button class="uppercase" (click)="openSampleDetail()" [disabled]="sittingStatus === 'CLOSED' || dataSource.data.length >= 3">
									<mat-icon>add</mat-icon> Probe hinzufügen
								</button>
							</td>
						</tr>
					</ng-container>

				</table>

				<!--<mat-paginator class="mat-elevation-z1 pb-3" [length]="totalElements" [pageSize]="paginationParams.size" [pageSizeOptions]="[5, 10, 25, 100]"(page)="pageChanged($event)" showFirstLastButtons>
				</mat-paginator> -->

			</mat-card-content>
			
		</mat-card>

		<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

	</div>


</div>
