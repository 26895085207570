<h1 mat-dialog-title [innerHTML]="data.title"></h1>

<div mat-dialog-content>
    
    <p [innerHTML]="data.text"></p>
    
    <mat-form-field *ngIf="data.confirmation" class="w-full mt-8">
        <mat-label>Tippen Sie "{{data.confirmation}}" in dieses Feld</mat-label>
        <input matInput type="text" [(ngModel)]="confirmationValue" cdkFocusInitial>
    </mat-form-field>

</div>

<div mat-dialog-actions>
    <button mat-button 
            mat-dialog-close>
            ABBRECHEN
    </button>
    <span class="flex-1"></span>
    <button mat-flat-button 
            mat-dialog-close 
            color="warn"
            [disabled]="data.confirmation && confirmationValue !== data.confirmation" 
            (click)="continue()">
            {{data.buttonText ? data.buttonText : 'LÖSCHEN'}}
    </button>
</div>