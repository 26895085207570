import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Nationality } from '@shared/enums/nationality.enum';
import { map, Observable, startWith, Subscription } from 'rxjs';

@Component({
	selector: 'app-autocomplete-nationalities',
	templateUrl: './autocomplete-nationalities.component.html',
	styleUrls: ['./autocomplete-nationalities.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective }
	]
})
export class AutocompleteNationalitiesComponent implements OnInit, OnDestroy, AfterViewInit {

	/** The MatAutocompleteTrigger element */
	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

	/** The form control name for the nationality input */
	@Input() nationalityFormControlName: string;

	/** The label for the nationality input */
	@Input() nationalityLabel: string;

	/** The form control for the nationality input */
	formCtrl: FormControl;

	/** The list of available nationalities */
	nationsList: any[];

	/** The filtered list of nationalities based on user input */
	filteredNations: Observable<any[]>;

	/** The subscription for the MatAutocompleteTrigger closing actions */
	subscription: Subscription;

	/** Whether the input has changed */
	hasChanged: boolean = false;

	constructor(
		private rootForm: FormGroupDirective
	) { }

	ngOnInit(): void {

		// Get the list of nationalities and convert it to an array of objects
		this.nationsList = Object.keys(Nationality).map((key: any) => {
			const value = Nationality[key as keyof typeof Nationality];
			return { name: value, value: key };
		});

		// Get the form control for the nationality input
		this.formCtrl = this.rootForm.control.get(this.nationalityFormControlName) as FormControl

		// Set up the filtered list of nationalities based on user input
		this.filteredNations = this.formCtrl!.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value || '')),
		);
	}

	ngAfterViewInit() {
		/**
		 * Empty input if no option was selected, this triggers required validator
		 * and forces the user to take one of the suggested options
		 */
		this.subscription = this.autocompleteTrigger.panelClosingActions.subscribe((e) => {
			if (!(e && e.source) && this.hasChanged) {
				this.formCtrl.patchValue(null, { emitEvent: false });
				this.autocompleteTrigger.closePanel();
			}
		});
	}

	ngOnDestroy() {
		// Unsubscribe from the MatAutocompleteTrigger closing actions
		this.subscription.unsubscribe();
	}

	/**
	 * Display the nationality name for a given value.
	 * @param value - The nationality value
	 * @returns The nationality name or an empty string
	 */
	displayFn = (value: string): string => {
		const nationality = this.nationsList.filter((item: any) => item.value === value)
		return nationality[0]?.name || ''
	}

	/**
	 * Set hasChanged to true when the input is updated.
	 */
	onKeyup() {
		this.hasChanged = true
	}

	/**
	 * Filter the list of nationalities based on user input.
	 * @param value - The user input
	 * @returns The filtered list of nationalities
	 */
	private _filter(value: any): string[] {
		const filterValue = value.toLowerCase();
		return this.nationsList.filter((option: any) => {
			return option.name.toLowerCase().includes(filterValue)
		});
	}

}
