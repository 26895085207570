import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'organization'
})
export class OrganizationPipe implements PipeTransform {

	private organizationMap = new Map<string, string>([
		['VISTA', 'vista'],
		['SCHWULENBERATUNG', 'Schwulenberatung'],
		['FIXPUNKT', 'Fixpunkt'],
		['GERMED', 'GerMed'],
	]);

	transform(value: string): string {
		const translation = this.organizationMap.get(value);
    	return translation ? translation : value;
	}
}
