<mat-form-field class="w-full" subscriptSizing="dynamic">
    <mat-label>{{nationalityLabel}}</mat-label>
    <input type="text"
           aria-label="Country"
           matInput
           [formControlName]="nationalityFormControlName"
           [matAutocomplete]="autoNationality"
           (keyup)="onKeyup()">
    <mat-autocomplete #autoNationality="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of filteredNations | async" [value]="option.value">
            {{option.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>