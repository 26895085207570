import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from '@core/services/api.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
	selector: 'app-client-search-form',
	templateUrl: './client-search-form.component.html',
	styleUrls: ['./client-search-form.component.scss']
})
export class ClientSearchformComponent implements OnInit, OnDestroy, OnChanges {

	@Output() clientUidCtrlValue = new EventEmitter<any>();
	@Output() searchResults = new EventEmitter<any>();

	@Input() clientUid: string = ''

	clientUidCtrl: FormControl = new FormControl(this.clientUid);
	isLoading: boolean = false

	subscription: Subscription

	constructor(
		private apiService: ApiService,
	) { }

	ngOnInit(): void {
		this.subscription = this.clientUidCtrl.valueChanges.subscribe(value => {
			this.clientUidCtrlValue.emit(value);
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['clientUid'] && !changes['clientUid'].isFirstChange()) {
			this.clientUidCtrl.patchValue(this.clientUid)
			this.getClientByUid(this.clientUid);
		}
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.getClientByUid(filterValue);
	}

	getClientByUid(uid: string): void {
		
		if (uid && uid.length >= 6) {
			this.isLoading = true;
			this.apiService.getClients(uid).pipe(
				debounceTime(1000),
				distinctUntilChanged(),
			).subscribe((data: any) => {
				this.searchResults.emit(data.content)
				this.isLoading = false;
			})
		} else {
			this.searchResults.emit([])
			this.isLoading = false;
		}
	}
}
