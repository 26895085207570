<h1 mat-dialog-title class="!mb-0 !pt-3 !inline-flex items-center">
    <span class="material-symbols-outlined mr-1 rotate-180">upload_file</span> Daten Export
</h1>

<mat-dialog-content class="mat-typography bg-[#F1F1F1] !pt-4">

    <mat-card class="mb-8">

        <mat-card-content>

            <h2 mat-card-title>Proben</h2>

            <mat-form-field class="!inline-flex !items-center w-full">
                <mat-label>Zeitraum</mat-label>
                <mat-date-range-input [formGroup]="daterangeFormSamples" [rangePicker]="pickerSamples">
                    <input matStartDate formControlName="from" placeholder="Von">
                    <input matEndDate formControlName="until" placeholder="Bis">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="pickerSamples"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerSamples></mat-date-range-picker>
            </mat-form-field>
            
            <mat-form-field class="w-full">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="sampleStatusCtrl">
                    <mat-option>Alle</mat-option>
                    <mat-option *ngFor="let status of optionsSampleStatus" [value]="status">{{status | sampleStatus}}</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-flat-button color="primary" (click)="downloadSamples()" [disabled]="daterangeFormSamples.invalid">
                <mat-icon>download</mat-icon> Herunterladen
            </button>
            
        </mat-card-content>

    </mat-card>


    <mat-card>

        <mat-card-content>

            <h2 mat-card-title>Klienten</h2>

            <mat-form-field class="!inline-flex !items-center w-full">
                <mat-label>Zeitraum</mat-label>
                <mat-date-range-input [formGroup]="daterangeFormClients" [rangePicker]="pickerClients">
                    <input matStartDate formControlName="from" placeholder="Von">
                    <input matEndDate formControlName="until" placeholder="Bis">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="pickerClients"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerClients></mat-date-range-picker>
            </mat-form-field>

            <button mat-flat-button color="primary" (click)="downloadClients()" [disabled]="daterangeFormClients.invalid">
                <mat-icon>download</mat-icon> Herunterladen
            </button>
            
        </mat-card-content>

    </mat-card>

</mat-dialog-content>

<mat-dialog-actions align="end" class="bg-[#FFFFFF]">
    <button type="button" mat-button mat-dialog-close cdkFocusInitial>Schließen</button>
</mat-dialog-actions>