import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@core/layout/material/material.module';

import { LoginComponent } from './login/login.component';


@NgModule({
	declarations: [
		LoginComponent,
	],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
	],
	providers: [
		// { provide: APP_INITIALIZER, useFactory: validateCookie, multi: true }
	],
})
export class AuthModule { }

export function validateCookie() {
	// Check for authentication session cookie here
	// If the cookie is valid, set the user's authentication status
	// If the cookie is not valid, log the user out
}