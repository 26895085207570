import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router'
import { Store } from '@ngxs/store';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Logout } from 'app/auth/states/auth.action';

export const SKIP_INTERCEPTOR_HEADER = 'X-Skip-Interceptor';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    snackOptions: MatSnackBarConfig = { duration: 5000, panelClass: 'error-snack' };

    constructor(
        private router: Router,
        private store: Store,
        private snackBar: MatSnackBar
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.headers.has(SKIP_INTERCEPTOR_HEADER)) {
            const headers = request.headers.delete(SKIP_INTERCEPTOR_HEADER);
            return next.handle(request.clone({ headers }));
        }

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                // console.error('An error occurred: ', err);
                if ( err.status === 401 ) {
                    this.store.dispatch(new Logout());
                    // This would be outside ngZone
                    // this.router.navigate(['/login']);
                }

                let errorMessage = 'Ein unbekannter Fehler ist aufgetreten. Bitten probieren Sie es später noch einmal.';
                if (err.error && typeof err.error.message !== 'undefined') {
                    errorMessage = `${err.error.message}`;
                } else if (err.error && typeof err.error.text !== 'undefined') {
                    errorMessage = err.error.text;
                }

                this.snackBar.open(errorMessage, 'Schließen', this.snackOptions);
                return throwError(() => err);
            })
        );
    }
}