import { Directive, HostListener, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetConsultation, SetSample, SetSitting } from '@shared/states/breadcrumb/breadcrumb.action';

@Directive({
	selector: '[breadcrumb]'
})
export class BreadcrumbDirective {

	@Input('breadcrumb') params: [string, any]; // [entity, payload]

	constructor(private store: Store) { }

	@HostListener('click')
	onClick() {
		
		const [entity, payload] = this.params;
		
		switch ( entity ) {
			case 'sitting':
				this.store.dispatch(new SetSitting(payload));
				break;
			case 'consultation':
				this.store.dispatch(new SetConsultation(payload));
				break;
			case 'sample':
				this.store.dispatch(new SetSample(payload));
				break;
			default:
				throw new Error(`Invalid entity type: ${entity}`);
		}
	}

}
